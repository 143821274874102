import axios, { AxiosResponse } from "axios";
import { API_URL } from "../_variables";
import { uploadRecordFile } from "../service-log/_requests";
import {
  OSHInstructionCreateEditModel,
  OSHInstructionDetailModel,
  OSHInstructionPropertiesModel,
  OSHInstructionTestCreateEditModel,
  OSHInstructionTestDetailModel,
  OSHInstructionTestResultModel,
  OSHTrainingCreateEditModel,
  OSHTrainingDetailModel,
} from "./_models";

const OSH_TRAINING_REGISTER_URL = `${API_URL}/osh-trainings`;
const OSH_TRAINING_NUMBER = `${API_URL}/osh-trainings/training-number`;
export function deleteOSHTraining(id: number) {
  return axios.delete(`${OSH_TRAINING_REGISTER_URL}/${id}`);
}

export function getOSHTrainings(query: any) {
  return axios
    .get(`${OSH_TRAINING_REGISTER_URL}?${query}`)
    .then((res) => res.data);
}

export function createOSHTraining(payload: OSHTrainingCreateEditModel) {
  return axios.post(`${OSH_TRAINING_REGISTER_URL}/`, payload);
}

export function updateOSHTraining(
  id: number,
  payload: OSHTrainingCreateEditModel
) {
  return axios.put(`${OSH_TRAINING_REGISTER_URL}/${id}/`, payload);
}

export function getOSHTrainingNumber() {
  return axios.get(`${OSH_TRAINING_NUMBER}`).then((res) => res.data);
}
export function getOSHTrainingById(id: number) {
  return axios
    .get(`${OSH_TRAINING_REGISTER_URL}/${id}`)
    .then((res: AxiosResponse<OSHTrainingDetailModel>) => res.data);
}

export function generateQRCode(id: number) {
  return axios
    .post(`${OSH_TRAINING_REGISTER_URL}/${id}/qr-codes/`)
    .then((res) => res.data);
}

export function getOSHTrainingTest(id: number, accessKey: string) {
  return axios
    .get(`${OSH_TRAINING_REGISTER_URL}/${id}/test?access_key=${accessKey}`)
    .then((res: AxiosResponse<OSHInstructionTestDetailModel>) => res.data);
}

export function getOSHTrainigTestResult(id: number, payload: any) {
  return axios
    .post(`${OSH_TRAINING_REGISTER_URL}/${id}/test/answers`, payload)
    .then((res: AxiosResponse<OSHInstructionTestResultModel>) => res.data);
}

export function getOSHTrainingMembersWithAccessKey(
  oshTrainingId: number,
  accessKey: string
) {
  return axios.get(
    `${OSH_TRAINING_REGISTER_URL}/${oshTrainingId}/members?access_key=${accessKey}`
  );
}

export function getOSHTrainingInternalWorkers(query: string) {
  return axios
    .get(`${API_URL}/osh-trainings/workers/?worker_type=internal&${query}`)
    .then((res) => res.data);
}

export function getOSHTrainingOutsourcedWorkers(query: string) {
  return axios
    .get(`${API_URL}/osh-trainings/workers/?worker_type=outsourced&${query}`)
    .then((res) => res.data);
}

export function getOSHTrainingInternalWorkerById(id: number) {
  return axios
    .get(`${API_URL}/osh-trainings/workers/${id}?worker_type=internal`)
    .then((res) => res.data);
}

export function getOSHTrainingOutsourcedWorkerById(id: number) {
  return axios
    .get(`${API_URL}/osh-trainings/workers/${id}?worker_type=outsourced`)
    .then((res) => res.data);
}

export function getOSHInstructionsDisabledPagination() {
  return axios
    .get(`${API_URL}/osh-instructions/?pagination_enabled=false`)
    .then((res) => res.data);
}

export function getOSHInstructions(query: any) {
  return axios
    .get(`${API_URL}/osh-instructions?${query}`)
    .then((res) => res.data);
}

export function getOSHInstructionById(id: number) {
  return axios
    .get(`${API_URL}/osh-instructions/${id}`)
    .then((res: AxiosResponse<OSHInstructionDetailModel>) => res.data);
}

export function createOSHInstruction(payload: OSHInstructionCreateEditModel) {
  return axios.post(`${API_URL}/osh-instructions/`, payload);
}

export function updateOSHInstruction(
  id: number,
  payload: OSHInstructionCreateEditModel
) {
  return axios.put(`${API_URL}/osh-instructions/${id}/`, payload);
}

export function updateOSHInstructionQuestion(questionId: number, payload: any) {
  return axios.put(
    `${API_URL}/osh-instruction-questions/${questionId}/`,
    payload
  );
}

export function deleteOSHInstruction(id: number) {
  return axios.delete(`${API_URL}/osh-instructions/${id}`);
}

export function getOSHTrainingForms() {
  return axios.get(`${API_URL}/osh-instruction-types`).then((res) => res.data);
}

export function getOSHTrainingQuestions(id: number) {
  return axios
    .get(`${API_URL}/osh-instructions/${id}/questions`)
    .then((res) => res.data);
}

// tests
const OSH_INSTRUCTION_TEST_URL = `${API_URL}/tests`;

export function deleteOSHInstructionTest(id: number) {
  return axios.delete(`${OSH_TRAINING_REGISTER_URL}/${id}`);
}

export function getOSHInstructionTests(query: any) {
  return axios
    .get(`${OSH_INSTRUCTION_TEST_URL}/?${query}`)
    .then((res) => res.data);
}

export function createOSHInstructionTest(
  payload: OSHInstructionTestCreateEditModel
) {
  return axios.post(`${OSH_INSTRUCTION_TEST_URL}/`, payload);
}

export function updateOSHInstructionTest(
  id: number,
  payload: OSHInstructionTestCreateEditModel
) {
  return axios.put(`${OSH_INSTRUCTION_TEST_URL}/${id}/`, payload);
}

export function getOSHInstructionTestById(id: number) {
  return axios
    .get(`${OSH_INSTRUCTION_TEST_URL}/${id}`)
    .then((res: AxiosResponse<OSHInstructionTestDetailModel>) => res.data);
}

export function getOSHInstructionTestsDisabledPagination() {
  return axios
    .get(`${API_URL}/tests/?pagination_enabled=false`)
    .then((res) => res.data);
}

export function getInstructionProperties(
  instructionId: number,
  isInitial?: boolean
) {
  const query: string = isInitial ? "type=initial" : "";
  return axios
    .get(`${API_URL}/osh-instructions/${instructionId}/properties?${query}`)
    .then((res: AxiosResponse<OSHInstructionPropertiesModel>) => res.data);
}

export function uploadOSHTrainingDocument(
  file: FormData,
  id: number,
  onProgress: (percentage: number) => void
) {
  return uploadRecordFile(
    `${OSH_TRAINING_REGISTER_URL}/${id}/files`,
    file,
    onProgress
  );
}

export function getOSHTrainingNotifications() {
  return axios
    .get(`${API_URL}/notifications/osh-training`)
    .then((res) => res.data);
}

export function changeOSHTrainingTestQuestionOrder(id:number, newOrder: number[]) { 
  return axios.post(`${OSH_INSTRUCTION_TEST_URL}/${id}/order`, { new_order: newOrder }).then((res) => res.data);
}
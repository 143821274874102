import { GROUP_NAME } from "../../../pages/user/table/columns/UserGroupCell";

export const TOAST_MESSAGES = {
  accident_registration: {
    create_success: "Protokół wypadku został utworzony.",
    edit_success: "Protokół wypadku został zaktualizowany pomyślnie.",
  },
  not_found: "Nie znaleziono zasobu.",
  accident_work_commute_card: {
    create_success: "Karta wypadku została utworzona.",
    edit_success: "Karta wypadku została zaktualizowana pomyślnie.",
    forbidden: "Nie masz uprawnień aby wykonać tę akcję.",
    not_found: "Wypadek nie istnieje.",
  },
  accident: {
    create_success: "Wypadek został utworzony.",
    edit_success: "Wypadek został zaktualizowany pomyślnie.",
    delete_success: "Wypadek został usunięty pomyślnie.",
    delete_forbidden: "Nie masz uprawnień aby usunąć ten wypadek.",
    not_found: "Wypadek nie istnieje.",
  },
  company: {
    edit_success: "Dane firmy zostały zaktualizowane pomyślnie.",
    forbidden: "Nie masz uprawnień aby wykonać tę akcję.",
    not_found: "Firma nie istnieje.",
  },
  factory: {
    edit_success: "Dane zakładu zostały zaktualizowane pomyślnie.",
    create_success: "Zakład został utworzony.",
    delete_success: "Zakład został usunięty pomyślnie.",
    delete_forbidden: "Nie masz uprawnień aby usunąć ten zakład.",
    forbidden: "Nie masz uprawnień aby wykonać tę akcję.",
    not_found: "Zakład nie istnieje.",
  },
  recommendation: {
    create_success: "Zalecenie zostało utworzone.",
    edit_success: "Zalecenie zostało zaktualizowane pomyślnie.",
    delete_success: "Zalecenie zostało usunięte pomyślnie.",
    delete_forbidden: "Nie masz uprawnień aby usunąć to zalecenie.",
    status_change_success: (is_resolved: boolean) =>
      `Zalecenie zostało oznaczone jako ${
        !is_resolved ? "zrealizowane" : "niezrealizowane"
      }.`,
    realization_create_success: (first_name: string, last_name: string) =>
      `Realizacja zalecenia została zapisana. Pracownik ${first_name} ${last_name} zostanie o tym poinformowany/a.`,
    not_found: "Zalecenie nie istnieje.",
  },
  factory_area: {
    not_found: "Budynek nie istnieje.",
    delete_success: "Budynek został usunięty.",
    create_success: "Budynek został utworzony.",
    edit_success: "Budynek został zaktualizowany pomyślnie.",
    delete_forbidden: "Nie masz uprawnień aby usunąć ten budynek.",
  },
  technical_security_system_type: {
    create_success: "Techniczny system bezpieczeństwa został utworzony.",
    edit_success:
      "Techniczny system bezpieczeństwa został zaktualizowany pomyślnie.",
    delete_success:
      "Techniczny system bezpieczeństwa został usunięty pomyślnie.",
    delete_forbidden: "Nie masz uprawnień aby usunąć ten system.",
    forbidden: "Nie masz uprawnień aby wykonać tę akcję.",
    not_found: "System nie istnieje.",
  },
  technical_security_system: {
    status_change_success: "Zmieniono status systemu.",
    delete_success: "System został usunięty.",
    delete_forbidden: "Nie masz uprawnień aby usunąć ten system.",
    create_success: "System został utworzony.",
    edit_success: "System został zaktualizowany pomyślnie.",
    forbidden: "Nie masz uprawnień aby wykonać tę akcję.",
    not_found: "System nie istnieje.",
  },
  service_log: {
    delete_success: "Serwis został usunięty.",
    delete_forbidden: "Nie masz uprawnień aby usunąć ten serwis.",
    create_success: "Serwis został utworzony.",
    edit_success: "Serwis został zaktualizowany pomyślnie.",
    forbidden: "Nie masz uprawnień aby wykonać tę akcję.",
    not_found: "Serwis nie istnieje.",
  },
  service_log_type: {
    delete_success: "Umowa serwisowa została usunięta.",
    delete_forbidden: "Nie masz uprawnień aby usunąć tą umowę.",
    create_success: "Umowa serwisowa została utworzony.",
    edit_success: "Umowa serwisowa została zaktualizowany pomyślnie.",
    forbidden: "Nie masz uprawnień aby wykonać tę akcję.",
    not_found: "Umowa serwisowa nie istnieje.",
  },
  user: {
    delete_success: "Użytkownik został usunięty.",
    create_success: "Użytkownik został utworzony.",
    edit_success: "Użytkownik został zaktualizowany pomyślnie.",
    grant_role_success: (email: string, selectedRole: string) =>
      `Użytkownik o e-mailu ${email} otrzymał rolę: ${GROUP_NAME[selectedRole]}.`,
    grant_role_request: (first_name: string, last_name: string) =>
      `${first_name} ${last_name} prosi o przyznanie uprawnień.`,
  },

  fire_hazardous_work: {
    delete_success: "zezwolenie na pracę zostało usunięte.",
    delete_forbidden: "Nie masz uprawnień aby usunąć to zezwolenie.",
    create_success: "zezwolenie na pracę zostało utworzone.",
    edit_success: "zezwolenie na pracę zostało zaktualizowane pomyślnie.",
    forbidden: "Nie masz uprawnień aby wykonać tę akcję.",
    not_found: "Zezwolenie nie istnieje.",
  },

  fire_zone: {
    delete_success: "Strefa pożarowa została usunięta.",
    create_success: "Strefa pożarowa została utworzona.",
    edit_success: "Strefa pożarowa została zaktualizowana pomyślnie.",
    delete_forbidden: "Nie masz uprawnień aby usunąć tą strefę.",
    forbidden: "Nie masz uprawnień aby wykonać tę akcję.",
    not_found: "Strefa pożarowa nie istnieje.",
  },
  worker: {
    delete_success: "Pracownik został usunięty.",
    create_success: "Pracownik został utworzony.",
    edit_success: "Pracownik został zaktualizowany pomyślnie.",
    delete_forbidden: "Nie masz uprawnień aby usunąć pracownika.",
    forbidden: "Nie masz uprawnień aby wykonać tę akcję.",
    not_found: "Pracownik nie istnieje.",
  },

  file: {
    delete_success: (resourceName: string) =>
      `Plik ${resourceName} został usunięty.`,
    create_success: (resourceName: string) =>
      `Plik ${resourceName} został dodany.`,
    upload_success: (resourceName: string) =>
      `Plik ${resourceName} jest obecnie wgrywany na serwer. Za chwilę będzie dostępny.`,
  },

  fire_protection_document: {
    create_success: "Dokument został utworzony.",
    edit_success: "Dokument został zaktualizowany.",
    delete_success: "Dokument został usunięty.",
    delete_forbidden: "Nie masz uprawnień aby usunąć ten dokument.",
    delete_fail: "Nie udało się usunąć dokumentu.",
    not_found: "Dokument nie istnieje.",
    forbidden: "Nie masz uprawnień aby wykonać tę akcję.",
  },

  error: {
    server_error:
      "Wystąpił błąd serwera. Jeśli problem będzie się powtarzał, skontaktuj się z administatorem platformy.",
  },
  delete_conflict: "Nie można usunąć rekordu, ponieważ jest używany w innych miejscach.",
  forbidden: "Nie masz uprawnień aby wykonać tę akcję.",
  osh_training_register: { 
    edit_success: "Szkolenie zostało zaktualizowane pomyślnie.",
    create_success: "Szkolenie zostało utworzone.",
    not_found: "Szkolenie nie istnieje.",
    forbidden: "Nie masz uprawnień aby wykonać tę akcję.",
  },
  osh_instruction_test: { 
    edit_success: "Test szkoleniowy został zaktualizowany pomyślnie.",
    create_success: "Test szkoleniowy został utworzony.",
    not_found: "Test szkoleniowy nie istnieje.",
    forbidden: "Nie masz uprawnień aby wykonać tę akcję.",
  }

};

import { FormikProps, getIn } from "formik";
import React, { ReactNode } from "react";
import { getErrorMessage } from "./RenderListSelectComponent";

interface RenderInputComponentProps {
  formik: FormikProps<any>;
  placeholder: string;
  type: "number" | "text" | "email" | "phone";
  fieldKey: string;
  label: string | ReactNode;
  isRequired?: boolean;
  disabled?: boolean;
  minValue?: number;
  maxValue?: number;
  formikErrorField?: FormikProps<any>;
  allowNumberSpinnerButtons?: boolean;
  value?: string;
  displayRule?: () => boolean;
  arrayFieldComponent?: boolean;
}

const RenderInputComponent: React.FC<RenderInputComponentProps> = ({
  formik,
  placeholder,
  type,
  fieldKey,
  label,
  minValue,
  maxValue,
  isRequired = false,
  formikErrorField = undefined,
  disabled = false,
  allowNumberSpinnerButtons = false,
  value = undefined,
  displayRule = () => true,
  arrayFieldComponent = false,
}) => {
  const fieldProps = formik.getFieldProps(fieldKey);
  const formikValue = arrayFieldComponent
    ? fieldProps.value
    : formik.values[fieldKey];

  let formikError = undefined;

  if (arrayFieldComponent) {
    // used in dynamically added fields
    formikError = getIn(formik.errors, fieldKey);
  } else if (formikErrorField) {
    // used in nested fields
    formikError = formikErrorField;
  } else {
    // used in normal, flat fields
    formikError = formik.errors[fieldKey];
  }

  return (
    <>
      <label className={`${isRequired ? "required" : ""} fw-bold fs-6 mb-2`}>
        {label}
      </label>
      <input
        disabled={disabled}
        className="form-control form-control-solid mb-3 mb-lg-0 fw-bold"
        type={type}
        min={type === "number" ? minValue : undefined}
        max={type === "number" ? maxValue : undefined}
        placeholder={placeholder}
        {...fieldProps}
        value={value !== undefined ? value : displayRule() ? formikValue : ""}
        style={
          type === "number" && !allowNumberSpinnerButtons
            ? { MozAppearance: "textfield", appearance: "textfield" }
            : {}
        }
      />
      {type === "number" && !allowNumberSpinnerButtons && (
        <style>
          {`
            input::-webkit-outer-spin-button, input::-webkit-inner-spin-button {
              -webkit-appearance: none;
              margin: 0;
            }
          `}
        </style>
      )}
      {formikError && (
        <div className="text-danger">{getErrorMessage(formikError)}</div>
      )}
    </>
  );
};

export default RenderInputComponent;

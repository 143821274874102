import moment, { Moment } from "moment";
import { FC, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useListView } from "../../../../../generic/table/ListViewProvider";
import { useAccidentQueryResponse } from "../../../../../modules/api/accident/QueryResponseProvider";
import {
  AccidentDetailModel,
  AccidentLocationTypeModel,
  AddressModel,
  CompanyModel,
} from "../../../../../modules/api/accident/_models";
import { getCompanyDetails } from "../../../../../modules/api/accident/_requests";
import { getCurrentUserDetails } from "../../../../../modules/api/auth/core/_requests";
import { FactoryDetailModel } from "../../../../../modules/api/factory/_models";

import { OUTPUT_DATE_FORMAT } from "../../../../../generic/_variables";
import { RecordValueContainer } from "../../../../../generic/components/detail/RecordValueContainer";
import { RequestLoader } from "../../../../../generic/components/loader/RequestLoader";
import { RegistrationCardDetailModel } from "../../../../../modules/api/accident/core/forms/_models";
import {
  getAccidentLocationTypeById,
  getAccidentRegistrationCard,
} from "../../../../../modules/api/accident/core/forms/_requests";


export const getDateTime = (
  date: Moment | Date | undefined | string,
) => { 
  if (date) return moment(date).format("DD.MM.YYYY HH:mm");
  else return undefined;
}

export const getDate = (
  date: Moment | Date | undefined | string,
  format: string = "YYYY-MM-DD"
) => {
  if (date) return moment(date).format(format);
  else return undefined;
};

export const getTime = (timestamp: Moment | Date | undefined) => {
  if (!timestamp) return undefined;

  return moment(timestamp, "HH:mm:ss").format("HH:mm");
};

export const getAddress = (address: AddressModel | undefined) => {
  if (!address) return undefined;

  return `ul. ${address.street} ${address.house_number}, ${address.zip_code} ${address.city}`;
};

export const getName = (
  first_name: string | undefined,
  last_name: string | undefined
) => {
  if (!first_name || !last_name) return undefined;
  return `${first_name} ${last_name}`;
};

type Props = {
  isAccidentDetailLoading: boolean;
  accidentDetail: AccidentDetailModel | null;
  registrationCardDetailInfo: RegistrationCardDetailModel | null;
  factoryDetail: FactoryDetailModel | null;
};

const AccidentRegistrationCardDetail: FC<Props> = ({
  isAccidentDetailLoading,
  accidentDetail,
  registrationCardDetailInfo,
  factoryDetail,
}) => {
  const { setItemIdForUpdate } = useListView();
  const { refetch } = useAccidentQueryResponse();
  const [user_reporting, setUserReporting] = useState<any | null>(null);
  const [company, setCompany] = useState<CompanyModel | null>(null);
  const [
    registrationCardDetail,
    setRegistrationCardDetail,
  ] = useState<RegistrationCardDetailModel | null>(null);
  const [accidentLocationType, setAccidentLocationType] = useState<
    AccidentLocationTypeModel | null | undefined
  >(null);

  const [isAccidentLoading, setIsAccidentLoading] = useState<boolean>(true);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchRegistrationCardDetailData = async () => {
      if (registrationCardDetailInfo) {
        setRegistrationCardDetail(registrationCardDetailInfo);
        setIsAccidentLoading(false); // always set to false when done, regardless of success or error
        try {
          const accidentLocationTypeResponse = await getAccidentLocationTypeById(
            registrationCardDetailInfo?.accident_location_type as number
          );
          setAccidentLocationType(accidentLocationTypeResponse);
        } catch {
          setAccidentLocationType(undefined);
        }
      } else {
        try {
          const accidentResponse = await getAccidentRegistrationCard(
            Number(accidentDetail?.id)
          );
          setRegistrationCardDetail(accidentResponse);
          const accidentLocationTypeResponse = await getAccidentLocationTypeById(
            registrationCardDetail?.accident_location_type as number
          );
          setAccidentLocationType(accidentLocationTypeResponse);
        } catch (error) {
          console.error("Failed to fetch registration card details", error);
        } finally {
        }

        setIsAccidentLoading(false); // always set to false when done, regardless of success or error
      }
    };

    const fetchUserReporting = async () => {
      try {
        const response = await getCurrentUserDetails();
        setUserReporting(response.data);
      } catch (error) {
        console.error("Failed to fetch user reporting details", error);
      }
    };

    const fetchCompany = async () => {
      try {
        const response = await getCompanyDetails();
        setCompany(response);
      } catch (error) {
        console.error("Failed to fetch company details", error);
      }
    };

    fetchRegistrationCardDetailData();
    fetchCompany();
    fetchUserReporting();
  }, [accidentDetail, registrationCardDetailInfo]);

  const cancel = (withRefresh?: boolean) => {
    if (withRefresh) {
      refetch();
    }
    setItemIdForUpdate(undefined);
  };

  return (
    <>
      {isAccidentLoading ? (
        <RequestLoader />
      ) : (
        <div className="d-flex flex-row justify-content-between">
          {/* begin::Scroll */}
          <div className="d-flex flex-column w-100">
            {/* begin::Worker Search List Component */}
            <RecordValueContainer
              title="Dane poszkodowanego"
              enableBorder={false}
              recordsLeftSide={[
                {
                  label: "Imię i nazwisko",
                  value: `${accidentDetail?.injured_worker?.first_name}${" "}${
                    accidentDetail?.injured_worker?.last_name
                  }`,
                },
                {
                  label: "Adres zamieszkania",
                  value: getAddress(accidentDetail?.injured_worker?.address),
                },
                {
                  label: "PESEL",
                  value: accidentDetail?.injured_worker.pesel
                    ? `${accidentDetail?.injured_worker.pesel?.slice(
                        0,
                        4
                      )}*******`
                    : undefined,
                },
                {
                  label: "Data urodzenia",
                  value: accidentDetail?.injured_worker?.date_of_birth
                    ? `${getDate(
                        accidentDetail?.injured_worker?.date_of_birth
                      ), OUTPUT_DATE_FORMAT}`
                    : undefined,
                },
                // {
                //   label: "Miejsce urodzenia",
                //   value: "Warszawa",
                // },
              ]}
              recordsRightSide={[
                {
                  label: "Numer telefonu",
                  value: "+48 123456789",
                },
                {
                  label: "Stanowisko",
                  value: `${accidentDetail?.injured_worker.position}`,
                },
                {
                  label: "Tytuł ubezpieczenia zdrowotnego/chorobowego",
                  value: registrationCardDetail?.insurance_title || undefined,
                },
              ]}
            />
            <RecordValueContainer
              title="Miejsce wypadku"
              recordsLeftSide={[
                {
                  label: "Firma",
                  value: `${company?.name}`,
                },
                {
                  label: "Adres",
                  value: `${company?.location}`,
                },
                {
                  label: "NIP",
                  value: `${company?.nip}`,
                },
              ]}
            />

            <RecordValueContainer
              title="Szczegóły wypadku"
              recordsLeftSide={[
                {
                  label: "Numer wypadku",
                  value: `${registrationCardDetail?.accident_protocol_number}`,
                },
                {
                  label: "Data zdarzenia",
                  value: `${getDate(accidentDetail?.accident_ts, OUTPUT_DATE_FORMAT)}`,
                },
                {
                  label: "Typ wypadku",
                  value: `${accidentDetail?.accident_type.description}`,
                },
                {
                  label: "Stopień obrażeń",
                  value: `${accidentDetail?.accident_severity_level?.description}`,
                },
                {
                  label: "Rodzaj wypadku (liczebność)",
                  value: `${accidentDetail?.accident_cardinality?.description}`,
                },
                {
                  label: "Data zgłoszenia wypadku",
                  value: `${getDate(
                    moment(registrationCardDetail?.created_at), OUTPUT_DATE_FORMAT
                  )}`,
                },
              ]}
              recordsRightSide={[
                {
                  label: "Numer SKW",
                  value: `${registrationCardDetail?.skw_number}`,
                },
                {
                  label: "Lokalizacja wypadku (grupa)",
                  value:
                    accidentLocationType !== undefined
                      ? `${
                          accidentLocationType?.group?.name || "Proszę czekać"
                        }`
                      : undefined,
                },
                {
                  label: "Lokalizacja wypadku (typ)",
                  value:
                    accidentLocationType !== undefined &&
                    accidentLocationType !== null
                      ? `${accidentLocationType?.name} [${
                          accidentLocationType?.code &&
                          accidentLocationType?.code / 100 >= 1
                            ? accidentLocationType?.code
                            : `0${accidentLocationType?.code}`
                        }]`
                      : undefined,
                },
                {
                  label: "Liczba dni niezdolności do pracy",
                  value: `${registrationCardDetail?.worker_incapacity_days} dni`,
                },
              ]}
            />
            <RecordValueContainer
              title="Okoliczności wypadku"
              recordsLeftSide={[
                {
                  label: null,
                  value: `${registrationCardDetail?.accident_circumstances}`,
                },
              ]}
            />
            <RecordValueContainer
              title="Przyczyny wypadku"
              recordsLeftSide={[
                {
                  label: null,
                  value: `${registrationCardDetail?.accident_causes}`,
                },
              ]}
            />
            <RecordValueContainer
              title="Skutki wypadku (miejsce i rodzaj)"
              recordsLeftSide={[
                {
                  label: null,
                  value: `${accidentDetail?.accident_description}`,
                },
              ]}
            />
            <RecordValueContainer
              title="Wnioski i zalecenia profilaktyczne zespołu powypadkowego"
              recordsLeftSide={[
                {
                  label: null,
                  value: `${registrationCardDetail?.post_accident_team_suggestions}`,
                },
              ]}
            />
          </div>

          {/* rest code ...  */}
        </div>
      )}

      {/* end::Scroll */}
    </>
  );
};

export { AccidentRegistrationCardDetail };

import axios, { AxiosResponse } from "axios";
import { UserBaseModel } from "../auth/core/_models";
import { API_URL } from "../_variables";


const USER_NOTIFICATIONS_URL = `${API_URL}/notifications/user-confirmation-requests`;
const RECOMMENDATIONS_NOTIFICATIONS_URL = `${API_URL}/notifications/recommendations`;

export function getNewUserNotifications() {
  return axios
    .get(`${USER_NOTIFICATIONS_URL}`)
    .then((response: AxiosResponse<UserBaseModel[]>) => response.data);
}

export function getNewRecommendations() {
  return axios
    .get(`${RECOMMENDATIONS_NOTIFICATIONS_URL}`)
    .then((response: AxiosResponse<any[]>) => response.data);
}

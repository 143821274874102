/* eslint-disable react-hooks/exhaustive-deps */
import { FC, useContext, useEffect, useMemo, useState } from "react";
import { useQuery } from "react-query";
import {
    PaginationState,
    QUERIES,
    WithChildren,
    createResponseContext,
    initialQueryResponse,
    initialQueryState,
    stringifyRequestQuery,
} from "../../../../_metronic/helpers";
import { useQueryRequest } from "../../../generic/table/QueryRequestProvider";
import { UserBaseModel } from "../auth/core/_models";
import { getUsers } from "./_requests"; // Change to your getUsers function

const QueryResponseContext = createResponseContext<UserBaseModel>(
  initialQueryResponse
);

const QueryResponseProvider: FC<WithChildren> = ({ children }) => {
  const { state } = useQueryRequest();
  const [query, setQuery] = useState<string>(stringifyRequestQuery(state));
  const updatedQuery = useMemo(() => stringifyRequestQuery(state), [state]);

  useEffect(() => {
    if (query !== updatedQuery) {
      setQuery(updatedQuery);
    }
  }, [updatedQuery]);

  const { isFetching, refetch, data: response } = useQuery(
    `${QUERIES.USER_LIST}-${query}`,
    () => {
      return getUsers(query);
    },
    { cacheTime: 0, keepPreviousData: true, refetchOnWindowFocus: false }
  );

  useEffect(() => {
    // Listen to userListChange event
    const handleUserListChange = () => {
      refetch();
    };
    window.addEventListener("userListChange", handleUserListChange);
    return () => {
      window.removeEventListener("userListChange", handleUserListChange);
    };
  }, [refetch]);

  return (
    <QueryResponseContext.Provider
      value={{ isLoading: isFetching, refetch, response, query }}
    >
      {children}
    </QueryResponseContext.Provider>
  );
};

const useQueryResponse = () => useContext(QueryResponseContext);
const useQueryResponseData = () => {
  const { response } = useQueryResponse();
  if (!response) {
    return [];
  }

  return response?.results || [];
};

const useQueryResponsePagination = () => {
  const defaultPaginationState: PaginationState = {
    ...initialQueryState,
  };

  const { response } = useQueryResponse();
  if (!response || !response.count) {
    return defaultPaginationState;
  }
  return {
    page: 1,
    pageSize: 10,
    count: response?.count,
    next: response?.next,
    previous: response?.previous,
  };
};

const useQueryResponseLoading = (): boolean => {
  const { isLoading } = useQueryResponse();
  return isLoading;
};

export {
    QueryResponseProvider,
    useQueryResponse,
    useQueryResponseData, useQueryResponseLoading, useQueryResponsePagination
};


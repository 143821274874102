import { FC, Suspense, lazy } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import TopBarProgress from "react-topbar-progress-indicator";
import { getCSSVariableValue } from "../../_metronic/assets/ts/_utils";
import { WithChildren } from "../../_metronic/helpers";
import { MasterLayout } from "../../_metronic/layout/MasterLayout";
import { USER_GROUPS, getAuth } from "../pages/auth";
import { DashboardWrapper } from "../pages/dashboard/DashboardWrapper";
const AccidentListWrapper = lazy(() =>
  import("../pages/accident/wrappers/AccidentListWrapper")
);
const AccidentStatisticsWrapper = lazy(() =>
  import("../pages/accident/wrappers/AccidentStatisticsWrapper")
);

const AccidentCreateWrapper = lazy(() =>
  import("../pages/accident/wrappers/AccidentCreateWrapper")
);
const AccidentDetailPage = lazy(() =>
  import("../pages/accident/wrappers/AccidentDetailWrapper")
);
const UserListWrapper = lazy(() => import("../pages/user/UserListWrapper"));
const OrganizationDetailPage = lazy(() =>
  import("../pages/company/wrappers/OrganizationWrapper")
);
const FactoryDetailPage = lazy(() =>
  import("../pages/company/wrappers/FactoryWrapper")
);
const WorkerListWrapper = lazy(() =>
  import("../pages/worker/WorkerListWrapper")
);
const RecommendationListWrapper = lazy(() =>
  import("../pages/recommendation/wrappers/RecommendationListWrapper")
);
const RecommendationCreateWrapper = lazy(() =>
  import("../pages/recommendation/wrappers/RecommendationCreateWrapper")
);
const RecommendationDetailPage = lazy(() =>
  import("../pages/recommendation/wrappers/RecommendationDetailWrapper")
);

const RecommendationStatisticsPage = lazy(() =>
  import("../pages/recommendation/wrappers/RecommendationStatisticsWrapper")
);
const AccountSettingsDetailPage = lazy(() =>
  import("../pages/account/wrappers/AccountSettingsWrapper")
);
const FPWrapper = lazy(() =>
  import("../pages/fire-protection/wrapper/FPOverviewWrapper")
);

const OSHTrainingWrapper = lazy(() => 
  import("../pages/osh-training/wrapper/OSHTrainingOverviewWrapper")
);

const withSuspensedView = (Component: FC) => (
  <SuspensedView>
    <Component />
  </SuspensedView>
);

const PrivateRoutes = () => {
  const isAdminUser = getAuth()?.groups.includes(USER_GROUPS.ADMIN);
  return (
    <Routes>
      
      <Route element={<MasterLayout />}>
        <Route
          path="ustawienia"
          element={withSuspensedView(AccountSettingsDetailPage)}
        />
        {/* Redirect to Dashboard after success login/registration */}
        <Route path="auth/*" element={<Navigate to="/dashboard" />} />
        {/* Pages */}
        <Route path="dashboard" element={withSuspensedView(DashboardWrapper)} />

        {/* Refactored Routes with withSuspensedView */}
        <Route
          path="wypadki"
          element={withSuspensedView(AccidentListWrapper)}
        />
        <Route
          path="wypadki/statystyki"
          element={withSuspensedView(AccidentStatisticsWrapper)}
        />
        <Route
          path="wypadki/dodaj"
          element={withSuspensedView(AccidentCreateWrapper)}
        />
        <Route
          path="wypadki/:accident_id/*"
          element={withSuspensedView(AccidentDetailPage)}
        />
        <Route
          path="zalecenia-bhp"
          element={withSuspensedView(RecommendationListWrapper)}
        />
        <Route
          path="zalecenia-bhp/dodaj"
          element={withSuspensedView(RecommendationCreateWrapper)}
        />
        <Route
          path="zalecenia-bhp/statystyki"
          element={withSuspensedView(RecommendationStatisticsPage)}
        />
        <Route
          path="zalecenia-bhp/:recommendation_id/*"
          element={withSuspensedView(RecommendationDetailPage)}
        />
        <Route path="ppoz/*" element={withSuspensedView(FPWrapper)} />
        <Route path="szkolenia/*" element={withSuspensedView(OSHTrainingWrapper)} />
        
        {isAdminUser && (
          <>
            <Route
              path="uzytkownicy"
              element={withSuspensedView(UserListWrapper)}
            />
            <Route
              path="firma"
              element={withSuspensedView(OrganizationDetailPage)}
            />
            <Route
              path="zaklad"
              element={withSuspensedView(FactoryDetailPage)}
            />
            <Route
              path="pracownicy"
              element={withSuspensedView(WorkerListWrapper)}
            />
          </>
        )}

        {/* Other routes */}
        {/* ... */}

        <Route path="*" element={<Navigate to="/error/404" />} />
      </Route>
    </Routes>
  );
};

const SuspensedView: FC<WithChildren> = ({ children }) => {
  const baseColor = getCSSVariableValue("--bs-primary");
  TopBarProgress.config({
    barColors: {
      "0": baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  });
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>;
};

export { PrivateRoutes };

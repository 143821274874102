import { FormikProps } from "formik";
import { FC } from "react";
import { useParams } from "react-router-dom";
import { RenderDatePickerComponent } from "../../../../generic/components/datepickers/RenderDatePickerComponent";
import RenderListSelectComponent from "../../../../generic/components/form/renderers/RenderListSelectComponent";
import { getOSHTrainingMembersWithAccessKey } from "../../../../modules/api/osh-training/_requests";

interface Props {
  formik: FormikProps<any>;
}
const Step1: FC<Props> = ({ formik }) => {

  const { osh_training_id } = useParams<{ osh_training_id: string }>();
  const params = new URLSearchParams(window.location.search);
  const accessKey = params.get("access_key") as string;

  return (
    <div
      className="col-12 mb-7 current-display-initial current"
      data-kt-stepper-element="content"
    >
      <div className="col-12 col-l-6 mx-auto">
        <div className="fv-row mb-7">
          <RenderListSelectComponent
            fieldKey="osh_training_member"
            fetchFunction={() => getOSHTrainingMembersWithAccessKey(Number(osh_training_id), accessKey)}
            changeHandler={(value) => {
              formik.setFieldValue("osh_training_member", {
                id: value?.value,
                first_name: value?.label.split(" ")[0],
                last_name: value?.label.split(" ")[1] || "", // This is a simplistic transformation; adjust as needed.
              });
            }}
            selectedItem={{
              value: formik.values?.id,
              label: `${formik.values?.worker?.first_name} ${formik.values?.worker?.last_name}`,
            }}
            placeholderText="Wyszukaj swoje imię i nazwisko"
            labelText="Imię i nazwisko"
            formatItem={(item: any) => ({
              value: item?.id,
              label: item.worker?.first_name + " " + item.worker?.last_name,
            })}
            formik={formik}
            required={true}
          />
        </div>
        <div className="fv-row mb-7">
          <RenderDatePickerComponent
            formik={formik}
            label="Data urodzenia"
            fieldKey="birth_date"
            isRequired={true}
            handleDateChange={(date) => {
              formik.setFieldValue("birth_date", date);
            }}
          />
        </div>
        <label
          className="form-check form-check-inline"
          htmlFor="kt_login_toc_agree"
        >
          <input
            className="form-check-input"
            type="checkbox"
            id="kt_login_toc_agree"
            {...formik.getFieldProps("terms_accepted")}
          />
          <span>
            Zgadzam się na przechowywanie i przetwarzanie moich danych
            osobowych, tj. Imię, nazwisko, data urodzenia, rezultat szkolenia w
            ramach oprogramowania BhApp.
          </span>
        </label>
      </div>
    </div>
  );
};

export { Step1 };

import { FC } from "react";

type Props = {
  groups: string[];
};

type BadgeColorMap = {
  [key: string]: string;
};

const BADGE_COLOR: BadgeColorMap = {
  admin: "badge-success",
  osh_worker: "badge-warning",
  security_worker: "badge-danger",
  outsourced_worker: "badge-primary",
};

export const GROUP_NAME: BadgeColorMap = {
  admin: "Administrator",
  osh_worker: "Pracownik BHP",
  outsourced_worker: "Pracownik firmy zewnętrznej",
  security_worker: "Inspektor PPOŻ",
};

const UserGroupCell: FC<Props> = ({ groups }) => {
  return (
    <div>
      {groups.length > 0 ? (
        groups.map((group, index) => (
          <span
            key={index}
            className={`badge me-3  ${BADGE_COLOR[group]} fs-7`}
          >
            {GROUP_NAME[group]}
          </span>
        ))
      ) : (
        <span className="badge me-3 badge-secondary fs-7">Brak grupy</span>
      )}
    </div>
  );
};

export { UserGroupCell };

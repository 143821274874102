import { FormikProps } from "formik";
import { FC, useEffect, useRef, useState } from "react";
import CreatableSelect from "react-select/creatable";
import { createOutsourcedWorker } from "../../../../../modules/api/worker/_requests";
import { SelectOption } from "./ListSearchComponent";
import {
  reactSelectClassConfig,
  reactSelectCustomStyles,
} from "./_customStyles";

interface Props {
  selectedItems: SelectOption[] | SelectOption; // Now can be an array or a single item
  onItemsChange: (
    selectedOptions: SelectOption[] | SelectOption
  ) => void;
  fetchItems: (params: any) => Promise<any>;
  formatOption: (item: any) => SelectOption;
  placeholder: string;
  noOptionsMessage: string;
  label: string;
  disabled?: boolean;
  required?: boolean;
  fieldName: string;
  formik: FormikProps<any>;
  isMulti: boolean;
}

const CreatableSelectComponent: FC<Props> = ({
  selectedItems,
  onItemsChange,
  fetchItems,
  formatOption,
  placeholder,
  noOptionsMessage,
  label,
  formik,
  fieldName,
  disabled = false,
  required = true,
  isMulti,
}) => {
  // Adjusting state initialization based on isMulti
  const [items, setItems] = useState<SelectOption[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const initialState = isMulti
    ? Array.isArray(selectedItems)
      ? selectedItems
      : []
    : selectedItems;
  const [selectedItemsState, setSelectedItemsState] = useState(initialState);
  const selectRef = useRef<any>(null);

  useEffect(() => {
    setIsLoading(true);
    fetchItems({ pagination_enabled: false })
      .then((response) => {
        setItems(response.data);
        setIsLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setIsLoading(false);
      });
  }, []);

  useEffect(() => {
    setSelectedItemsState(
      isMulti
        ? Array.isArray(selectedItems)
          ? selectedItems
          : []
        : selectedItems
    );
  }, [selectedItems, isMulti]);

  const options = items.map(formatOption);

  const handleSelectChange = (
    selectedOption: SelectOption | SelectOption[] | null
  ) => {
    if (isMulti) {
      const newSelectedItems = selectedOption || [];
      onItemsChange(newSelectedItems);
      setSelectedItemsState(newSelectedItems);
    } else {
      onItemsChange(selectedOption as SelectOption);
      setSelectedItemsState(selectedOption as SelectOption);
    }

    if (selectRef.current) {
      selectRef.current.blur();
    }
  };

  const handleCreate = (inputValue: string) => {
    setIsLoading(true);

    // Split the inputValue based on spaces
    const words = inputValue.split(" ").filter((word) => word); // filter() is used to remove any potential empty strings

    // Check if there are at least two words
    if (words.length < 2) {
      // Set a formik field error
      formik.setFieldError(fieldName, "Wpisz imię i nazwisko pracownika.");
      setIsLoading(false);
      return; // Exit the function
    }

    // Extract the first name and combine the rest as last name
    const firstName = words[0];
    const lastName = words.slice(1).join(" ");
    const newOption = createOutsourcedWorker(firstName, lastName)
      .then((response) => {
        const option = {
          value: response.id,
          label: response.first_name + " " + response.last_name,
        };
        if (isMulti) {
          const updatedItems = [
            ...(selectedItemsState as SelectOption[]),
            option,
          ];
          setSelectedItemsState(updatedItems);
          onItemsChange(updatedItems);
        } else {
          setSelectedItemsState(option);
          onItemsChange(option);
        }
        setItems((prevItems) => [...prevItems, response]);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const customStyles = reactSelectCustomStyles;
  const classNames = reactSelectClassConfig;
  return (
    <>
      <label className={`form-label fw-bold ${required ? "required" : ""}`}>
        {label}
      </label>
      <CreatableSelect
        isMulti={isMulti}
        isClearable
        isDisabled={isLoading}
        isLoading={isLoading}
        styles={customStyles}
        value={
          isMulti ? selectedItemsState : [selectedItemsState].filter(Boolean)
        } // Adjusting value for single select
        onChange={handleSelectChange as any}
        onCreateOption={handleCreate}
        options={options as any}
        classNames={classNames}
        components={{
          IndicatorsContainer: () => null,
        }}
        placeholder={placeholder}
        formatCreateLabel={(value) =>
          `Utwórz (w kolejności imię-nazwisko): "${value}"`
        }
        noOptionsMessage={() => "Brak opcji"}
      />
    </>
  );
};

export { CreatableSelectComponent };

import { useFormik } from "formik";
import { Dispatch, FC, SetStateAction, useState } from "react";
import RenderInputComponent from "../../../../app/generic/components/form/renderers/RenderInputComponent";
import { useQueryRequest } from "../../../../app/generic/table/QueryRequestProvider";
import { getDate } from "../../../../app/pages/accident/detail/protocols/AccidentRegistration/AccidentRegistrationCardDetail";
import { initialQueryState } from "../../../helpers/crud-helper/models";
import { FilteringDataModel } from "../../widgets/mixed/RenderPieChart";

interface Props {
  chartData?: any;
  setChartData?: any;
  useChartData: any;
  filteringValues?: FilteringDataModel[];
  setSubTitleFilteringName: Dispatch<SetStateAction<string>>;
  setYearInterval: Dispatch<SetStateAction<string[]>>;
  initialFilteringValue: string;
}

/* eslint-disable jsx-a11y/anchor-is-valid */
export const StatisticsDropdown: FC<Props> = ({
  useChartData,
  setSubTitleFilteringName,
  filteringValues = [],
  setYearInterval,
  initialFilteringValue,
}) => {
  const { updateState, state } = useQueryRequest();
  const { chartData, refetchData, isChartDataLoading, grouppedByTimeRange } = useChartData();

  const [filteredValue, setFilteredValue] = useState<string>(
    initialFilteringValue
    ? (filteringValues.find(
        (item: any) => item?.value == initialFilteringValue
      )?.value as string)
    : filteringValues[0].value
  );
  const resetData = () => {
    updateState({ filter: undefined, ...initialQueryState });
  };
  const formik = useFormik({
    initialValues: {
      year_from: getDate(new Date("2023-01-01"), "YYYY"),
      year_to: getDate(new Date(), "YYYY"),
    },
    onSubmit: (values) => {},
  });
  const filterData = () => {
    updateState({ filter: filteredValue, ...initialQueryState });
    updateState({
      filter: { filteredValue },
      ...initialQueryState,
    });
    setSubTitleFilteringName(
      filteringValues.find((item) => item.value === filteredValue)
        ?.subTitleFilterName || ""
    );
    setYearInterval([
      formik.values.year_from as string,
      formik.values.year_to as string,
    ]);

    refetchData(
      `filter_field=${filteredValue}&year_from=${formik.values.year_from}&year_to=${formik.values.year_to}&group_by_time_range=${grouppedByTimeRange}`
    );
  };

  return (
    <div
      className="menu menu-sub menu-sub-dropdown w-250px w-md-300px"
      data-kt-menu="true"
    >
      <div className="px-7 py-5">
        <div className="fs-5 text-dark fw-bolder">Opcje filtrowania</div>
      </div>

      <div className="separator border-gray-200"></div>

      <div className="px-7 py-5">
        <div className="mb-10">
          <label className="form-label fw-bold">Wykres na podstawie:</label>

          <div>
            <select
              className="form-select form-select-solid"
              data-kt-select2="true"
              data-placeholder="Select option"
              data-allow-clear="true"
              defaultValue={filteringValues[0]?.value}
              onChange={(e) => setFilteredValue(e.target.value)}
              value={filteredValue}
            >
              {filteringValues?.map((item) => (
                <option value={item.value}>{item.label}</option>
              ))}
            </select>
          </div>
        </div>
        <div className="mb-10 row px-0 py-0 mx-0 my-0 justify-content-between">
          <div className="col-5 px-0 me-1">
            <RenderInputComponent
              formik={formik}
              fieldKey={"year_from"}
              type="number"
              placeholder={formik.values.year_from as string}
              minValue={2000}
              maxValue={new Date().getFullYear() + 1}
              label={<span>{"Początek"}</span>}
              allowNumberSpinnerButtons={true}
            />
          </div>
          <div className="col-5 px-0 ms-1">
            <RenderInputComponent
              formik={formik}
              fieldKey={"year_to"}
              type="number"
              minValue={2000}
              maxValue={new Date().getFullYear() + 1}
              placeholder={formik.values.year_to as string}
              label={<span>{"Koniec"}</span>}
              allowNumberSpinnerButtons={true}
            />
          </div>
        </div>

        <div className="d-flex justify-content-end">
          <button
            type="submit"
            className="btn btn-sm btn-primary"
            data-kt-menu-dismiss="true"
            onClick={filterData}
          >
            Zastosuj
          </button>
        </div>
      </div>
    </div>
  );
};

export const reactSelectCustomStyles = {
    menu: (provided: any, state: any) => ({
      ...provided,
      // background: 'blue'
    }),
    MenuList: (provided: any, state: any) => ({
      ...provided,
      background: "green",
    }),
    option: (provided: any, state: any) => ({
      ...provided,
      borderBottom: "none",
      borderRadius: "5px",
      // backgroundColor: state.isSelected ? "#009ef7;" : "white", todo: fix hover
      // onHover: {
      //   backgroundColor: "#009ef7",
      //   color: "white"

      // }
    }),
    Input: (provided: any) => ({
      ...provided,
      backgroundColor: "green",
    }),
    control: () => ({
      border: "none",
      display: "flex",
      padding: "0.3rem",
      color: "#5e6278",
      borderRadius: "5px",
      // fontFamily: 'inherit',
      // backgroundColor: "#f9f9f9",
      // color: "#5e6278",
      // transition:"color 0.2s ease",
      // fontSize: "1.1rem",
      // fontWeight: "500",
    }),
    valueContainer: (provided: any) => ({
      ...provided,
    }),
    singleValue: (provided: any) => ({
      ...provided,
      color: "#5e6278",
    }),
  };


export const reactSelectClassConfig = { 
    control: () => "form-select form-select-solid",
    // IndicatorsContainer: () => "d-none w-100",
    DropdownIndicator: () => "d-none",
}

import clsx from "clsx";
import {
  Dispatch,
  FC,
  SetStateAction,
  useEffect,
  useRef,
  useState,
} from "react";
import { useToastMessage } from "../../../../app/generic/components/toast/ToastMessageProvider";
import { TOAST_MESSAGES } from "../../../../app/generic/components/toast/ToastMessages";
import {
  getNewRecommendations,
  getNewUserNotifications,
} from "../../../../app/modules/api/notification/_requests";
import { getOSHTrainingNotifications } from "../../../../app/modules/api/osh-training/_requests";
import {
  RecommendationDetailModel,
  RecommendationNotificationModel,
} from "../../../../app/modules/api/recommendation/_models";
import { getFireProtectionNotifications } from "../../../../app/modules/api/technical-security-system/_requests";
import { UserBaseModel, getAuth, useAuth } from "../../../../app/pages/auth";
import { isUserOutsourcedWorker } from "../../../../app/pages/recommendation/helpers/permissions";
import { KTIcon } from "../../../helpers";
import { HeaderNotificationsMenu, HeaderUserMenu } from "../../../partials";
import { useLayout } from "../../core";

const itemClass = "ms-1 ms-lg-3";
const btnClass =
  "btn btn-icon btn-custom btn-icon-muted btn-active-light btn-active-color-primary w-35px h-35px w-md-40px h-md-40px";
const userAvatarClass = "symbol-35px symbol-md-40px";
const btnIconClass = "fs-1";

const WS_URL = process.env.REACT_APP_WS_URL;

interface Props {
  onUserGrantRoleModalChange: Dispatch<SetStateAction<UserBaseModel | null>>;
}
const Navbar: FC<Props> = ({ onUserGrantRoleModalChange }) => {
  const { config } = useLayout();
  const { currentUser, logout } = useAuth();
  const auth = getAuth();
  const [userNotificationWS, setUserNotificationWS] = useState<any>(null);
  const [
    recommendationNotificationWS,
    setRecommendationNotificationWS,
  ] = useState<any>(null);
  const [newUserNotifications, setNewUserNotifications] = useState<
    UserBaseModel[] | null
  >(null);
  const [
    newRecommendationNotifications,
    setNewRecommendationNotifications,
  ] = useState<RecommendationNotificationModel[] | null>(null);
  const newUserNotificationsRef = useRef<UserBaseModel[] | null>(null);
  const newRecommendationNotificationsRef = useRef<
    RecommendationDetailModel[] | null
  >(null);
  const [newUserNotificationsCount, setUserNewNotificationsCount] = useState<
    number
  >(0);
  const [
    newRecommendationNotificationsCount,
    setRecommendationNewNotificationsCount,
  ] = useState<number>(0);
  const [notificationsLoading, setNotificationsLoading] = useState<boolean>(
    true
  );

  const [
    fireProtectionNotificationWS,
    setFireProtectionNotificationWS,
  ] = useState<any>(null);
  const [
    newFireProtectionNotifications,
    setNewFireProtectionNotifications,
  ] = useState<any[] | null>(null);

 
  const [
    newFireProtectionNotificationsCount,
    setFireProtectionNotificationsCount,
  ] = useState<number>(0);
  const fireProtectionNotificationsRef = useRef<any[] | null>(null);
  const [
    fireProtectionNotificationInterval,
    setFireProtectionNotificationInterval,
  ] = useState<NodeJS.Timeout | null>(null);

  const [
    oshTrainingNotificationWS,
    setOSHTrainingNotificationWS,
  ] = useState<any>(null);
  const [
    newOSHTrainingNotifications,
    setNewOSHTrainingNotifications,
  ] = useState<any[] | null>(null);
  
  
  const [
    newOSHTrainingNotificationsCount,
    setOSHTrainingNotificationsCount,
  ] = useState<number>(0);
  const oshTrainingNotificationsRef = useRef<any[] | null>(null);
  const [
    oshTrainingNotificationInterval,
    setOSHTrainingNotificationInterval,
  ] = useState<NodeJS.Timeout | null>(null);
  

  const { setToastMessage } = useToastMessage();
  const [isUserAdmin, setIsUserAdmin] = useState<boolean>(false);
  let messageCounter: number = 0;
  const [
    userNotificationInterval,
    setUserNotificationInterval,
  ] = useState<NodeJS.Timeout | null>(null);
  const [
    recommendationNotificationInterval,
    setRecommendationNotificationInterval,
  ] = useState<NodeJS.Timeout | null>(null);

  useEffect(() => {
    newUserNotificationsRef.current = newUserNotifications;
  }, [userNotificationWS, newUserNotifications]);

  // useEffect(() => {
  //   newRecommendationNotificationsRef.current = newRecommendationNotifications;
  // }, [recommendationNotificationWS, newRecommendationNotifications]);
  const handleWebSocketMessage = (
    data: any,
    notificationsRef: any,
    setNotifications: any,
    setNotificationsCount: any
  ) => {
    messageCounter += 1;
    if (
      notificationsRef.current &&
      notificationsRef.current.length < data.length
    ) {
      setToastMessage(
        TOAST_MESSAGES.user.grant_role_request(
          data[0].first_name,
          data[0].last_name
        )
      );
    }
    setNotificationsLoading(false);
    setNotifications(data);
    setNotificationsCount(data.length);
    notificationsRef.current = data;
  };

  const handleWebSocketError = async (
    wsInstance: WebSocket,
    fetchFunction: () => Promise<any>,
    setNotifications: React.Dispatch<React.SetStateAction<any>>,
    setNotificationsCount: React.Dispatch<React.SetStateAction<number>>,
    setIntervalState: React.Dispatch<
      React.SetStateAction<NodeJS.Timeout | null>
    >,
    currentIntervalState: NodeJS.Timeout | null
  ) => {
    try {
      const data = await fetchFunction();
      setNotifications(data);
      setNotificationsCount(data.length);
      setNotificationsLoading(false);
    } catch (error) {
      console.error("Failed to fetch data from API:", error);
    } finally {
      wsInstance.close();
    }

    if (currentIntervalState) return; // If there's already an interval set, don't set another one

    const intervalId = setInterval(async () => {
      try {
        const data = await fetchFunction();
        setNotifications(data);
        setNotificationsCount(data.length);
        setNotificationsLoading(false);
      } catch (error) {
        console.error("Failed to fetch data from API:", error);
      }
    }, 60 * 1000); // 60 seconds

    setIntervalState(intervalId);
  };

  const initiateWebSocket = (
    url: string,
    stateSetter: React.Dispatch<React.SetStateAction<any>>,
    notificationsRef: any,
    setNotifications: any,
    setNotificationsCount: any,
    fetchFunction: () => Promise<any>,
    setIntervalState: React.Dispatch<
      React.SetStateAction<NodeJS.Timeout | null>
    >,
    currentIntervalState: NodeJS.Timeout | null
  ) => {
    if (stateSetter || !auth) return;
    const wsInstance = new WebSocket(url, ["Bearer", `${auth.access}`]);
    wsInstance.onmessage = (msg) => {
      handleWebSocketMessage(
        JSON.parse(msg.data),
        notificationsRef,
        setNotifications,
        setNotificationsCount
      );
    };
    wsInstance.onerror = (err) => {
      handleWebSocketError(
        wsInstance,
        fetchFunction,
        setNotifications,
        setNotificationsCount,
        setIntervalState,
        currentIntervalState
      );
    };

    return wsInstance;
  };

  useEffect(() => {
    if (userNotificationWS || !auth) return;
    if (auth?.groups.includes("admin")) {
      setIsUserAdmin(true);
      setUserNotificationWS(
        initiateWebSocket(
          `${WS_URL}/account/notifications`,
          userNotificationWS,
          newUserNotificationsRef,
          setNewUserNotifications,
          setUserNewNotificationsCount,
          getNewUserNotifications, // pass the API call function here
          setUserNotificationInterval,
          userNotificationInterval
        )
      );
    }

    return () => {
      if (userNotificationInterval) {
        clearInterval(userNotificationInterval);
      }
    };
  }, [userNotificationWS, auth, newUserNotifications]);

  useEffect(() => {
    if (recommendationNotificationWS || !auth) return;
    setRecommendationNotificationWS(
      initiateWebSocket(
        `${WS_URL}/recommendation/notifications`,
        recommendationNotificationWS,
        newRecommendationNotificationsRef,
        setNewRecommendationNotifications,
        setRecommendationNewNotificationsCount,
        getNewRecommendations, // pass the API call function here
        setRecommendationNotificationInterval,
        recommendationNotificationInterval
      )
    );

    return () => {
      if (recommendationNotificationInterval) {
        clearInterval(recommendationNotificationInterval);
      }
    };
  }, [recommendationNotificationWS, auth, newUserNotifications]);

  useEffect(() => {
    if (fireProtectionNotificationWS || !auth) return;
    if (isUserOutsourcedWorker(auth)) return;

    setFireProtectionNotificationWS(
      initiateWebSocket(
        `${WS_URL}/fire-protection/notifications`,
        fireProtectionNotificationWS,
        fireProtectionNotificationsRef,
        setNewFireProtectionNotifications,
        setFireProtectionNotificationsCount,
        getFireProtectionNotifications, // pass the API call function here
        setFireProtectionNotificationInterval,
        fireProtectionNotificationInterval
      )
    );

    return () => {
      if (fireProtectionNotificationInterval) {
        clearInterval(fireProtectionNotificationInterval);
      }
    };
  }, [fireProtectionNotificationWS, auth, newFireProtectionNotifications]);

  useEffect(() => {
    if (oshTrainingNotificationWS || !auth) return;
    if (isUserOutsourcedWorker(auth)) return;
  
    setOSHTrainingNotificationWS(
      initiateWebSocket(
        `${WS_URL}/osh-training/notifications`,
        oshTrainingNotificationWS,
        oshTrainingNotificationsRef,
        setNewOSHTrainingNotifications,
        setOSHTrainingNotificationsCount,
        getOSHTrainingNotifications, // pass the API call function here
        setOSHTrainingNotificationInterval,
        oshTrainingNotificationInterval
      )
    );
  
    return () => {
      if (oshTrainingNotificationInterval) {
        clearInterval(oshTrainingNotificationInterval);
      }
    };
  }, [oshTrainingNotificationWS, auth, newOSHTrainingNotifications]);
  
  // useEffect(() => {
  //   if (userNotificationWS || !auth) return;
  //   setIsUserAdmin(auth.groups.includes("admin"));
  //   if (!isUserAdmin) return;

  //   const socketUrl = `${WS_URL}/account/notifications`;
  //   const wsInstance = new WebSocket(socketUrl, ["Bearer", `${auth.access}`]);

  //   wsInstance.onopen = () => {
  //     console.log("WebSocket connected");
  //   };

  //   wsInstance.onmessage = (msg) => {
  //     messageCounter += 1;
  //     const data = JSON.parse(msg.data);
  //     if (
  //       newUserNotificationsRef.current &&
  //       newUserNotificationsRef.current.length < data.length
  //     ) {
  //       const message = `${data[0].first_name} ${data[0].last_name} prosi o przyznanie uprawnień`;
  //       setToastMessage(message); // Setting up the toast content
  //       setShowToastMessage(true);
  //     }

  //     setNotificationsLoading(false);
  //     setNewUserNotifications(data);
  //     setNewNotificationsCount(data.length);
  //     newUserNotificationsRef.current = data;
  //   };

  //   wsInstance.onerror = (err) => {
  //     console.log("WebSocket encountered error: ", err, "Closing socket");
  //     setNotificationsLoading(false);
  //     wsInstance.close();
  //   };
  //   setUserNotificationWS(wsInstance);
  // }, [userNotificationWS, auth, newUserNotifications, newNotificationsCount]);

  // useEffect(() => {
  //   if (recommendationNotificationWS || !auth) return;

  //   const socketUrl = `${WS_URL}/recommendation/notifications`;
  //   const wsInstance = new WebSocket(socketUrl, ["Bearer", `${auth.access}`]);

  //   wsInstance.onopen = () => {
  //     console.log("WebSocket connected");
  //   };

  //   wsInstance.onmessage = (msg) => {
  //     messageCounter += 1;
  //     const data = JSON.parse(msg.data);
  //     if (
  //       newRecommendationNotificationsRef.current &&
  //       newRecommendationNotificationsRef.current.length < data.length
  //     ) {
  //       const message = `${data[0].first_name} ${data[0].last_name} prosi o przyznanie uprawnień`;
  //       setToastMessage(message); // Setting up the toast content
  //       setShowToastMessage(true);
  //     }

  //     setNotificationsLoading(false);
  //     setNewRecommendations(data);
  //     setNewNotificationsCount(data.length);
  //     newRecommendationNotificationsRef.current = data;
  //   };

  //   wsInstance.onerror = (err) => {
  //     console.log("WebSocket encountered error: ", err, "Closing socket");
  //     setNotificationsLoading(false);
  //     wsInstance.close();
  //   };
  //   setRecommendationNotificationWS(wsInstance);
  // }, [
  //   recommendationNotificationWS,
  //   auth,
  //   newUserNotifications,
  //   newNotificationsCount,
  // ]);

  return (
    <>
      <div className="app-navbar flex-shrink-0">
        <div
          className={clsx("app-navbar-item align-items-stretch", itemClass)}
        ></div>

        <div className={clsx("app-navbar-item", itemClass)}>
          <div
            data-kt-menu-trigger="{default: 'click'}"
            data-kt-menu-attach="parent"
            data-kt-menu-placement="bottom-end"
            className={`${btnClass} position-relative`}
          >
            <KTIcon iconName="message-text-2" className={btnIconClass} />
            {newUserNotificationsCount === 0 &&
            newRecommendationNotificationsCount === 0 &&
            newFireProtectionNotificationsCount === 0 ? null : (
              <>
                {/* <span className="bullet bullet-dot bg-success h-6px w-6px position-absolute translate-middle top-0 start-50 animation-blink" /> */}
                <div>
                  <span
                    className="position-absolute top-0 start-50 fw-bolder text-white fs-9 bg-danger animation-blink"
                    style={{
                      borderRadius: "50%",
                      padding: "2px 5px",
                    }}
                  >
                    {newUserNotificationsCount +
                      newRecommendationNotificationsCount +
                      newFireProtectionNotificationsCount + newOSHTrainingNotificationsCount}{" "}
                  </span>
                </div>
              </>
            )}
          </div>
          {
            <HeaderNotificationsMenu
              newUserNotifications={newUserNotifications}
              newRecommendationNotifications={newRecommendationNotifications}
              notificationsLoading={notificationsLoading}
              onUserGrantRoleModalChange={onUserGrantRoleModalChange}
              fireProtectionNotifications={newFireProtectionNotifications}
              oshTrainingNotifications={newOSHTrainingNotifications}
              isUserAdmin={isUserAdmin}
            />
          }
        </div>

        {/* USER PROFILE MENU */}
        <div className={clsx("app-navbar-item", itemClass)}>
          <div
            className={clsx("cursor-pointer symbol", userAvatarClass)}
            data-kt-menu-trigger="{default: 'click'}"
            data-kt-menu-attach="parent"
            data-kt-menu-placement="bottom-end"
          >
            <div className="avatar avatar-online">
              <span className="avatar-initial rounded-square bg-primary fs-5">
                {currentUser?.first_name &&
                  currentUser?.first_name[0].toLocaleUpperCase()}
              </span>
            </div>
          </div>
          <HeaderUserMenu />
        </div>

        {config.app?.header?.default?.menu?.display && (
          <div
            className="app-navbar-item d-lg-none ms-2 me-n3"
            title="Show header menu"
          >
            <div
              className="btn btn-icon btn-active-color-primary w-35px h-35px"
              id="kt_app_header_menu_toggle"
            >
              <KTIcon iconName="text-align-left" className={btnIconClass} />
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export { Navbar };

import clsx from "clsx";
import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { useAuth } from "../../../modules/api/auth/core/Auth";
import { contactSupport } from "../../../modules/api/auth/core/_requests";

const initialValues = {
  first_name: "",
  email: "",
  message: "",
  subject: "",
};

const ContactSupportSchema = Yup.object().shape({
  name: Yup.string()
    .min(8, "Minimum 8 znaków")
    .max(100, "Maksymalnie 100 znaków"),
  email: Yup.string()
    .email("Nieprawidłowy format adresu email")
    .min(5, "Minimum 5 znaków")
    .max(100, "Maksymalnie 100 znaków")
    .required("Email jest wymagany"),
  subject: Yup.string()
    .min(5, "Minimum 5 znaków")
    .max(256, "Maksymalnie 256 znaków")
    .required("Temat jest wymagany"),
  message: Yup.string()
    .min(5, "Minimum 5 znaków")
    .required("Wiadomość jest wymagana"),
});

export function ContactSupport() {
  const [loading, setLoading] = useState(false);
  const [hasErrors, setHasErrors] = useState<boolean | undefined>(undefined);
  const [message, setMessage] = useState<string | null>(null);
  const navigate = useNavigate();
  const { currentUser } = useAuth();

  const formik = useFormik({
    initialValues,
    validationSchema: ContactSupportSchema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      const payload = {
        first_name: values.first_name,
        email: values.email,
        message: values.message,
        subject: values.subject,
        user_id: currentUser?.id,
      };

      contactSupport(payload)
        .then((response) => {
          if (response.status === 200) {
            setMessage("Wiadomość została wysłana. Dziękujemy.");
            setHasErrors(false);
          } else {
            setHasErrors(true);
          }
        })
        .catch((error) => {
          setHasErrors(true);
        })
        .finally(() => {
          setSubmitting(false);
        });
    },
  });

  useEffect(() => {
    if (currentUser) {
      formik.setFieldValue("first_name", currentUser.first_name);
      formik.setFieldValue("email", currentUser.email);
    }
  }, []);

  return (
    <form
      className="form w-100 fv-plugins-bootstrap5 fv-plugins-framework"
      onSubmit={formik.handleSubmit}
    >
      <div className="text-center mb-10">
        {/* begin::Title */}
        <h1 className="text-dark fw-bolder mb-3">Formularz kontaktowy</h1>
        {/* end::Title */}

        {/* begin::Link */}
        <div className="text-gray-500 fw-semibold fs-6">
          Skontaktuj się ze wsparciem technicznym aplikacji w celu zgłoszenia
          usterki.
        </div>
        {/* end::Link */}
      </div>

      {/* begin::Title */}
      {message && (
        <div className="fv-plugins-message-container my-5">
          <div className={`alert alert-success alert-success`}>
            <div className="alert-text font-weight-bold">{message}</div>
          </div>
        </div>
      )}

      {hasErrors === true && (
        <div className="mb-lg-15 alert alert-danger">
          <div className="alert-text font-weight-bold">
            Przepraszamy, wygląda na to, że wykryto błąd. Spróbuj ponownie.
          </div>
        </div>
      )}

      {formik.status && (
        <div className="mb-lg-15 alert alert-danger">
          <div className="alert-text font-weight-bold">{formik.status}</div>
        </div>
      )}
      {/* begin::Form group Password */}
      <div className="fv-row mb-8">
        <div className="mb-1">
          <label className="form-label fw-bolder text-dark fs-6">Imię</label>
          <div className="position-relative mb-3">
            <input
              type="text"
              placeholder="Imię"
              autoComplete="off"
              {...formik.getFieldProps("first_name")}
              className={clsx(
                "form-control bg-transparent",
                {
                  "is-invalid":
                    formik.touched.first_name && formik.errors.first_name,
                },
                {
                  "is-valid":
                    formik.touched.first_name && !formik.errors.first_name,
                }
              )}
            />
            {formik.touched.first_name && formik.errors.first_name && (
              <div className="fv-plugins-message-container">
                <div className="fv-help-block">
                  <span role="alert">{formik.errors.first_name}</span>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      {/* end::Form group */}

      {/* begin::Form group Confirm password */}
      <div className="fv-row mb-8">
        <div className="mb-1">
          <label className="form-label fw-bolder text-dark fs-6">
            Adres e-mail
          </label>
          <div className="position-relative mb-3">
            <input
              type="text"
              placeholder="Adres e-mail"
              autoComplete="off"
              {...formik.getFieldProps("email")}
              className={clsx(
                "form-control bg-transparent",
                {
                  "is-invalid": formik.touched.email && formik.errors.email,
                },
                {
                  "is-valid": formik.touched.email && !formik.errors.email,
                }
              )}
            />
            {formik.touched.email && formik.errors.email && (
              <div className="fv-plugins-message-container">
                <div className="fv-help-block">
                  <span role="alert">{formik.errors.email}</span>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="fv-row mb-8">
        <div className="mb-1">
          <label className="form-label fw-bolder text-dark fs-6">Temat</label>
          <div className="position-relative mb-3">
            <input
              type="text"
              placeholder="Temat"
              autoComplete="off"
              {...formik.getFieldProps("subject")}
              className={clsx(
                "form-control bg-transparent",
                {
                  "is-invalid": formik.touched.subject && formik.errors.subject,
                },
                {
                  "is-valid": formik.touched.subject && !formik.errors.subject,
                }
              )}
            />
            {formik.touched.subject && formik.errors.subject && (
              <div className="fv-plugins-message-container">
                <div className="fv-help-block">
                  <span role="alert">{formik.errors.subject}</span>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>

      {/* end::Form group */}
      <div className="fv-row mb-8">
        <div className="mb-1">
          <label className="form-label fw-bolder text-dark fs-6">
            Wiadomość
          </label>
          <div className="position-relative mb-3">
            <textarea
              placeholder="Wiadomość"
              autoComplete="off"
              {...formik.getFieldProps("message")}
              className={clsx(
                "form-control bg-transparent min-h-300px",
                {
                  "is-invalid": formik.touched.message && formik.errors.message,
                },
                {
                  "is-valid": formik.touched.message && !formik.errors.message,
                }
              )}
            />
            {formik.touched.message && formik.errors.message && (
              <div className="fv-plugins-message-container">
                <div className="fv-help-block">
                  <span role="alert">{formik.errors.message}</span>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      {/* begin::Form group */}
      <div className="d-flex flex-wrap justify-content-around pb-lg-0">
        <button
          type="submit"
          id="kt_sign_in_submit"
          className="btn btn-primary mx-5"
          disabled={formik.isSubmitting || !formik.isValid}
        >
          <span className="indicator-label">Wyślij wiadomość</span>
          {true && (
            <span className="indicator-progress">
              Proszę czekać...
              <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
            </span>
          )}
        </button>
        <Link to="/auth/login">
          <button
            type="button"
            id="kt_login_password_reset_form_cancel_button"
            className="btn btn-light  mx-5"
            disabled={formik.isSubmitting || !formik.isValid}
          >
            Anuluj
          </button>
        </Link>{" "}
      </div>
      {/* end::Form group */}
    </form>
  );
}

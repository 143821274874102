/* eslint-disable jsx-a11y/anchor-is-valid */
import clsx from "clsx";
import { FC, SetStateAction, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { UserBaseModel, useAuth } from "../../../../app/pages/auth";
import { KTIcon, defaultLogs, toAbsoluteUrl } from "../../../helpers";

import moment from "moment-timezone";

import { Dispatch } from "react";
import { OSHTrainingNotificationsModel } from "../../../../app/modules/api/osh-training/_models";
import { RecommendationNotificationModel } from "../../../../app/modules/api/recommendation/_models";
import { FireProtectionNotificationsModel } from "../../../../app/modules/api/technical-security-system/_models";
import { isUserOutsourcedWorker } from "../../../../app/pages/recommendation/helpers/permissions";
import { useSelectedUser } from "../../../../app/partials/modals/SelectedUserProvider";
import { NotificationListItem } from "./NotificationListItem";

const recommendationNotificationMessage: any = {
  recommendation_request: "dodał nowe zalecenie",
  realization_response: "odpowiedział na zalecenie",
  realization_completed: "zrealizował zalecenie i oczekuje na twoją akceptację",
};

const fireProtectionNotificationMessage: object = {
  service_upcoming: "dodał nowe zalecenie",
};
type NotificationActionType =
  | "service_upcoming"
  | "service_not_realized"
  | "service_needed"
  | "service_contract_end_date"
  | "document_renewal_upcoming"
  | "document_renewal_needed";

const renderNotification = (notification: FireProtectionNotificationsModel) => {
  let message: string;
  let redirectUrl: string;
  let notificationColor: string; // "danger" | "success" | "warning" | "info";

  switch (notification.action_type) {
    // services notifications
    case "service_upcoming":
    case "service_not_realized":
    case "service_needed":
      message = `Zbliża się przegląd dla urządzeń z grupy "${notification.service_log_type.name}"`;
      redirectUrl = notification.service_log_id
        ? `ppoz/serwisy/${notification.service_log_id}`
        : `ppoz/typy-serwisow/${notification.service_log_type.id}`;
      notificationColor = "info";
      // if (notification.action_type === "service_not_realized") {
      //   message = `Zaplanowany przegląd dla  "${notification.service_log_type.name}" w strefie "${notification.fire_zone.name}" nie został zrealizowany.`;
      //   notificationColor = "warning"
      // }
      if (notification.action_type === "service_needed") {
        message = `Wymagany przegląd dla urządzeń z grupy "${notification.service_log_type.name}"`;
        notificationColor = "danger";
      }
      break;

    // contracts notifications
    case "service_contract_end_date_upcoming":
    case "service_contract_end_date_expired":
      message = `Umowa na serwisowanie systemu "${notification.service_log_type?.name}" niedługo dobiegnie końca.`;
      redirectUrl = `ppoz/typy-serwisow/${notification.service_log_type?.id}`;
      notificationColor = "warning";
      if (notification.action_type === "service_contract_end_date_expired") {
        message = `Umowa na serwisowanie systemu "${notification.service_log_type?.name}" wygasła.`;
        notificationColor = "danger";
      }

      break;

    // documents notifications
    case "document_renewal_upcoming":
    case "document_renewal_needed":
      if (notification.action_type === "document_renewal_needed") {
        message = `Dokument ${notification.document?.name} jest przedawniony.`;
        notificationColor = "danger";
      } else {
        message = `Zbliża się termin odnowienia dokumentu "${notification.document?.name}".`;
        notificationColor = "warning";
      }

      redirectUrl =
        notification.document?.type === "authority_decisions"
          ? `ppoz/dokumenty/decyzje-organow-panstwowych`
          : `ppoz/dokumenty`;
      break;
    default:
      message = "Nieznane powiadomienie";
      redirectUrl = "";
      notificationColor = "success";
      break;
  }

  return { message, redirectUrl, notificationColor };
};

const renderOSHTrainingNotification = (
  notification: OSHTrainingNotificationsModel
) => {
  const message = notification.message;
  const redirectUrl = `szkolenia/pracownicy/${notification.worker.id}`;
  switch (notification.status) {
    case "expired": {
      return { message, redirectUrl, notificationColor: "danger" };
    }
    case "upcoming_expiry": {
      return { message, redirectUrl, notificationColor: "warning" };
    }
  }
};

function getTimeDifference(dateString: string) {
  // Set the locale to Polish
  moment.locale("pl");

  // Convert the provided date string to a moment object
  const providedDate = moment(dateString);

  // Current date
  const now = moment();

  // Calculate the difference in terms of various units
  const differenceInSeconds = now.diff(providedDate, "seconds");
  const differenceInMinutes = now.diff(providedDate, "minutes");
  const differenceInHours = now.diff(providedDate, "hours");
  const differenceInDays = now.diff(providedDate, "days");
  const differenceInMonths = now.diff(providedDate, "months");
  const differenceInYears = now.diff(providedDate, "years");

  if (differenceInSeconds < 60) {
    return `${differenceInSeconds} sekund temu`;
  } else if (differenceInMinutes < 60) {
    return `${differenceInMinutes} minut temu`;
  } else if (differenceInHours < 24) {
    return `${differenceInHours} godzin temu`;
  } else if (differenceInDays < 30) {
    return `${differenceInDays} dni temu`;
  } else if (differenceInMonths < 12) {
    return `${differenceInMonths} miesiąc temu`;
  } else {
    return `${differenceInYears} lat temu`;
  }
}

interface Props {
  newUserNotifications: UserBaseModel[] | null;
  newRecommendationNotifications: RecommendationNotificationModel[] | null;
  fireProtectionNotifications?: FireProtectionNotificationsModel[] | null;
  oshTrainingNotifications: OSHTrainingNotificationsModel[] | null;
  notificationsLoading: boolean;
  onUserGrantRoleModalChange: Dispatch<SetStateAction<UserBaseModel | null>>;
  isUserAdmin?: boolean | null;
}

const HeaderNotificationsMenu: FC<Props> = ({
  newUserNotifications,
  newRecommendationNotifications,
  fireProtectionNotifications,
  oshTrainingNotifications,
  notificationsLoading,
  onUserGrantRoleModalChange,
  isUserAdmin = false,
}) => {
  const { setSelectedUser } = useSelectedUser();
  //give an initial state so that the data won't be undefined at start
  const [bids, setBids] = useState<any[]>([0]);
  const navigate = useNavigate();
  const notificationCount: number | undefined =
    (newUserNotifications ? (newUserNotifications?.length as number) : 0) +
    (newRecommendationNotifications
      ? (newRecommendationNotifications?.length as number)
      : 0) +
    (fireProtectionNotifications
      ? (fireProtectionNotifications?.length as number)
      : 0) +
    (oshTrainingNotifications
      ? (oshTrainingNotifications?.length as number)
      : 0);
  const [showMenu, setShowMenu] = useState<boolean>(true);

  const hideNotificationsMenu = () => {
    const notificationMenu = document.querySelector("#notifications_menu");
    notificationMenu?.classList.remove("show");
  };

  const { auth } = useAuth();

  return (
    <>
      {showMenu && (
        <div
          id="notifications_menu"
          className="menu menu-sub menu-sub-dropdown menu-column w-350px w-lg-400px"
          data-kt-menu="true"
        >
          <div
            className="d-flex flex-column bgi-no-repeat rounded-top"
            style={{
              backgroundImage: `url('${toAbsoluteUrl(
                "/media/misc/menu-header-bg.jpg"
              )}')`,
            }}
          >
            <h3 className="text-white fw-bold px-9 mt-10 mb-6">
              Powiadomienia{" "}
              {notificationCount && (
                <span className="fs-7 opacity-75 ps-3">
                  {notificationCount} nowych
                </span>
              )}
            </h3>
            <ul className="nav nav-line-tabs nav-line-tabs-2x nav-stretch fw-bold px-9">
              {isUserAdmin && (
                <>
                  <NotificationListItem
                    className="active"
                    href="#kt_topbar_notifications_grant_role"
                    notifications={newUserNotifications}
                    title={"Uprawnienia"}
                  />{" "}
                </>
              )}
              {!isUserOutsourcedWorker(auth) && (
                <NotificationListItem
                  href="#kt_topbar_notifications_osh_training"
                  notifications={oshTrainingNotifications}
                  title={"Szkolenia"}
                />
              )}
              <NotificationListItem
                className={isUserAdmin ? "" : "active"}
                href="#kt_topbar_notifications_recommendation"
                notifications={newRecommendationNotifications}
                title={"Zalecenia"}
              />{" "}
              {!isUserOutsourcedWorker(auth) && (
                <NotificationListItem
                  href="#kt_topbar_notifications_fire_protection"
                  notifications={fireProtectionNotifications}
                  title={"PPOŻ"}
                />
              )}
            </ul>

            {/* <li className="nav-item">
            <a
              className="nav-link text-white opacity-75 opacity-state-100 pb-4 "
              data-bs-toggle="tab"
              href="#kt_topbar_notifications_accident"
            >
              Wypadki
            </a>
          </li> */}
          </div>

          <div className="tab-content">
            {isUserAdmin && (
              <div
                className="tab-pane fade show active"
                id="kt_topbar_notifications_grant_role"
                role="tabpanel"
              >
                <div className="scroll-y mh-325px my-5">
                  {newUserNotifications && newUserNotifications.length > 0 ? (
                    newUserNotifications.map((notification, index) => (
                      <div
                        key={`user-notification-${index}`}
                        className="d-flex flex-stack py-4 bg-hover-secondary rounded"
                        style={{
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          hideNotificationsMenu();
                          setSelectedUser(notification);
                        }}
                        data-bs-toggle="modal"
                        data-bs-target="#kt_modal_grant_role"
                      >
                        <div className="d-flex align-items-center px-5">
                          <div className="symbol symbol-35px me-4">
                            {/* Assuming the color and icon based on the user's ID for demonstration purposes */}
                            <span
                              className={clsx(
                                "symbol-label",
                                `bg-light-success`
                              )}
                            >
                              {/* You'll need to decide how to pick an icon for each user */}
                              <KTIcon
                                iconName="user"
                                className="fs-2 text-success"
                              />
                            </span>
                          </div>

                          <div className="mb-0 ">
                            <a
                              href="#"
                              className="fs-7 text-gray-800 text-hover-primary fw-bold"
                            >
                              {`${notification.first_name} ${notification.last_name} prosi o przyznanie uprawnień`}
                            </a>
                            <div className="text-gray-400 fs-7">
                              {notification.email}
                            </div>
                            {/* if is new: */}
                            <span
                              className="bullet bullet-dot bg-success position-absolute h-8px w-8px"
                              style={{ right: 10 }}
                            />

                            <div className="mt-2 text-gray-400 fs-8 fw-bold">
                              {getTimeDifference(
                                String(notification.date_joined)
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    ))
                  ) : (
                    <div className="py-10">
                      <h3 className="text-dark text-center fw-bolder">
                        {notificationsLoading ? "" : "Brak powiadomień"}
                      </h3>
                      <div className="text-center text-gray-600 fw-bold py-1">
                        {notificationsLoading && isUserAdmin
                          ? "Ładowanie..."
                          : `Brak powiadomień ${
                              isUserAdmin ? "w sprawie nadania uprawnień" : ""
                            } `}
                      </div>
                    </div>
                  )}
                </div>
              </div>
            )}
            <div
              className={`tab-pane fade show `}
              id="kt_topbar_notifications_osh_training"
              role="tabpanel"
            >
              <div className="scroll-y mh-325px my-5">
                {oshTrainingNotifications &&
                oshTrainingNotifications.length > 0 ? (
                  oshTrainingNotifications.map((notification, index) => {
                    const {
                      message,
                      redirectUrl,
                      notificationColor,
                    } = renderOSHTrainingNotification(notification);
                    return (
                      <div
                        key={`osh-training-notification-${index}`}
                        className="d-flex flex-stack py-4 bg-hover-secondary rounded"
                        style={{
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          navigate(redirectUrl);
                          hideNotificationsMenu();
                          setShowMenu(true);
                        }}
                      >
                        <div className="d-flex align-items-center px-5">
                          <div className="symbol symbol-35px me-4">
                            {/* Assuming the color and icon based on the user's ID for demonstration purposes */}
                            <span
                              className={clsx(
                                "symbol-label",
                                `bg-light-${notificationColor}`
                              )}
                            >
                              {/* You'll need to decide how to pick an icon for each user */}
                              <KTIcon
                                iconName="timer"
                                className={`fs-2 text-${notificationColor}`}
                              />
                            </span>
                          </div>

                          <div className="mb-0 ">
                            <a
                              // href={`/zalecenia-bhp/${notification.id}`}
                              className="fs-7 text-gray-800 text-hover-primary fw-bold"
                            >
                              {message}
                            </a>
                            <div className="text-gray-400 fs-7"></div>
                            {/* if is new: */}
                            <span
                              className="bullet bullet-dot bg-success position-absolute h-8px w-8px"
                              style={{ right: 10 }}
                            />

                            <div className="mt-2 text-gray-400 fs-8 fw-bold">
                              {/* {getTimeDifference(String(notification.created_at))} */}
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })
                ) : (
                  <div className="py-10">
                    <h3 className="text-dark text-center fw-bolder">
                      {notificationsLoading ? "" : "Brak powiadomień"}
                    </h3>
                    <div className="text-center text-gray-600 fw-bold py-1">
                      {notificationsLoading
                        ? "Ładowanie..."
                        : `Brak powiadomień ${
                            isUserAdmin ? "w sprawie szkoleń" : ""
                          } `}
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div
              className={`tab-pane fade show ${isUserAdmin ? "" : "active"}`}
              id="kt_topbar_notifications_recommendation"
              role="tabpanel"
            >
              <div className="scroll-y mh-325px my-5">
                {newRecommendationNotifications &&
                newRecommendationNotifications.length > 0 ? (
                  newRecommendationNotifications.map((notification, index) => (
                    <div
                      key={`recommendation-notification-${index}`}
                      className="d-flex flex-stack py-4 bg-hover-secondary rounded"
                      style={{
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        navigate(`/zalecenia-bhp/${notification.id}`);
                        hideNotificationsMenu();
                        setShowMenu(true);
                      }}
                    >
                      <div className="d-flex align-items-center px-5">
                        <div className="symbol symbol-35px me-4">
                          {/* Assuming the color and icon based on the user's ID for demonstration purposes */}
                          <span
                            className={clsx("symbol-label", `bg-light-success`)}
                          >
                            {/* You'll need to decide how to pick an icon for each user */}
                            <KTIcon
                              iconName="user"
                              className="fs-2 text-success"
                            />
                          </span>
                        </div>

                        <div className="mb-0 ">
                          <a
                            href={`/zalecenia-bhp/${notification.id}`}
                            className="fs-7 text-gray-800 text-hover-primary fw-bold"
                            onClick={() => setShowMenu(true)}
                          >
                            {notification.notification_type ===
                            "recommendation_request"
                              ? `${notification.created_by?.first_name} ${notification.created_by?.last_name}`
                              : `${notification.responsible_user?.first_name} ${notification.responsible_user?.last_name}`}{" "}
                            {notification.notification_type &&
                              recommendationNotificationMessage[
                                notification.notification_type
                              ]}
                          </a>
                          <div className="text-gray-400 fs-7">
                            {notification.notification_type ===
                            "recommendation_request"
                              ? notification.created_by?.email
                              : notification.responsible_user?.email}
                          </div>
                          {/* if is new: */}
                          <span
                            className="bullet bullet-dot bg-success position-absolute h-8px w-8px"
                            style={{ right: 10 }}
                          />

                          <div className="mt-2 text-gray-400 fs-8 fw-bold">
                            {getTimeDifference(
                              String(
                                notification.notification_type ===
                                  "realization_response"
                                  ? notification.created_at
                                  : notification.updated_at
                              )
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  ))
                ) : (
                  <div className="py-10">
                    <h3 className="text-dark text-center fw-bolder">
                      {notificationsLoading ? "" : "Brak powiadomień"}
                    </h3>
                    <div className="text-center text-gray-600 fw-bold py-1">
                      {notificationsLoading
                        ? "Ładowanie..."
                        : `Brak powiadomień ${
                            isUserAdmin ? "w sprawie nowych zaleceń" : ""
                          } `}
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div
              className={`tab-pane fade show `}
              id="kt_topbar_notifications_fire_protection"
              role="tabpanel"
            >
              <div className="scroll-y mh-325px my-5">
                {fireProtectionNotifications &&
                fireProtectionNotifications.length > 0 ? (
                  fireProtectionNotifications.map((notification, index) => {
                    const {
                      message,
                      redirectUrl,
                      notificationColor,
                    } = renderNotification(notification);
                    return (
                      <div
                        key={`fire-protection-notification-${index}`}
                        className="d-flex flex-stack py-4 bg-hover-secondary rounded"
                        style={{
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          navigate(redirectUrl);
                          hideNotificationsMenu();
                          setShowMenu(true);
                        }}
                      >
                        <div className="d-flex align-items-center px-5">
                          <div className="symbol symbol-35px me-4">
                            {/* Assuming the color and icon based on the user's ID for demonstration purposes */}
                            <span
                              className={clsx(
                                "symbol-label",
                                `bg-light-${notificationColor}`
                              )}
                            >
                              {/* You'll need to decide how to pick an icon for each user */}
                              <KTIcon
                                iconName="timer"
                                className={`fs-2 text-${notificationColor}`}
                              />
                            </span>
                          </div>

                          <div className="mb-0 ">
                            <a
                              // href={`/zalecenia-bhp/${notification.id}`}
                              className="fs-7 text-gray-800 text-hover-primary fw-bold"
                            >
                              {message}
                            </a>
                            <div className="text-gray-400 fs-7"></div>
                            {/* if is new: */}
                            <span
                              className="bullet bullet-dot bg-success position-absolute h-8px w-8px"
                              style={{ right: 10 }}
                            />

                            <div className="mt-2 text-gray-400 fs-8 fw-bold">
                              {/* {getTimeDifference(String(notification.created_at))} */}
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })
                ) : (
                  <div className="py-10">
                    <h3 className="text-dark text-center fw-bolder">
                      {notificationsLoading ? "" : "Brak powiadomień"}
                    </h3>
                    <div className="text-center text-gray-600 fw-bold py-1">
                      {notificationsLoading
                        ? "Ładowanie..."
                        : `Brak powiadomień ${
                            isUserAdmin
                              ? "w sprawie bezpieczeństwa pożarowego"
                              : ""
                          } `}
                    </div>
                  </div>
                )}
              </div>
            </div>

            {/* <div
          className="tab-pane fade show"
          id="kt_topbar_notifications_accident"
          role="tabpanel"
        >
          <div className="scroll-y mh-325px my-5 px-8">
            {grantRoleAlerts.length > 0 ? (
              grantRoleAlerts.map((alert, index) =>
                index > 0 ? null : (
                  <div key={`alert${index}`} className="d-flex flex-stack py-4">
                    <div className="d-flex align-items-center">
                      <div className="symbol symbol-35px me-4">
                        <span
                          className={clsx(
                            "symbol-label",
                            `bg-light-${alert.state}`
                          )}
                        >
                          {" "}
                          <KTIcon
                            iconName={alert.icon}
                            className={`fs-2 text-${alert.state}`}
                          />
                        </span>
                      </div>

                      <div className="mb-0 me-2">
                        <a
                          href="#"
                          className="fs-6 text-gray-800 text-hover-primary fw-bolder"
                        >
                          {alert.title}
                        </a>
                        <div className="text-gray-400 fs-7">
                          {alert.description}
                        </div>
                      </div>
                    </div>

                    <span className="badge badge-light fs-8">{alert.time}</span>
                  </div>
                )
              )
            ) : (
              <div className="py-10">
                <h3 className="text-dark text-center fw-bolder">
                  Brak powiadomień
                </h3>

                <div className="text-center text-gray-600 fw-bold py-1">
                  Brak powiadomień w sprawie nadania uprawnień.
                </div>
              </div>
            )}
          </div>
        </div> */}
            <div
              className="tab-pane fade"
              id="kt_topbar_notifications_3"
              role="tabpanel"
            >
              <div className="scroll-y mh-325px my-5 px-8">
                {defaultLogs.map((log, index) => (
                  <div key={`log${index}`} className="d-flex flex-stack py-4">
                    <div className="d-flex align-items-center me-2">
                      <span
                        className={clsx(
                          "w-70px badge",
                          `badge-light-${log.state}`,
                          "me-4"
                        )}
                      >
                        {log.code}
                      </span>

                      <a
                        href="#"
                        className="text-gray-800 text-hover-primary fw-bold"
                      >
                        {log.message}
                      </a>

                      <span className="badge badge-light fs-8">{log.time}</span>
                    </div>
                  </div>
                ))}
              </div>
              <div className="py-3 text-center border-top">
                <Link
                  to="/crafted/pages/profile"
                  className="btn btn-color-gray-600 btn-active-color-primary"
                >
                  View All <KTIcon iconName="arrow-right" className="fs-5" />
                </Link>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export { HeaderNotificationsMenu };

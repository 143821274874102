const QUERIES = {
  ACCIDENT_LIST: 'accidents-list',
  ACCIDENT_HISTORY: 'accidents-history',
  COMPANY_DETAILS: 'company-details',
  FACTORY_LIST: 'factories',
  REGISTRATION_CARD: 'registration-card',
  WORK_COMMUTE_CARD: 'work-commute-card',
  USER_LIST: 'users-list',
  WORKER_LIST: 'workers-list',
  SERVICE_LOG_TYPE_HISTORY: 'service-log-type-history',
  RECOMMENDATION_LIST: 'recommendations-list',
  RECOMMENDATION_DETAILS: 'recommendations-details',
  RECOMMENDATION_HISTORY: 'recommendations-history',
  FIRE_HAZARDOUS_WORK_LIST: 'fire-hazardous-work-list',
  FIRE_HAZARDOUS_WORK_HISTORY: 'fire-hazardous-work-history',
  FACTORY_AREA_LIST: 'factory-area-list',
  FACTORY_AREA_HISTORY: 'factory-area-history',
  FIREZONE_HISTORY: 'firezone-history',
  FIRE_ZONE_LIST: 'fire-zone-list',
  TECHNICAL_SECURITY_SYSTEM_TYPE_LIST: 'technical-security-system-type-list',
  TECHNICAL_SECURITY_SYSTEM_LIST: 'technical-security-system-list',
  FIRE_PROTECTION_DOCUMENT_LIST: 'fire-protection-document-list',
  FIRE_PROTECTION_DOCUMENT_LIST_NO_PAGINATION: 'fire-protection-document-list-no-pagination',
  CIRCUMSTANCES_AND_CAUSES_CARD: 'circumstances-and-causes-card',
  SERVICE_LOG_LIST: 'service-log-list',
  SERVICE_LOG_TYPE_LIST: 'service-log-type-list',
  SERVICE_LOG_HISTORY: 'service-log-history',
  OSH_TRAINING_LIST: 'osh-training-list',
  OSH_TRAINING_WORKER_LIST: 'osh-training-worker-list',
  OSH_TRAINING_HISTORY: 'osh-training-history',  
  OSH_INSTRUCTION_LIST: 'osh-instruction-list',
  OSH_INSTRUCTION_TEST_LIST: 'osh-instruction-test-list',
  FILE_MANAGER_LIST: 'file-manager-list',
}

export { QUERIES }


import { SxProps } from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { plPL } from "@mui/x-date-pickers/locales";
import moment from "moment-timezone";
import "moment/locale/pl";
import { useEffect, useState } from "react";
import { OUTPUT_DATE_FORMAT } from "../../_variables";

moment.tz.setDefault("Europe/Warsaw");

interface DatePickerProps {
  selectedDate: any | null;
  onDateChange: (date: any | null) => void;
  label: string;
  loading: boolean;
  className?: string;
  error: string | undefined;
  props?: Object;
  required?: boolean;
}

const DatePickerComponent: React.FC<DatePickerProps> = ({
  selectedDate,
  onDateChange,
  label,
  loading,
  error,
  className,
  props,
  required = true,
}) => {
  const [selectedDateState, setSelectedDateState] = useState<Date | null>(
    selectedDate
  );

  const [open, setOpen] = useState(false);

  useEffect(() => {
    setSelectedDateState(selectedDate);
  }, [selectedDate]);

  const handleDateChange = (date: any | null) => {
    onDateChange(date);
    setSelectedDateState(date);
  };
  
  const DatePickerStyles: SxProps = {
    "& .MuiInput-root": {
      borderRadius: "8px",
      backgroundColor: "red",
      "&:hover": {
        backgroundColor: "#e0e0e0",
      },
      "& .MuiInput-input": {
        fontWeight: 500,
      },
    },
    "& .MuiInput-underline:before": {
      borderBottom: "none",
    },
    "& .MuiInput-underline:hover:not(.Mui-disabled):before": {
      borderBottom: "none",
    },
    "& .MuiInput-underline:after": {
      borderBottom: "none",
    },
  };
  return (
    <>
      <label
        className={`form-label fw-bold ${required && "required"}`}
      >
        {label}
      </label>
      <LocalizationProvider
        dateAdapter={AdapterMoment}
        dateLibInstance={moment}
        localeText={
          plPL.components.MuiLocalizationProvider.defaultProps.localeText
        }
          adapterLocale={"pl"}
      >
        <DatePicker
          className={`custom-datetime-picker ${className}`}
          value={moment.tz(selectedDateState, "Europe/Warsaw")}
          onChange={handleDateChange}
          format={OUTPUT_DATE_FORMAT}
          open={open}
          onOpen={() => setOpen(true)}
          onClose={() => setOpen(false)}
          slotProps={{
            textField: {
              onClick: () => setOpen(true),
              style: {
                cursor: "pointer",
              },
            },
          }}
          sx={DatePickerStyles} // Apply custom styles here
          {...props}
        />
      </LocalizationProvider>
    </>
  );
};

export { DatePickerComponent };

import React from "react";

type Props = {
  titleContent: string;
  titleColor: string;
  progressBarColor: string;
  accidentCount: number;
  totalAccidentCount?: number;
  loading?: boolean;
};

export const ProgressBarItem: React.FC<Props> = ({
  titleContent,
  titleColor = "text-white",
  progressBarColor = "bg-white",
  accidentCount = 0,
  totalAccidentCount = 45,
  loading = false
}) => {
  return (
    <div className="w-100 py-3">
      <div className="d-flex w-100 justify-content-space-between align-items-center">
        <span className={`${titleColor} text-align-left me-auto fs-2 fw-bold`}>
          {titleContent}{" "}
        </span>
        <span
          className={`text-white text-align-left fw-bold`}
          style={{
            fontSize: "3rem",
          }}
        >
          {" "}
          {!loading ? accidentCount : <>
            <span className="spinner spinner-border fw-bolder"> </span>
          </> }
        </span>
      </div>

      <div className={`h-8px w-100 bg-white bg-opacity-50 rounded mt-2`}>
        <div
          className={`${progressBarColor} rounded h-8px`}
          role="progressbar"
          style={{ width: `${(accidentCount / (totalAccidentCount || accidentCount)) * 100}%` }}
          aria-valuenow={80}
          aria-valuemin={0}
          aria-valuemax={100}
        ></div>
      </div>
    </div>
  );
};

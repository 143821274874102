import { FC, createContext, useContext, useEffect, useState } from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import {
  QueryRequestContextProps,
  QueryState,
  WithChildren,
  initialQueryRequest,
} from "../../../_metronic/helpers";

const QueryRequestContext = createContext<QueryRequestContextProps>(
  initialQueryRequest
);

const QueryRequestProvider: FC<WithChildren> = ({ children }) => {
  const [state, setState] = useState<QueryState>(initialQueryRequest.state);
  const navigate = useNavigate();
  const location = useLocation();
  const [searchParams] = useSearchParams();

  const updateState = (updates: Partial<QueryState>) => {
    setState((prevState) => ({
      ...prevState,
      ...updates,
    }));
  };
  const handleFileManagerQueryStateChange = () => {
    // Check if location pathname contains "menadzer-plikow". If not, return;
    if (!location.pathname.includes("menadzer-plikow")) return;

    // get all characters after "menadzer-plikow" in the pathname
    // get all path after the menadzer-plikow"
    const path = searchParams.get("path") || "";

    if (path !== state.path) {
      setState((prevState) => ({
        ...prevState,
        path: path !== "" ? path : undefined,
        page: 1, // Reset to first page whenever path changes
      }));
    }
    updateState({ path });
  };

  useEffect(() => {
    handleFileManagerQueryStateChange();
  }, [searchParams]);

  return (
    <QueryRequestContext.Provider value={{ state, updateState }}>
      {children}
    </QueryRequestContext.Provider>
  );
};

const useQueryRequest = () => useContext(QueryRequestContext);
export { QueryRequestProvider, useQueryRequest };

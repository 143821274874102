import React, { FC, createContext, useCallback, useContext, useState } from 'react';
import { Toast } from 'react-bootstrap';

interface ToastContextType {
  setToastMessage: (message: string) => void;
  setShowToast: (show: boolean) => void;
  setDelay: (delay: number) => void;
}

const ToastContext = createContext<ToastContextType | undefined>(undefined);

export const useToastMessage = (): ToastContextType => {
  const context = useContext(ToastContext);
  if (!context) {
    throw new Error('useToastMessage must be used within a ToastProvider');
  }
  return context;
};


interface ToastProviderProps {
  children: React.ReactNode;
}

const ToastMessageProvider: FC<ToastProviderProps> = ({ children }) => {
  const [message, setMessage] = useState('');
  const [showToast, setShowToast] = useState(false);
  const [delay, setDelay] = useState(6500);

  const setToastMessage = useCallback((newMessage: string) => {
    setMessage(newMessage);
    setShowToast(true);
  }, []);

  const handleClose = useCallback(() => {
    setShowToast(false);
  }, []);

  const contextValue = {
    setToastMessage,
    setShowToast,
    setDelay,
  };

  return (
    <ToastContext.Provider value={contextValue}>
      {children}
      <Toast
        onClose={handleClose}
        delay={delay}
        show={showToast}
        autohide
        style={{
          position: 'fixed',
          top: 20,
          right: 20,
          zIndex: 9999,
        }}
      >
        <Toast.Header>
          <strong className="text-primary mb-1 fs-6 fw-semibold text-center me-auto">
            Powiadomienie
          </strong>
        </Toast.Header>
        <Toast.Body className="fs-6">{message}</Toast.Body>
      </Toast>
    </ToastContext.Provider>
  );
};

export { ToastMessageProvider };

import clsx from "clsx";
import { FormikProps } from "formik";
import { FC } from "react";
import { getErrorMessage } from "../form/components/select/RenderCreatableSelectComponent";
import { DatePickerComponent } from "./DatePickerComponent";

interface Props {
  formik: FormikProps<any>;
  label: string;
  props?: object;
  fieldKey: string;
  isRequired?: boolean;
  handleDateChange: (date: any | null) => void;
}

const RenderDatePickerComponent: FC<Props> = ({
  formik,
  label,
  props,
  fieldKey,
  isRequired,
  handleDateChange,
}) => {
  return (
    <>
      <DatePickerComponent
        selectedDate={
          formik.values[fieldKey]
            ? new Date(formik.values[fieldKey])
            : null
        }
        onDateChange={handleDateChange}
        label={label}
        error={undefined}
        loading={false}
        className={clsx("form-control form-control-solid mb-3 mb-lg-0", {
          "is-invalid":
            formik.touched[fieldKey] && formik.errors[fieldKey],
        })}
        required={isRequired}
      />
      {formik.touched[fieldKey] && formik.errors[fieldKey] && (
        <div className="text-danger position-absolute">
          {getErrorMessage(formik.errors[fieldKey])}
        </div>
      )}
    </>
  );
};


export { RenderDatePickerComponent };

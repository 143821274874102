import axios, { AxiosResponse } from "axios";
import { stringifyRequestQuery } from "../../../../_metronic/helpers";
import { API_URL } from "../_variables";
import { WorkerModel } from "./_models";

export const WORKERS_URL = `${API_URL}/workers`;
export const WORKER_GROUPS_URL = `${API_URL}/worker-groups/`;
export const OUTSOURCED_WORKERS_URL = `${API_URL}/outsourced-workers/`;
export function getWorkers(
  queryParams?: any,
) {

  return axios
    .get(`${WORKERS_URL}?${queryParams}`)
    .then((response) => response.data);
}

/**
 * workers GET request with disabled pagination
 */
export function getWorkersDisabledPagination(queryParams?: any) {
  queryParams.pagination_enabled = false;
  queryParams = stringifyRequestQuery(queryParams);

  return axios
    .get(`${WORKERS_URL}?${queryParams}`)
    .then((response: AxiosResponse<WorkerModel[]>) => response);
}

export function getWorkersBySearchTerm(queryParams?: any) {
  queryParams = stringifyRequestQuery(queryParams);

  return axios
    .get(`${API_URL}/workers?pagination_enabled=false&list_search=true&${queryParams}`)
    .then((response: AxiosResponse<WorkerModel[]>) => response);
}

export function getWorkerById(workerId: number) {
  return axios
    .get(`${WORKERS_URL}/${workerId}`)
    .then((response: AxiosResponse<WorkerModel>) => response.data);
}

export function deleteWorker(workerId: number) {
  return axios
    .delete(`${WORKERS_URL}/${workerId}`)
    .then((response: AxiosResponse<WorkerModel>) => response.data);
}

export function createWorker(worker: any) {
  return axios.post(`${WORKERS_URL}/`, worker);
}

export function updateWorker(workerId: number, worker: any) {
  return axios.patch(`${WORKERS_URL}/${workerId}/`, worker);
}

export function getWorkerGroups() {
  return axios.get(`${WORKER_GROUPS_URL}`);
}

export function getOutsourcedWorkers() {
  return axios.get(`${OUTSOURCED_WORKERS_URL}`);
}

export function getOutsourcedWorkersDisabledPagination() {
  return axios.get(`${OUTSOURCED_WORKERS_URL}?pagination_enabled=false`);
}

export function createOutsourcedWorker(firstName: string, lastName: string) {
  return axios
    .post(`${OUTSOURCED_WORKERS_URL}`, {
      first_name: firstName,
      last_name: lastName,
    })
    .then((response) => response.data);
}

import { Suspense } from "react";
import { Outlet } from "react-router-dom";
import { I18nProvider } from "../_metronic/i18n/i18nProvider";
import { MasterInit } from "../_metronic/layout/MasterInit";
import { LayoutProvider, LayoutSplashScreen } from "../_metronic/layout/core";
import { ToastMessageProvider } from "./generic/components/toast/ToastMessageProvider";
import { AuthInit } from "./pages/auth";

const App = () => {
  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <I18nProvider>
        <LayoutProvider>
          <AuthInit>
            <ToastMessageProvider>
              <Outlet />
              <MasterInit />
            </ToastMessageProvider>
          </AuthInit>
        </LayoutProvider>
      </I18nProvider>
    </Suspense>
  );
};

export { App };

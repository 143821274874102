import { createContext, useContext, useState } from "react";
import { QueryObserverResult, useQuery } from "react-query";
import { useParams } from "react-router-dom";
import { RequestError } from "../../../_metronic/helpers";
import { onRequestError } from "../../modules/errors/helpers";
import { useToastMessage } from "../components/toast/ToastMessageProvider";
import { TOAST_MESSAGES } from "../components/toast/ToastMessages";

interface DataProviderProps {
  children: React.ReactNode;
}

interface DataContextType<Model> {
  dataDetail: Model | null;
  setDataDetail: React.Dispatch<React.SetStateAction<Model | null>>;
  showCreateEditModal: boolean;
  setShowCreateEditModal: React.Dispatch<React.SetStateAction<boolean>>;
  refetchDetail: <TPageData>(
    options?: any
  ) => Promise<QueryObserverResult<Model | undefined, unknown>>;
  setRecordId: React.Dispatch<React.SetStateAction<number>>;
  recordId: number;
}

function createDataProvider<Model>(
  contextName: string,
  requestByIdFunction: (id: number) => Promise<Model>,
  idKeyName: string
) {
  const DataContext = createContext<DataContextType<Model> | undefined>(
    undefined
  );

  const useData = () => {
    const context = useContext(DataContext);
    if (!context) {
      throw new Error(`useData must be used within a ${contextName}Provider`);
    }
    return context;
  };

  const DataProvider: React.FC<DataProviderProps> = ({ children }) => {
    const [dataDetail, setDataDetail] = useState<Model | null>(null);
    const [showCreateEditModal, setShowCreateEditModal] = useState<boolean>(
      false
    );
    const { setToastMessage } = useToastMessage();
    const [customRecordId, setCustomRecordId] = useState<number>(0);
    // Adjusted the type definition here:
    const id = useParams<Record<string, string>>();

    const { data, refetch: refetchDetail } = useQuery(
      [contextName, id[idKeyName]],
      ({ queryKey }) => {
        const [_, _id] = queryKey;
        let recordId = customRecordId || _id;
        if (recordId) return requestByIdFunction(Number(recordId));
        return undefined; // Explicitly return undefined if no recordId is found
      },
      {
        onSuccess: (data) => {
          // Check if data is not undefined before setting the state:
          if (data !== undefined) {
            setDataDetail(data);
          } else {
            if (id[idKeyName]) setDataDetail(null);
          }
        },
        refetchOnWindowFocus: false,
        onError: (error: RequestError) => {
          onRequestError(error, (TOAST_MESSAGES as any)[contextName], setToastMessage);
        },
      }
    );

    return (
      <DataContext.Provider
        value={{
          dataDetail,
          setDataDetail,
          showCreateEditModal,
          setShowCreateEditModal,
          refetchDetail,
          setRecordId: setCustomRecordId,
          recordId: customRecordId,
        }}
      >
        {children}
      </DataContext.Provider>
    );
  };

  return { DataProvider, useData };
}

export { createDataProvider };

import { FormikProps } from "formik";
import { FC } from "react";
import { WorkerBaseModel } from "../../../../../modules/api/worker/_models";
import { CreatableSelectComponent } from "./CreatableSelect";
import { SelectOption } from "./ListSearchComponent";


// Import the ListSelectComponent from its location

interface MyFormValues {
  [key: string]: any;
}

type Props = {
  selectedItem?: SelectOption | null;
  fieldKey: string;
  fetchFunction: (params?: any) => any;
  changeHandler: (selectedOptions: SelectOption[] ) => void; // Change this line
  placeholderText: string;
  labelText: string;
  formik: FormikProps<any>;
  formatItem: (item: any) => SelectOption;
  required?: boolean;
};

export const getErrorMessage = (error: any) => {
  if (typeof error === "string") {
    return error;
  }
  if (Array.isArray(error)) {
    return error[0];
  }
  if (typeof error === "object") {
    return Object.values(error)[0] as string;
  }
  return undefined;
};

const RenderCreatableSelectComponent: FC<Props> = ({
  selectedItem,
  fieldKey,
  fetchFunction,
  changeHandler,
  placeholderText,
  labelText,
  formik,
  formatItem,
  required = true,
}) => {
  return (
    <>
      <CreatableSelectComponent
      isMulti={true} 
        selectedItems={formik.values[fieldKey].map((item : WorkerBaseModel)  => {
          return {
            value: item.id,
            label: item.first_name + " " + item.last_name
          };
        })}
        onItemsChange={changeHandler as any}
        fetchItems={fetchFunction}
        formatOption={(item: any) => {
          const formattedItem = formatItem(item);
          
          return {
            value: formattedItem.value,
            label: formattedItem.label,
          };
        }}
        placeholder={placeholderText}
        noOptionsMessage="Brak wyników"
        label={labelText}
        required={required}
        formik={formik}
        fieldName={fieldKey}
      />
      {formik.errors[fieldKey] ? (
        <div className="text-danger">
          {getErrorMessage(formik.errors[fieldKey])}
        </div>
      ) : null}
    </>
  );
};

export default RenderCreatableSelectComponent;

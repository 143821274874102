import React from 'react';

interface RequestLoaderProps {
  allowFixedCenter?: boolean;
}

const RequestLoader: React.FC<RequestLoaderProps> = ({
  allowFixedCenter = false,
}) => {
  /**
   * This is a Loader Component **
   * It is used to show a loading message until the data is being fetched from the API
   * allowFixedCenter prop enables to show the loader on the absolute center of the screen.
   ***/
  const baseClassNames = "border rounded box-shadow mx-auto my-0 w-auto py-4 px-5 bg-white text-align-center";
  const absoluteClassNames = " position-sm-absolute position-absolute top-50 start-50 translate-middle";
  const fixedClassNames = " position-fixed top-50 start-50 translate-middle";
  const classNames = `${baseClassNames} ${allowFixedCenter ? fixedClassNames : absoluteClassNames}`;

  return (
    <div 
      style={{
        borderRadius: "0.475rem",
        boxShadow: "0 0 50px 0 rgb(82 63 105 / 15%)",
      }} 
      className={classNames}
    >
      Proszę czekać...
    </div>
  );
};

export { RequestLoader };

export function getCurrentUrl(pathname: string) {
  return pathname.split(/[?#]/)[0];
}

export function checkIsActive(pathname: string, url: string) {
  const current = getCurrentUrl(pathname);
  if (!current || !url) {
    return false;
  }

  if (current === url) {
    return true;
  }


  const currentSegments = current.split('/');
  const urlSegments = url.split('/');
  if (currentSegments.length === urlSegments.length) {
    // Check if all segments except the last one are the same
    const lastIndex = currentSegments.length - 1;
    for (let i = 0; i < lastIndex; i++) {
      if (currentSegments[i] !== urlSegments[i]) {
        return false;
      }
    }
    // Check if the last segments match
    if (currentSegments[lastIndex] === urlSegments[lastIndex]) {
      return true;
    }
  }

  if (currentSegments[2] && urlSegments[2] && currentSegments[2] == urlSegments[2])
    return true;
  
  return false;
}

export function checkIsMainMenuItemActive(pathname: string, url: string) {
  const current = getCurrentUrl(pathname);
  if (!current || !url) {
    return false;
  }

  if (current === url) {
    return true;
  }
  const currentFirstSegment = current.split("/")[1];
  const urlFirstSegment = url.split("/")[1];
  return currentFirstSegment === urlFirstSegment;
}

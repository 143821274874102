import axios, { AxiosResponse } from "axios";
import { API_URL } from "../../../_variables";
import { fetchWithSessionStorage } from "../../../helpers";
import {
  AccidentCircumstancesAndCausesCardCreateEditModel,
  AccidentCircumstancesAndCausesCardDetailModel,
  AccidentLocationTypeModel,
} from "../../_models";
import { ACCIDENTS_URL } from "../../_requests";
import {
  AccidentProtocolsStatusModel,
  RegistrationCardDetailModel,
} from "./_models";

export const ACCIDENT_REGISTRATION_FORM_URL_SUFIX = `accident-registration-cards`;
export const ACCIDENT_WORK_COMMUTE_CARD_FORM_URL_SUFIX = `work-commute-cards`;
export const ACCIDENT_LOCATION_TYPES = `accident-location-types`;
export const ACCIDENT_WORK_COMMUTE_CARD_URL = (accidentId: number) =>
  `${ACCIDENTS_URL}/${accidentId}/${ACCIDENT_WORK_COMMUTE_CARD_FORM_URL_SUFIX}/`;
export const ACCIDENT_CIRCUMSTANCES_AND_CAUSES_CARD_URL_SUFFIX = `circumstances-and-causes-cards`;

export function getAccidentRegistrationCard(accidentId: number) {
  const url = `${ACCIDENTS_URL}/${accidentId}/${ACCIDENT_REGISTRATION_FORM_URL_SUFIX}/`;
  return axios
    .get(url)
    .then(
      (response: AxiosResponse<RegistrationCardDetailModel>) => response.data
    );
}

export function getAccidentLocationTypeById(locationTypeId: number) {
  const url = `${API_URL}/accident-location-types/${locationTypeId}/`;
  return axios
    .get(url)
    .then(
      (response: AxiosResponse<AccidentLocationTypeModel>) => response.data
    );
}

export function getProtocolsInfo(accidentId: number) {
  const url = `${ACCIDENTS_URL}/${accidentId}/protocols-status`;
  return axios
    .get(url)
    .then(
      (response: AxiosResponse<AccidentProtocolsStatusModel>) => response.data
    );
}

export function getAccidentWorkCommuteCard(accidentId: number) {
  return axios
    .get(ACCIDENT_WORK_COMMUTE_CARD_URL(accidentId))
    .then((response) => response.data);
}

export function createAccidentWorkCommuteCard(
  accidentId: number,
  payload: any
) {
  return axios.post(ACCIDENT_WORK_COMMUTE_CARD_URL(accidentId), payload);
}
export function getAccidentWorkCommuteCardCount(accidentId: number) {
  return axios
    .get(ACCIDENT_WORK_COMMUTE_CARD_URL(accidentId))
    .then((response) => response.data);
}

export function updateAccidentWorkCommuteCard(
  accidentId: number,
  payload: any,
  formId: number
) {
  return axios.patch(
    `${ACCIDENT_WORK_COMMUTE_CARD_URL(accidentId)}${formId}/`,
    payload
  );
}

export function getIdentificationTypeChoices() {
  return axios.get(`${API_URL}/identification-types/`).catch((error) => {
    return {};
  });
}

export function getAccidentWorkCommuteLocationTypeChoices() {
  return fetchWithSessionStorage(
    `${API_URL}/accident-commune-location-choices`,
    "accident-commune-location-choices"
  );
}

export function getAccidentWithMetaData(accidentId: number) {
  return axios
    .get(`${API_URL}/accidents/${accidentId}?accident_protocol_serializer=true`)
    .then((res) => res.data);
}

export function getAccidentCircumstancesAndCausesCard(accidentId: number) {
  const url = `${ACCIDENTS_URL}/${accidentId}/${ACCIDENT_CIRCUMSTANCES_AND_CAUSES_CARD_URL_SUFFIX}/`;
  return axios
    .get(url)
    .then(
      (
        response: AxiosResponse<AccidentCircumstancesAndCausesCardDetailModel>
      ) => response.data
    );
}

export function createAccidentCircumstancesAndCausesCard(
  accidentId: number,
  payload: AccidentCircumstancesAndCausesCardCreateEditModel
) {
  return axios.post(
    `${ACCIDENTS_URL}/${accidentId}/${ACCIDENT_CIRCUMSTANCES_AND_CAUSES_CARD_URL_SUFFIX}/`,
    payload
  );
}

export function updateAccidentCircumstancesAndCausesCard(
  accidentId: number,
  cardId: number,
  payload: AccidentCircumstancesAndCausesCardCreateEditModel
) {
  return axios.patch(
    `${ACCIDENTS_URL}/${accidentId}/${ACCIDENT_CIRCUMSTANCES_AND_CAUSES_CARD_URL_SUFFIX}/${cardId}/`,
    payload
  );
}

import { FormikProps } from "formik";
import { RequestError } from "../../../_metronic/helpers";
import { TOAST_MESSAGES } from "../../generic/components/toast/ToastMessages";

export const onRequestError = (
  error: RequestError,
  toastMessageType: {
    not_found?: string;
    forbidden?: string;
  },
  setToastMessage: any,
  setGlobalError?: any,
  formik?: FormikProps<any>,
  setGlobalErrorOn404: boolean = false
) => {
  if (!error?.response) {
    console.error("Error is not RequestError type.");
    return;
  }

  // make a switch case here to handle different error codes
  switch (error.response.status) {
    case 404:
      const errorMessage: string =
        toastMessageType?.not_found || "Nie znaleziono zasobu.";
      setGlobalErrorOn404 && setGlobalError && setGlobalError(errorMessage);
      setToastMessage && setToastMessage(errorMessage);
      break;
    case 400:
      if (error.response.data?.detail) {
        // non-related field error
        setGlobalError && setGlobalError(error.response.data?.detail);
      } else {
        // related field error
        if (formik){
          formik.setErrors(error.response.data);
          setToastMessage("Wystąpił błąd. Spróbuj ponownie.");
        }
        !formik && setToastMessage("Wystąpił błąd. Spróbuj ponownie.");
      }
      break;
    case 403: 
      if (error.response.data?.detail) {
        setToastMessage(toastMessageType.forbidden || TOAST_MESSAGES.forbidden);
        // non-related field error
        setGlobalError && setGlobalError(error.response.data?.detail);
      } else {
        // related field error
        formik && formik.setErrors(error.response.data);
        !formik && setToastMessage("Wystąpił błąd. Spróbuj ponownie.");
      }
      break;
    case 409:
      setToastMessage(TOAST_MESSAGES.delete_conflict);
      break;
    case 500:
      setGlobalError && setGlobalError(TOAST_MESSAGES.error.server_error);
      setToastMessage(TOAST_MESSAGES.error.server_error);
      break;
    default:
      break;
  }
};

import clsx from "clsx";
import { FormikProps } from "formik";
import { FC, useEffect, useRef } from "react";
import { StepperComponent } from "../../../../_metronic/assets/ts/components";
import { KTIcon, WithChildren } from "../../../../_metronic/helpers";
import { FormStepModel } from "./_models";

interface Props {
  stepsInfo: FormStepModel[];
  onSubmit: () => void;
  isEdit: boolean;
  displaySteps?: boolean;
  gotoPageIndex?: number;
  formik: FormikProps<any>;
}
const FormStepper: FC<Props & WithChildren> = ({
  stepsInfo,
  onSubmit,
  isEdit,
  formik,
  displaySteps = true,
  gotoPageIndex,
  children,
}) => {
  const stepperRef = useRef<HTMLDivElement | null>(null);
  const stepper = useRef<StepperComponent | null>(null);
  const stepCount = stepsInfo?.length;
  const loadStepper = () => {
    stepper.current = StepperComponent.createInsance(
      stepperRef.current as HTMLDivElement
    );
  };

  // todo: change enforcing page index to a more optimised solution
  useEffect(() => {
    if (gotoPageIndex) {
      goToStep(gotoPageIndex);
    }

  }, [gotoPageIndex]);
  useEffect(() => {
    loadStepper();
  });

  const prevStep = () => {
    if (!stepper.current) {
      return;
    }

    stepper.current.goPrev();
  };

  const nextStep = () => {
    if (!stepper.current) {
      return;
    }

    if (stepper.current.getCurrentStepIndex() === 1) {
      if (!true) {
        return;
      }
    }

    if (stepper.current.getCurrentStepIndex() === stepCount) {
      if (!true) {
        return;
      }
    }

    stepper.current.goNext();
  };

  const goToStep = (index: number) => {
    stepper.current?.goto(index);
  };
  return (
    <>
      <div
        ref={stepperRef}
        className="stepper stepper-pills stepper-column d-flex flex-column flex-xl-row flex-row-fluid"
        id="kt_modal_create_app_stepper"
      >
        {/* begin::Aside*/}
        <div
          className={`d-flex justify-content-center justify-content-xl-start flex-row-auto w-100 w-xl-300px ${
            displaySteps ? "" : "d-none"
          }`}
        >
          {/* begin::Nav*/}
          <div className="stepper-nav ps-lg-10">
            {/* begin::Step*/}
            {stepsInfo?.map((value: any, index: number) => {
              const stepHasError = value.values?.some(
                (field: string | string[]) => {
                  if (Array.isArray(field)) {
                    // When field is an array, check if the nested error is not undefined
                    const [parentField, childField] = field;
                    if (!childField) {
                      // When childField is undefined, check if the parentField error is not undefined
                      return formik.errors[parentField] !== undefined;
                    }
                    if (
                      formik.errors[parentField] &&
                      (formik.errors[parentField] as any)[childField] !==
                        undefined
                    )
                      console.log(
                        (formik.errors[parentField] as any)[childField],
                        field
                      );
                    return (
                      formik.errors[parentField] &&
                      (formik.errors[parentField] as any)[childField] !==
                        undefined
                    );
                  } else {
                    // When field is a string, directly check if the error is not undefined
                    // if(formik.errors[field] !== undefined) {
                    // console.log(formik.errors[field], field)
                    // }
                    return formik.errors[field] !== undefined;
                  }
                }
              );

              return (
                <div
                  className={clsx("stepper-item", {
                    current: index === 0,
                  })}
                  data-kt-stepper-element="nav"
                >
                  {/* begin::Wrapper*/}
                  <div
                    className="stepper-wrapper cursor-pointer"
                    onClick={() => goToStep(index + 1)}
                  >
                    {/* begin::Icon*/}
                    <div className="stepper-icon w-40px h-40px">
                      <i className="stepper-check fas fa-check"></i>
                      <span className="stepper-number">{index + 1}</span>
                    </div>
                    {/* end::Icon*/}

                    {/* begin::Label*/}
                    <div className="stepper-label">
                      <h3
                        className={`stepper-title ${
                          stepHasError ? "text-danger" : ""
                        }`}
                      >
                        {value.stepperTitle}
                      </h3>
                      <div className="stepper-desc">{value.stepperDesc}</div>
                    </div>
                    {/* end::Label*/}
                  </div>
                  {/* end::Wrapper*/}
                  {index !== stepsInfo?.length - 1 ? (
                    <div className="stepper-line h-40px"></div>
                  ) : (
                    <div className="my-5"></div>
                  )}
                </div>
              );
            })}
            {/* end::Step*/}
          </div>
          {/* end::Nav*/}
        </div>
        {/* begin::Aside*/}

        {/*begin::Content */}
        <div className="flex-row-fluid py-lg-5 px-lg-15">
          {children}
          {/*begin::Actions */}
          <div className="d-flex flex-stack pt-10">
            <div className="me-2">
              <button
                type="button"
                className="btn btn-lg btn-light-primary me-3"
                data-kt-stepper-action="previous"
                onClick={prevStep}
              >
                <KTIcon iconName="arrow-left" className="fs-3 me-1" />
                Wróć
              </button>
            </div>
            <div>
              <button
                type="submit"
                className="btn btn-lg btn-primary"
                data-kt-stepper-action="submit"
                onSubmit={onSubmit}
              >
                Zapisz {isEdit && "zmiany"}
                <KTIcon iconName="arrow-right" className="fs-3 ms-2 me-0" />
              </button>

              <button
                type="button"
                className="btn btn-lg btn-primary"
                data-kt-stepper-action="next"
                onClick={nextStep}
              >
                Dalej{" "}
                <KTIcon iconName="arrow-right" className="fs-3 ms-1 me-0" />
              </button>
            </div>
          </div>
          {/*end::Actions */}
        </div>
      </div>
    </>
  );
};

export { FormStepper };

// ListSelectComponent.tsx
import { useEffect, useRef, useState } from "react";
import { useQuery } from "react-query";
import Select from "react-select";
import {
  reactSelectClassConfig,
  reactSelectCustomStyles,
} from "./_customStyles";

interface GroupedOptions {
  label: string;
  options: SelectOption[];
}

interface SelectOption {
  value: string | number;
  label: string;
  additionalFields?: any;
}

interface ListSelectChoiceComponentProps {
  selectedItem: SelectOption | any;
  onItemChange: (selectedOption: SelectOption | null) => void;
  fetchItems?: (params: any) => Promise<any>; // Make fetchItems optional
  formatOption: (item: any) => SelectOption;
  placeholder: string;
  noOptionsMessage: string;
  label: string;
  disabled?: boolean;
  required?: boolean;
  optionsGrouped?: boolean;
  options?: SelectOption[]; // Add options prop here
  enableFetchFunction?: boolean;
}

const ListSelectComponent: React.FC<ListSelectChoiceComponentProps> = ({
  selectedItem,
  onItemChange,
  fetchItems,
  formatOption,
  placeholder,
  noOptionsMessage,
  label,
  disabled = false,
  required = true,
  optionsGrouped = false,
  options = [], // Default to an empty array
  enableFetchFunction = true,
}) => {
  const [items, setItems] = useState<any[]>([]);
  const [selectedItemState, setSelectedItemState] = useState<SelectOption | null>(selectedItem);
  const selectRef = useRef<any>(null);

  // Use useQuery only if fetchItems is provided
  const { data, isLoading, isError, error } = useQuery(
    `fetchItems${label}`,
    () => fetchItems ? fetchItems({ pagination_enabled: false }) : Promise.resolve({ data: options }),
    {
      refetchOnWindowFocus: false,
      retry: 3, // Retry fetching up to 3 times
      onSuccess: (response) => {
        const data = response?.data || response;
        setItems(data); // Assuming the response structure has a `data` property
      },
      onError: (error) => {
        console.error(error);
      },
      enabled: !!fetchItems && enableFetchFunction // Only run query if fetchItems is provided
    }
  );

  useEffect(() => {
    setSelectedItemState(selectedItem);
  }, [selectedItem]);

  useEffect(() => {
    if (!enableFetchFunction) {
      setItems(options);
    }
  }, [options, fetchItems]);

  // Change the type of options to be more specific
  let formattedOptions: SelectOption[] | GroupedOptions[];

  if (optionsGrouped) {
    formattedOptions = items.map((group) => ({
      label: group.type,
      options: group.choices.map(formatOption),
    }));
  } else {
    formattedOptions = items.map(formatOption);
  }

  const handleSelectChange = (selectedOption: SelectOption | null) => {
    onItemChange(selectedOption);
    setSelectedItemState(selectedOption);
    if (selectRef.current) {
      selectRef.current.blur();
    }
  };

  const customStyles = reactSelectCustomStyles;
  const classNames = reactSelectClassConfig;

  let selectedOption: SelectOption | undefined = undefined;

  if (optionsGrouped) {
    for (const group of formattedOptions as GroupedOptions[]) {
      selectedOption = group.options.find(
        (option) => option.value === selectedItem?.value
      );
      if (selectedOption) break;
    }
  } else {
    selectedOption = (formattedOptions as SelectOption[]).find(
      (option) => option.value === selectedItem?.value
    );
  }

  return (
    <>
      <label className={`form-label fw-bold ${required ? "required" : ""}`}>
        {label}
      </label>
      <Select
        ref={selectRef}
        isSearchable
        isClearable
        isLoading={!options.length && isLoading}
        options={formattedOptions}
        styles={customStyles}
        classNames={classNames}
        noOptionsMessage={() => noOptionsMessage}
        onChange={handleSelectChange}
        placeholder={placeholder}
        value={selectedOption}
        isDisabled={disabled}
        components={{
          IndicatorsContainer: () => null,
        }}
        maxMenuHeight={200}
      />
    </>
  );
};

export { ListSelectComponent };

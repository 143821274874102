import { createDataProvider } from "../../../../generic/detail/GenericDataProvider";
import { getRecommendationById } from "../../../../modules/api/recommendation/_requests";



const { DataProvider: RecommendationDataProvider, useData: useRecommendationData } = createDataProvider(
    "RecommendationData",
    getRecommendationById,
    "recommendation_id",
  );

export { RecommendationDataProvider, useRecommendationData };

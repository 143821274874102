/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC, lazy, useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { PageTitle } from "../../../_metronic/layout/core";


import { AccidentStatisticsModel } from "../../modules/api/accident/_models";
import { getAccidentStatistics } from "../../modules/api/accident/_requests";
import { AccidentCountCard } from "../../partials/cards/AccidentCountCard";
import { ProgressBarItem } from "../../partials/widgets/ProgressBarItem";
import { getAuth } from "../auth";
const AccidentCountCardWrapper = lazy(() =>
  import("../../partials/cards/AccidentCountCardWrapper")
);

const WS_URL = process.env.REACT_APP_WS_URL;

const DashboardPage: FC = () => {
  const [ws, setWs] = useState<WebSocket | null>(null);
  const auth = getAuth();
  const [
    accidentStats,
    setAccidentStats,
  ] = useState<AccidentStatisticsModel | null>(null);
  const [daysAfterLastAccident, setDaysAfterLastAccident] = useState<number>(0);
  const [accidentStatsLoading, setAccidentStatsLoading] = useState<boolean>(
    true
  );
  const [retryInterval, setRetryInterval] = useState<NodeJS.Timeout | null>(
    null
  );

  const handleWebSocketError = async (wsInstance: WebSocket, err: any) => {
    // Call the function immediately upon error
    if (!accidentStats)
      getAccidentStatistics()
        .then((data: AccidentStatisticsModel) => {
          setAccidentStats(data);
          setAccidentStatsLoading(false);
        })
        .catch((err: any) => {});

    if (retryInterval) return; // If there's already an interval set, don't set another one

    const intervalId = setInterval(() => {
      getAccidentStatistics()
        .then((data: AccidentStatisticsModel) => {
          setAccidentStats(data);
          setAccidentStatsLoading(false);
        })
        .catch((err: any) => {});
    }, 60 * 1000); // 20 seconds

    setRetryInterval(intervalId);
  };

  useEffect(() => {
    if (ws || !auth) return;
    const socketUrl = `${WS_URL}/osh_event_log`;
    const wsInstance = new WebSocket(socketUrl, ["Bearer", `${auth.access}`]);

    wsInstance.onopen = () => {
      if (retryInterval) {
        clearInterval(retryInterval); // Clear the interval if WebSocket reconnects
        setRetryInterval(null);
      }
    };

    wsInstance.onmessage = (msg) => {
      const data = JSON.parse(msg.data);
      setAccidentStats(data);
      setAccidentStatsLoading(false);
      const last_accident_date = new Date(data.last_accident_ts);
      const today = new Date();
      const diffTime = Math.abs(today.getTime() - last_accident_date.getTime());
      const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
      setDaysAfterLastAccident(diffDays);
    };

    wsInstance.onerror = (err) => {
      handleWebSocketError(wsInstance, err);
      wsInstance.close();
    };

    setWs(wsInstance);

    return () => {
      if (retryInterval) {
        clearInterval(retryInterval); // Clear the interval when the component is unmounted
      }
    };
  }, [ws, auth, accidentStats]);

  return (
    <>
      {/* begin::Row */}
      <div
        className="row"
        style={
          {
            // maxHeight: "2500px",
          }
        }
      >
        <div
          className="col-12 col-xl-3"
          style={{
            height: "100%",
            placeSelf: "flex-end",
          }}
        >
          <AccidentCountCardWrapper
            style=""
            background="bg-dark"
            color="white"
            title="Wypadki w miejscu pracy"
          >
            <AccidentCountCard
              background="bg-light-danger"
              color="white"
              title="Ostatni miesiąc"
              accidentCount={
                !accidentStatsLoading ? (
                  accidentStats?.last_month_accidents
                ) : (
                  <>
                    <span className="spinner spinner-border fw-bolder"> </span>
                  </>
                )
              }
            />
            <AccidentCountCard
              background="bg-warning"
              color="white"
              title="Ostatni kwartał"
              accidentCount={
                !accidentStatsLoading ? (
                  accidentStats?.last_quarter_accidents
                ) : (
                  <>
                    <span className="spinner spinner-border fw-bolder"> </span>
                  </>
                )
              }
            />
            <AccidentCountCard
              background="bg-success"
              color="white"
              title="Ostatnie 6 miesięcy"
              accidentCount={
                !accidentStatsLoading ? (
                  accidentStats?.last_half_year_accidents
                ) : (
                  <>
                    <span className="spinner spinner-border fw-bolder"> </span>
                  </>
                )
              }
            />
          </AccidentCountCardWrapper>
        </div>
        <div className="col-12 col-xl-9 d-flex flex-column my-10 my-xl-0 mx-0 px-0">
          <div className="align-self-start">
            <span
              className="fw-bolder"
              style={{
                fontSize: "2.5rem",
              }}
            >
              {daysAfterLastAccident > 20 ? (
                <>
                  Pracujemy bez wypadku od{" "}
                  <span className="text-success">{daysAfterLastAccident}</span>{" "}
                  dni.
                </>
              ) : (
                <>
                  Dbamy, aby nasz zakład pracy był{" "}
                  <span className="text-success">bezpieczny</span>.{" "}
                </>
              )}
            </span>
          </div>
          <div
            style={{
              alignContent: "flex-end",
              justifyContent: "space-between",
              marginTop: "auto",
            }}
            className="w-100"
          >
            <AccidentCountCardWrapper
              style={
                accidentStatsLoading
                  ? "mt-auto spinner spinner-primary spinner-lg"
                  : "mt-auto "
              }
              background="bg-dark"
              color="white"
              title="Najczęstsze okoliczności wypadków"
            >
              {!accidentStatsLoading && accidentStats?.accident_probable_causes
                ? accidentStats?.accident_probable_causes.map(
                    (cause, index) => {
                      const colors = [
                        "bg-warning",
                        "bg-danger",
                        "bg-light-warning",
                        "bg-success",
                        "bg-light-success", // Add more colors as needed
                      ];

                      return (
                        <ProgressBarItem
                          key={index}
                          titleContent={cause.description}
                          titleColor="text-white"
                          progressBarColor={colors[index % colors.length]}
                          accidentCount={cause.count}
                          totalAccidentCount={
                            accidentStats.last_year_total_accidents_count
                          }
                        />
                      );
                    }
                  )
                : [1, 1, 1, 1, 1].map((_, index) => {
                    return (
                      <ProgressBarItem
                        key={index}
                        titleContent={""}
                        titleColor="text-white"
                        progressBarColor={""}
                        accidentCount={0}
                        totalAccidentCount={1}
                        loading={true}
                      />
                    );
                  })}
            </AccidentCountCardWrapper>
            {/* <PieChart style="" 
background="" 
color="" 
title="" /> */}

            <div></div>
          </div>
        </div>
        
        {/* <CardsWidget20 className="bg-gray" description="tell me" color="" img=""/> */}
      </div>
      {/* end::Row */}
    </>
  );
};

const DashboardWrapper: FC = () => {
  const intl = useIntl();
  return (
    <>
      <PageTitle breadcrumbs={[]}>Statystyki</PageTitle>
      <DashboardPage />
    </>
  );
};

export { DashboardWrapper };

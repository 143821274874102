import axios from "axios";
import { ID, stringifyRequestQuery } from "../../../../_metronic/helpers";
import { API_URL } from "../_variables";
import { FIRE_PROTECTION_DOCUMENT_URL } from "../fire-protection-document/_requests";

export function getResources(query: any) {
  return axios.get(`${API_URL}/resources?${query}`).then((res) => res.data);
}

export function getResourcesByPath(path: string, query: any) {
  return axios
    .get(`${API_URL}/resources/${path}?${query}`)
    .then((res) => res.data);
}

export function deleteFileManager(id: number) {
  return axios.delete(`${API_URL}/resources/${id}`);
}

export const fetchResources = async (state: any) => {
  const query = stringifyRequestQuery(state);
  return axios.get(`${API_URL}/resources?${query}`).then((res) => res.data);
};

export const exportResourcesToZip = async (
  id: Array<ID>,
  path?: string,
  filteringValues?: object
) => {
  return axios
    .post(
      `${API_URL}/resources/export`,
      { ids: id, path: path, ...filteringValues },
    )
    .then((res) => res.data)
    
};


export function createFileManagerDocument(payload: any) { 
  return axios.post(`${FIRE_PROTECTION_DOCUMENT_URL}/`, payload);
}

export function updateFileManagerDocument(id: number, payload: any) { 
  return axios.put(`${FIRE_PROTECTION_DOCUMENT_URL}/${id}/`, payload);
}

import { Dispatch, SetStateAction } from "react";

export type ID = undefined | null | number;

export type PaginationState = {
  page: number;
  pageSize: 10 | 30 | 50 | 100;
  count?: number | 0;
  next?: string | null;
  previous?: string | null;
};

export type SortState = {
  sort?: string;
  order?: "asc" | "desc";
};

export type PathState = { 
  path?: string;
}

export type FilterState = {
  filter?: unknown;
};

export type SearchState = {
  search?: string;
};

export type Response<T> = {
  results?: Array<T>;
  count?: number;
  previous?: string | null;
  next?: string | null;
};

export interface ErrorResponse {
  status: number;
  data: any;
};

export interface RequestError {
  response: ErrorResponse;
};



export type QueryState = PaginationState &
  SortState &
  FilterState &
  SearchState & PathState;

export type QueryRequestContextProps = {
  state: QueryState;
  updateState: (updates: Partial<QueryState>) => void;
};

export const initialQueryState: QueryState = {
  page: 1,
  pageSize: 10,
  path: "",
};

export const initialQueryRequest: QueryRequestContextProps = {
  state: initialQueryState,
  updateState: () => {},
};

export type QueryResponseContextProps<T> = {
  response?: Response<T> | undefined;
  refetch: () => void;
  isLoading: boolean;
  query: string;
};

export const initialQueryResponse = {
  refetch: () => {},
  isLoading: false,
  query: "",
};

export type ListViewContextProps = {
  selected: Array<ID>;
  onSelect: (selectedId: ID) => void;
  onSelectAll: () => void;
  clearSelected: () => void;
  // NULL => (CREATION MODE) | MODAL IS OPENED
  // NUMBER => (EDIT MODE) | MODAL IS OPENED
  // UNDEFINED => MODAL IS CLOSED
  itemIdForUpdate?: ID;
  setItemIdForUpdate: Dispatch<SetStateAction<ID>>;
  isAllSelected: boolean;
  disabled: boolean;
};

export const initialListView: ListViewContextProps = {
  selected: [],
  onSelect: () => {},
  onSelectAll: () => {},
  clearSelected: () => {},
  setItemIdForUpdate: () => {},
  isAllSelected: false,
  disabled: false,
};

import axios from "axios";
import { API_URL } from "../_variables";
import { ImageModel } from "../images/_models";

export const FIRE_PROTECTION_DOCUMENT_URL = `${API_URL}/fire-protection-documents`;
export function getFireProtectionDocuments(query: string) {
  return axios.get(`${FIRE_PROTECTION_DOCUMENT_URL}/?${query}`).then((res) => res.data);
}

export function uploadFireProtectionFile(file: FormData, recordId:number) {
  return axios.post<ImageModel>(`${FIRE_PROTECTION_DOCUMENT_URL}/${recordId}/files`, file).then((response) => response.data);
}

export function getFireProtectionDocumentById(id: number)  {
  return axios.get(`${FIRE_PROTECTION_DOCUMENT_URL}/${id}`).then((response) => response.data);
}

export function updateFireProtectionDocument(id: number, payload: any) { 
  return axios.put(`${FIRE_PROTECTION_DOCUMENT_URL}/${id}/`, payload);
}

export function createFireProtectionDocument(payload: any) { 
  return axios.post(`${FIRE_PROTECTION_DOCUMENT_URL}/`, payload);
}

export function deleteFireProtectionDocument(id: number) { 
  return axios.delete(`${FIRE_PROTECTION_DOCUMENT_URL}/${id}/`);
}

/* eslint-disable react/jsx-no-target-blank */
import { useIntl } from "react-intl";
import { USER_GROUPS, getAuth, useAuth } from "../../../../../app/pages/auth";
import { isUserOutsourcedWorker } from "../../../../../app/pages/recommendation/helpers/permissions";
import { toAbsoluteUrl } from "../../../../helpers";
import { SidebarMenuItem } from "./SidebarMenuItem";
import { SidebarMenuItemWithSub } from "./SidebarMenuItemWithSub";

const SidebarMenuMain = () => {
  const intl = useIntl();
  const isUserAdmin = getAuth()?.groups.includes(USER_GROUPS.ADMIN);
  const { auth } = useAuth();
  return (
    <>
      <SidebarMenuItem
        to="/dashboard"
        icon="element-11"
        title="Statystyki"
        fontIcon="bi-app-indicator"
      />

      {/* normal menu item with svg icon as a class  */}

      {!isUserOutsourcedWorker(auth) && (
        <SidebarMenuItemWithSub
          to="/crafted/pages"
          title="Wypadki"
          icon="information-5"
          fontIcon="bi-app-indicator"
        >
          <SidebarMenuItem
            to="/wypadki"
            title="Lista wypadków"
            hasBullet={true}
          />
          <SidebarMenuItem
            to="/wypadki/statystyki"
            title="Statystyki wypadków"
            hasBullet={true}
          />
          <SidebarMenuItem
            to="/wypadki/dodaj"
            title="Utwórz wypadek"
            hasBullet={true}
          />
        </SidebarMenuItemWithSub>
      )}

      {!isUserOutsourcedWorker(auth) && (
        <SidebarMenuItemWithSub
          to="/szkolenia"
          title="Szkolenia"
          icon={
            <img
              src={toAbsoluteUrl(
                "/media/custom/icons/osh-training-icon-sidebar.svg"
              )}
              alt="logo"
            />
          }
          fontIcon="bi-app-indicator"
        >
          <SidebarMenuItem
            to="/szkolenia"
            title="Przegląd"
            hasBullet={true}
          />
          <SidebarMenuItem
            to="/szkolenia/przeglad"
            title="Rejestr szkoleń"
            hasBullet={true}
          />
          <SidebarMenuItem
            to="/szkolenia/pracownicy"
            title="Szkolenia pracowników"
            hasBullet={true}
          />
          <SidebarMenuItem
            to="/szkolenia/rodzaje-szkolen"
            title="Rodzaje szkoleń"
            hasBullet={true}
          />
          <SidebarMenuItem
            to="/szkolenia/testy"
            title="Testy"
            hasBullet={true}
          />
        </SidebarMenuItemWithSub>
      )}

      {/* icon as an svg image */}

      <SidebarMenuItemWithSub
        to="/crafted/accounts"
        title="Zalecenia BHP"
        icon={
          <img
            src={toAbsoluteUrl(
              "/media/custom/icons/osh-recommendations-icon.svg"
            )}
            alt="logo"
          />
        }
        fontIcon="bi-person"
      >
        <SidebarMenuItem
          to="/zalecenia-bhp"
          title="Przegląd"
          hasBullet={true}
        />
        <SidebarMenuItem
          to="/zalecenia-bhp/dodaj"
          title="Utwórz zalecenie"
          hasBullet={true}
        />
        <SidebarMenuItem
          to="/zalecenia-bhp/statystyki"
          title="Statystyki zaleceń"
          hasBullet={true}
        />
      </SidebarMenuItemWithSub>
      {!isUserOutsourcedWorker(auth) && (
        <SidebarMenuItemWithSub
          to="/ppoz"
          title="Panel PPOŻ"
          icon={
            <img
              src={toAbsoluteUrl("/media/custom/icons/fire.svg")}
              alt="logo"
              style={{
                maxHeight: "20px",
              }}
            />
          }
          fontIcon="bi-person"
        >
          <SidebarMenuItem to="/ppoz" title="Przegląd" hasBullet={true} />
          <SidebarMenuItem
            to="/ppoz/budynki"
            title="Budynki"
            hasBullet={true}
          />
          <SidebarMenuItem
            to="/ppoz/systemy-w-strefach-pozarowych"
            title="Systemy bezpieczeństwa w strefach"
            hasBullet={true}
          />
          <SidebarMenuItem
            to="/ppoz/strefy-pozarowe"
            title="Strefy pożarowe"
            hasBullet={true}
          />
          <SidebarMenuItem
            to="/ppoz/prace-niebezpieczne-pozarowo"
            title="Prace niebezpieczne pożarowo"
            hasBullet={true}
          />
          <SidebarMenuItem
            to="/ppoz/techniczne-systemy-bezpieczenstwa"
            title="Typy systemów bezpieczeństwa"
            hasBullet={true}
          />
          <SidebarMenuItem
            to="/ppoz/serwisy"
            title="Serwisy"
            hasBullet={true}
          />
          <SidebarMenuItem
            to="/ppoz/typy-serwisow"
            title="Rodzaje serwisów"
            hasBullet={true}
          />
          <SidebarMenuItem
            to="/ppoz/dokumenty"
            title="Wymagane dokumenty"
            hasBullet={true}
          />
        </SidebarMenuItemWithSub>
      )}
      {isUserAdmin && (
        <SidebarMenuItemWithSub
          to=""
          icon="switch"
          title="Konfiguracja"
          fontIcon="bi-app-indicator"
        >
          <SidebarMenuItem
            to="/uzytkownicy"
            title="Użytkownicy"
            hasBullet={true}
          />
          <SidebarMenuItem to="/firma" title="Dane firmy" hasBullet={true} />
          <SidebarMenuItem
            to="/pracownicy"
            title="Pracownicy"
            hasBullet={true}
          />
        </SidebarMenuItemWithSub>
      )}
    </>
  );
};

export { SidebarMenuMain };

import { createDataProvider } from "../../../generic/detail/GenericDataProvider";
import { getWorkerById } from "../../../modules/api/worker/_requests";



const { DataProvider: WorkerDataProvider, useData: useWorkerData } = createDataProvider(
    "WorkerData",
    getWorkerById,
    "worker_id",
  );

export { WorkerDataProvider, useWorkerData };

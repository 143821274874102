import axios, { AxiosRequestConfig, AxiosResponse } from "axios";
import { API_URL } from "../_variables";
import { ImageModel } from "../images/_models";
import {
  HistoryDataModel,
  ServiceLogDetailModel,
  ServiceLogTypeDetailModel,
} from "./_models";

const SERVICE_LOG_URL = `service-logs`;
const SERVICE_LOG_TYPE_URL = `service-log-types`;

export function getServiceLogs(query: any) {
  return axios
    .get(`${API_URL}/${SERVICE_LOG_URL}?${query}`)
    .then((res) => res.data);
}

export function getServiceLogsDisabledPagiantion() {
  return axios
    .get(`${API_URL}/${SERVICE_LOG_URL}?pagination_enabled=false`)
    .then((res) => res);
}

export function getServiceLogById(id: number) {
  return axios
    .get(`${API_URL}/${SERVICE_LOG_URL}/${id}`)
    .then((res: AxiosResponse<ServiceLogDetailModel>) => res.data);
}

export function deleteServiceLog(id: number) {
  return axios
    .delete(`${API_URL}/${SERVICE_LOG_URL}/${id}/`)
    .then((res) => res);
}

export function createServiceLog(payload: any) {
  return axios
    .post(`${API_URL}/${SERVICE_LOG_URL}/`, payload)
    .then((res) => res);
}

export function updateServiceLog(id: number, payload: any) {
  return axios
    .put(`${API_URL}/${SERVICE_LOG_URL}/${id}/`, payload)
    .then((res) => res);
}

export function getServiceLogTypes(query: any) {
  return axios
    .get(`${API_URL}/${SERVICE_LOG_TYPE_URL}?${query}`)
    .then((res) => res.data);
}

export function getServiceLogTypesDisabledPagiantion() {
  return axios
    .get(`${API_URL}/${SERVICE_LOG_TYPE_URL}?pagination_enabled=false`)
    .then((res) => res.data);
}

export function getServiceLogTypeById(id: number) {
  return axios
    .get(`${API_URL}/${SERVICE_LOG_TYPE_URL}/${id}`)
    .then((res: AxiosResponse<ServiceLogTypeDetailModel>) => res.data);
}

export function deleteServiceLogType(id: number) {
  return axios
    .delete(`${API_URL}/${SERVICE_LOG_TYPE_URL}/${id}/`)
    .then((res) => res);
}

export function createServiceLogType(payload: any) {
  return axios
    .post(`${API_URL}/${SERVICE_LOG_TYPE_URL}/`, payload)
    .then((res) => res);
}

export function updateServiceLogType(id: number, payload: any) {
  return axios
    .put(`${API_URL}/${SERVICE_LOG_TYPE_URL}/${id}/`, payload)
    .then((res) => res);
}

export function uploadServiceLogDocument(
  file: FormData,
  id: number,
  onProgress: (percentage: number) => void
) {
  return uploadRecordFile(
    `${API_URL}/${SERVICE_LOG_URL}/${id}/files`,
    file,
    onProgress
  );
}

export function uploadRecordFile(
  endpointUrl: string,
  file: FormData,
  onProgress: (percentage: number) => void
) {
  const config: AxiosRequestConfig = {
    onUploadProgress: (progressEvent) => {
      const percentCompleted = Math.round(
        (progressEvent.loaded * 100) / progressEvent.total
      );
      onProgress(percentCompleted);
    },
  };

  return axios
    .post<ImageModel>(endpointUrl, file, config)
    .then((response) => response.data);
}

export function getHistoryData(modelName: string, id: number) {
  return axios
    .get<HistoryDataModel[]>(`${API_URL}/${modelName}/${id}/history`)
    .then((response) => response.data);
}

import { FC, lazy } from "react";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import { App } from "../App";
import { ErrorsPage } from "../modules/errors/ErrorsPage";
import { Logout, useAuth } from "../pages/auth";
import AuthLayout, { AuthPage } from "../pages/auth/AuthPage";
import { ContactSupport } from "../pages/auth/components/Support";
import { OSHTrainingForm } from "../pages/osh-training/osh-instruction-test/exam/OSHTrainingTest";
import { PrivateRoutes } from "./PrivateRoutes";
const VerifcationPage = lazy(() =>
  import("../pages/auth/components/Verification")
);
const EmailNotActivatedPage = lazy(() =>
  import("../pages/auth/components/EmailNotActivated")
);
const VerifyEmailPage = lazy(() =>
  import("../pages/auth/components/VerifyEmail")
);

const PrivacyPolicy = lazy(() =>
  import("../pages/auth/components/PrivacyPolicy")
);

const { PUBLIC_URL } = process.env;

const AppRoutes: FC = () => {
  const { currentUser, auth } = useAuth();

  return (
    <BrowserRouter basename={PUBLIC_URL}>
      <Routes>
        <Route element={<App />}>
        <Route path="szkolenia/przeglad/:osh_training_id/test" element={<OSHTrainingForm />} />
          <Route path="error/*" element={<ErrorsPage />} />
          <Route path="logout" element={<Logout />} />
          <Route element={<AuthLayout />}>
            <Route path="kontakt" element={<ContactSupport />} />
          </Route>
          <Route path="polityka-prywatnosci" element={<PrivacyPolicy />} />
          {(currentUser && !currentUser.email_activated) || !currentUser ? (
            <Route path="email-verification" element={<VerifyEmailPage />} />
          ) : null}
          {/* If the user is authenticated and accepted by admin */}
          {currentUser &&
          currentUser.accepted_by_admin &&
          currentUser.email_activated ? (
            <>
              <Route path="/*" element={<PrivateRoutes />} />
              <Route index element={<Navigate to="/dashboard" />} />
              <Route
                path="/verification"
                element={<Navigate to="/dashboard" />}
              />
              <Route
                path="/verify-email"
                element={<Navigate to="/dashboard" />}
              />
            </>
          ) : (
            <>
              {/* If the user is authenticated but NOT accepted by admin */}
              {currentUser ? (
                !currentUser.accepted_by_admin &&
                currentUser.email_activated ? (
                  <>
                    <Route path="/*" element={<VerifcationPage />} />
                    <Route index element={<Navigate to="/verification" />} />
                    <Route
                      path="auth/*"
                      element={<Navigate to="/verification" />}
                    />
                    <Route
                      path="verify-email"
                      element={<Navigate to="/verification" />}
                    />
                  </>
                ) : (
                  <>
                    <Route path="/*" element={<EmailNotActivatedPage />} />
                    <Route index element={<Navigate to="/verify-email" />} />
                    <Route
                      path="auth/*"
                      element={<Navigate to="/verify-email" />}
                    />
                    <Route
                      path="verification/*"
                      element={<Navigate to="/verify-email" />}
                    />
                  </>
                )
              ) : !auth ? (
                <>
                  <Route path="auth/*" element={<AuthPage />} />
                  <Route path="auth" element={<Navigate to="/auth/login" />} />

                  <Route path="*" element={<Navigate to="/auth" />} />
                </>
              ) : (
                <>
                  <Route path="auth/*" element={<AuthPage />} />
                  <Route path="auth" element={<Navigate to="/auth/2fa" />} />

                  <Route path="*" element={<Navigate to="/auth" />} />
                </>
              )}
            </>
          )}
        </Route>
      </Routes>
    </BrowserRouter>
  );
};

export { AppRoutes };

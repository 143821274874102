import { RecommendationDetailModel } from "../../../modules/api/recommendation/_models";
import { USER_GROUPS } from "../../auth";

export function isUserOwner(
  auth: any,
  currentUser: any,
  recommendation: RecommendationDetailModel
) {
  return recommendation?.created_by === currentUser?.id;
}

export function isUserAdmin(auth: any) {
    return auth?.groups.includes(USER_GROUPS.ADMIN);
}

export function isUserOSHWorker(auth: any) {
    return auth?.groups.includes(USER_GROUPS.OSH_WORKER);
}

export function isUserSecurityWorker(auth: any) {
    return auth?.groups.includes(USER_GROUPS.SECURITY_WORKER);
}

export function isUserOutsourcedWorker(auth: any) {
  return auth?.groups.includes(USER_GROUPS.OUTSOURCED_WORKER);
}
export function isUserRecommendationOwner(
  auth: any,
  currentUser: any,
  recommendation: RecommendationDetailModel
) {
  return recommendation?.created_by === currentUser?.id;
}



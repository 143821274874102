import axios from "axios";
import { AuthModel, ContactModel, Enable2FAModel, ObtainToken2FAEnabledModel, UserBaseModel, UserGroupsModel, UserModel } from "./_models";

import { API_URL } from "../../_variables";

export const GET_USER_BY_ACCESSTOKEN_URL = `${API_URL}/token/verify`;
export const LOGIN_URL = `${API_URL}/token`;
export const REGISTER_URL = `${API_URL}/register`;
export const REQUEST_PASSWORD_URL = `${API_URL}/forgot-password`;
export const REFRESH_TOKEN_URL = `${API_URL}/token/refresh`;

export const GET_CURRENT_USER_DETAILS = `${API_URL}/users/me`;
export const GET_USERS = `${API_URL}/users`;

// Server should return AuthModel
export function refreshToken(refreshToken: string) {
  return axios.post<AuthModel>(REFRESH_TOKEN_URL, {
    refresh: refreshToken,
  });
}

// Server should return AuthModel
export function login(email: string, password: string) {
  return axios.post<AuthModel>(LOGIN_URL, {
    email,
    password,
  });
}

// Server should return AuthModel
export function register(
  email: string,
  first_name: string,
  last_name: string,
  password: string,
  confirm_password: string,
  terms_accepted: boolean,
  gender: string
) {
  return axios.post(REGISTER_URL, {
    email,
    first_name,
    last_name,
    password,
    confirm_password,
    terms_accepted,
    gender,
  });
}


// Server should return object => { result: boolean } (Is Email in DB)
export function requestPassword(email: string) {
  return axios.post<{ result: boolean }>(REQUEST_PASSWORD_URL, {
    email,
  });
}

export function getUserByToken(token: string) {
  return axios.post<UserModel>(GET_USER_BY_ACCESSTOKEN_URL, {
    token: token,
  }).then((res) => res.data);
}

export function getCurrentUserDetails() {
  return axios.get<UserModel>(GET_CURRENT_USER_DETAILS);
}

export function setUserGroup(payload: UserGroupsModel) {
  return axios.put<UserGroupsModel>(
    `${GET_USERS}/${payload.id}/groups`,
    payload
  );
}

export function sendVerificationEmail(payload: UserBaseModel) { 
  return axios.post<UserBaseModel>(`${API_URL}/users/${payload.id}/verification-email/send`, payload);
}

export function contactSupport(payload: ContactModel) { 
  return axios.post<ContactModel>(`${API_URL}/contact-support`, payload);
}

export function enable2FA()  { 
  return axios.post(`${API_URL}/enable-2fa`).then(res=>res.data);
}

export function confirm2FA(payload: Enable2FAModel) {
  return axios.post(`${API_URL}/confirm-2fa`, payload).then(res=>res.data);
   
}

export function verify2FA(payload: ObtainToken2FAEnabledModel) {
  return axios.post<AuthModel>(`${API_URL}/token/verify-2fa`, payload).then(res=>res.data);
}


export function authenticateUser(payload : any) {
  return axios.post<AuthModel>(`${API_URL}/users/authenticate`, payload).then(res=>res.data);
}

export function disable2FA(payload: any) { 
  return axios.post(`${API_URL}/disable-2fa`, payload).then(res=>res.data);
}
import axios, { AxiosResponse } from "axios";
import { API_URL } from "../_variables";
import { FactoryDetailModel } from "../factory/_models";
import { fetchWithSessionStorage } from "../helpers";
import { uploadRecordFile } from "../service-log/_requests";
import {
  AccidentCreateEditRequestModel,
  AccidentDetailModel,
  AccidentLocationTypeModel,
  AccidentRegistrationFormModel,
  CompanyCreateUpdateModel,
  CompanyModel,
} from "./_models";

export const ACCIDENTS_URL = `${API_URL}/accidents`;
export const ACCIDENT_TYPES_URL = `${API_URL}/accident-types`;
export const INJURY_LEVELS_URL = `${API_URL}/injury-levels`;
export const FACTORIES_URL = `${API_URL}/factories`;
export const FACTORY_AREAS_URL = (factoryId: number) =>
  `${API_URL}/factories/${factoryId}/areas/`;
export const ACCIDENT_LOCATION_TYPES_URL = `${API_URL}/accident-location-types`;
export const ACCIDENT_SEVERITY_URL = `${API_URL}/accident-severity-levels`;
export const COMPANY_DETAILS = `${API_URL}/companies/my`;
export const ACCIDENT_CARDINALITY_LIST_URL = `${API_URL}/accident-cardinalities`;
export const ACCIDENT_REGISTRATION_FORM_URL_SUFIX = `accident-registration-cards`;

export const ACCIDENT_PROBABLE_CAUSES = `${API_URL}/accident-probable-causes`;

export function getAccidents(queryParams?: any) {
  return axios.get(`${ACCIDENTS_URL}?${queryParams}`).then((d) => d.data);
}

export function getAccidentById(accidentId: number) {
  return axios
    .get(`${ACCIDENTS_URL}/${accidentId}`)
    .then((response: AxiosResponse<AccidentDetailModel>) => response.data);
}

export function createAccident(accidentData: AccidentCreateEditRequestModel) {
  return axios.post<AccidentDetailModel>(`${ACCIDENTS_URL}/`, accidentData);
}

export function updateAccident(
  accidentId: number,
  accidentData: AccidentCreateEditRequestModel
) {
  return axios.patch<AccidentDetailModel>(
    `${ACCIDENTS_URL}/${accidentId}/`,
    accidentData
  );
}

export function deleteAccident(accidentId: number) {
  return axios.delete(`${ACCIDENTS_URL}/${accidentId}`);
}

export function getAccidentTypes() {
  return fetchWithSessionStorage(ACCIDENT_TYPES_URL, "accident-types");
}

export function getAccidentCardinality() {
  return fetchWithSessionStorage(
    ACCIDENT_CARDINALITY_LIST_URL,
    "accident-cardinalities"
  );
}

export function getAccidentProbableCauses() {
  return fetchWithSessionStorage(
    ACCIDENT_PROBABLE_CAUSES,
    "accident-probable-causes"
  );
}

export function getInjuryLevels() {
  return fetchWithSessionStorage(INJURY_LEVELS_URL, "injury-levels");
}

export function getAccidentSeverityLevels() {
  return fetchWithSessionStorage(
    ACCIDENT_SEVERITY_URL,
    "accident-severity-levels"
  );
}

export function getAllAccidentLocationTypes() {
  return axios
    .get(`${ACCIDENT_LOCATION_TYPES_URL}`)
    .then((response: AxiosResponse<AccidentLocationTypeModel[]>) => response);
}

export function getCompanyDetails() {
  return axios
    .get<CompanyModel>(COMPANY_DETAILS)
    .then((response) => response.data);
}

export function createUpdateCompanyDetails(payload: CompanyCreateUpdateModel) {
  return axios
    .put<CompanyCreateUpdateModel>(COMPANY_DETAILS, payload)
    .then((response) => response.data);
}

export function createUpdateFactoryDetails(payload: FactoryDetailModel) {
  return axios
    .put<FactoryDetailModel>(`${FACTORIES_URL}/${payload.id}`, payload)
    .then((response) => response.data);
}

export function createRegistrationForm(accidentId: number, formData: any) {
  const url = `${ACCIDENTS_URL}/${accidentId}/${ACCIDENT_REGISTRATION_FORM_URL_SUFIX}/`;
  return axios.post<AccidentRegistrationFormModel>(url, formData);
}

export function updateRegistrationForm(
  accidentId: number,
  formData: any,
  formId: number
) {
  const url = `${ACCIDENTS_URL}/${accidentId}/${ACCIDENT_REGISTRATION_FORM_URL_SUFIX}/${formId}/`;
  return axios.patch<AccidentRegistrationFormModel>(url, formData);
}

export function getAccidentStatistics() {
  return axios.get(`${API_URL}/accidents-statistics`).then((res) => res.data);
}

export function getAccidentStatisticsChartData(
  queryParams = "filter_field=accident_type"
) {
  return axios
    .get(`${API_URL}/accidents/statistics/charts?${queryParams}`)
    .then((res) => res.data);
}

export function uploadAccidentFile(
  file: FormData,
  accidentId: number,
  onProgress: (percentage: number) => void
) {
  return uploadRecordFile(
    `${ACCIDENTS_URL}/${accidentId}/files`,
    file,
    onProgress
  );
}

export function getAccidentAnalyticsData() {
  return axios
    .get(`${ACCIDENTS_URL}/statistics/analysis`)
    .then((res) => res.data);
}

import { FormikProps } from "formik";
import { FC } from "react";
import {
  OSHTrainingQuestionAnswerModel,
  OSHTrainingQuestionModel,
} from "../../../../modules/api/osh-training/_models";

interface AnswerProps {
  questionId: number;
  answer: OSHTrainingQuestionAnswerModel;
  formik: FormikProps<any>;
  isMultipleChoice: boolean;
}

const AnswerComponent: FC<AnswerProps> = ({ questionId, answer, formik, isMultipleChoice }) => {
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (isMultipleChoice) {
      const currentAnswers = formik.values.answers[questionId] || [];
      if (event.target.checked) {
        formik.setFieldValue(`answers.${questionId}`, [...currentAnswers, event.target.value]);
      } else {
        formik.setFieldValue(
          `answers.${questionId}`,
          currentAnswers.filter((id: string) => id !== event.target.value)
        );
      }
    } else {
      formik.setFieldValue(`answers.${questionId}`, event.target.value);
    }
  };

  const isChecked = isMultipleChoice
    ? (formik.values.answers[questionId] || []).includes(String(answer.id))
    : formik.values.answers[questionId] === String(answer.id);

  return (
    <label
      className="form-check w-100 border rounded me-5 py-4 d-flex align-items-center bg-hover-light-primary my-2"
      htmlFor={`answer-${answer.id}`}
    >
      <input
        className="form-check-input ms-1 me-5 p-3"
        type={isMultipleChoice ? "checkbox" : "radio"}
        id={`answer-${answer.id}`}
        name={`question-${questionId}`}
        value={answer.id}
        checked={isChecked}
        onChange={handleChange}
      />
      <span className="text-black fw-bolder fs-5">{answer.text}</span>
    </label>
  );
};

interface Props {
  formik: FormikProps<any>;
  question: OSHTrainingQuestionModel;
  index: number;
}

const StepQuestion: FC<Props> = ({ formik, question, index }) => {
  return (
    <div
      className="col-12 mb-7 current-display-initial"
      data-kt-stepper-element="content"
    >
      <div className="pb-10">
        <div className="border-top mb-6"></div>
        <span className="fw-bold fs-5">
          {index + 1}. {question.question}
        </span>
      </div>
      <div className="row">
        {question.answers?.map(
          (answer: OSHTrainingQuestionAnswerModel, answerIndex: number) => (
            <div className="col-12" key={answerIndex}>
              <AnswerComponent
                questionId={question.id}
                answer={answer}
                formik={formik}
                isMultipleChoice={question.is_multiple_choice}
              />
            </div>
          )
        )}
      </div>
    </div>
  );
};

export { StepQuestion };

import { FC, ReactNode } from "react";

export type RecordType = {
  label: string | null;
  value: string | ReactNode; // ReactNode can be string or JSX.Element
};

type Props = {
  title: string | ReactNode | null;
  recordsLeftSide: RecordType[];
  recordsRightSide?: RecordType[] | null;
  enableBorder?: boolean | null;
  recordsLeftSideFullWidth?: boolean | null;
  displayValueOnNewLine?: boolean;
};

const RecordValueContainer: FC<Props> = ({
  title,
  recordsLeftSide,
  recordsRightSide = null,
  enableBorder = true,
  recordsLeftSideFullWidth = false,
  displayValueOnNewLine = false,
}) => {
  const renderTable = (records: RecordType[]) => (
    <div
      className={`col-12 col-lg-6 ${
        recordsLeftSideFullWidth && !recordsRightSide ? "col-lg-12" : ""
      }`}
    >
      <table className="d-flex pe-lg-10 me-lg-5">
        <tbody>
          {records.map((record, index) => (
            <tr key={index} className=" d-block d-lg-table-row py-2" style={{border:"none"}}>
              {record.label && (
                <td className="col-12 col-lg-auto d-block py-2 fs-5 text-muted fw-semibold text-hover-primary pe-10">
                  {record.label}
                </td>
              )}

              <td
                className={`col-12 col-lg-auto px-lg-8 py-2 fs-5 fw-bold border-none ${
                  displayValueOnNewLine ? "d-block pb-3" : "px-lg-8"
                }`}
              >
                {record.value !== "null" &&
                record.value !== "undefined" &&
                record.value !== null &&
                record.value !== undefined ? (
                  record.value
                ) : (
                  <span className="fw-bolder">Niewypełnione</span>
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );

  return (
    <>
      {enableBorder && <hr className="my-5" />}
      <span className="my-5 fs-3 fw-bolder px-5">{title}</span>

      <div className="row p-0 w-100 px-5 mb-5">
        {renderTable(recordsLeftSide)}
        {recordsRightSide && renderTable(recordsRightSide)}
      </div>
    </>
  );
};

export { RecordValueContainer };

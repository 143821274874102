import { FC, useContext, useEffect, useMemo, useState } from "react";
import { useQuery } from "react-query";
import {
  PaginationState,
  QUERIES,
  RequestError,
  WithChildren,
  createResponseContext,
  initialQueryResponse,
  initialQueryState,
  stringifyRequestQuery,
} from "../../../_metronic/helpers";
import { useToastMessage } from "../components/toast/ToastMessageProvider";
import { TOAST_MESSAGES } from "../components/toast/ToastMessages";
import { useQueryRequest } from "./QueryRequestProvider";

export type QueryConfig<T> = {
  modelName: keyof typeof QUERIES;
  fetchFunction: (query: string) => Promise<T>;
  eventType: string;
};


const createGenericQueryProvider = <T extends {}>({
  modelName,
  fetchFunction,
  eventType,
}: QueryConfig<T>) => {
  const QueryResponseContext = createResponseContext<T>(initialQueryResponse);

  const QueryResponseProvider: FC<WithChildren> = ({ children }) => {
    const { state } = useQueryRequest();
    const [query, setQuery] = useState<string>(stringifyRequestQuery(state));
    const updatedQuery = useMemo(() => stringifyRequestQuery(state), [state]);
    const { setToastMessage } = useToastMessage();
    

    useEffect(() => {
      if (query !== updatedQuery) {
        setQuery(updatedQuery);
      }
    }, [updatedQuery]);

    const { isFetching, refetch, data: response } = useQuery(
      `${QUERIES[modelName]}-${query}`,
      () => fetchFunction(query),
      {
        cacheTime: 0,
        keepPreviousData: true,
        refetchOnWindowFocus: false,
        onError: (error: RequestError) => {
          if (error?.response.status == 404) {
            setToastMessage(TOAST_MESSAGES.not_found);
          }
        },
      }
    );

    useEffect(() => {
      const handleListChange = () => {
        refetch();
      };
      window.addEventListener(eventType, handleListChange);
      return () => {
        window.removeEventListener(eventType, handleListChange);
      };
    }, [refetch]);

    return (
      <QueryResponseContext.Provider
        value={{ isLoading: isFetching, refetch, response, query }}
      >
        {children}
      </QueryResponseContext.Provider>
    );
  };

  const useQueryResponse = () => useContext(QueryResponseContext);

  const useQueryResponseData = () => {
    const { response } = useQueryResponse();
    return response?.results || [];
  };

  const useQueryResponsePagination = () => {
    const defaultPaginationState: PaginationState = { ...initialQueryState };
    const { response } = useQueryResponse();

    return response && response.count
      ? {
          page: 1,
          pageSize: 10,
          count: response.count,
          next: response.next,
          previous: response.previous,
        }
      : defaultPaginationState;
  };

  const useQueryResponseLoading = (): boolean => {
    const { isLoading } = useQueryResponse();
    return isLoading;
  };

  return {
    QueryResponseProvider,
    useQueryResponse,
    useQueryResponseData,
    useQueryResponsePagination,
    useQueryResponseLoading,
  };
};

export default createGenericQueryProvider;

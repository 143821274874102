import { useIntl } from "react-intl";
import { useAuth } from "../../../../../app/pages/auth";
import { isUserOutsourcedWorker } from "../../../../../app/pages/recommendation/helpers/permissions";
import { MenuInnerWithSub } from "./MenuInnerWithSub";
import { MenuItem } from "./MenuItem";

export function MenuInner() {
  const intl = useIntl();

  const { auth } = useAuth();

  return (
    <>
      <MenuItem title="Statystyki" to="/dashboard" />
      {!isUserOutsourcedWorker(auth) && (
        <MenuItem title="Wypadki" to="/wypadki" />
      )}
      {!isUserOutsourcedWorker(auth) && (
        <MenuInnerWithSub
          title="Szkolenia"
          to="/szkolenia"
          menuPlacement="bottom-start"
          menuTrigger="click"
        >
          <MenuItem to="/szkolenia" title="Przegląd" hasBullet={true} />
          <MenuItem
            to="/szkolenia/przeglad"
            title="Rejestr szkoleń"
            hasBullet={true}
          />
          <MenuItem
            to="/szkolenia/pracownicy"
            title="Szkolenia pracowników"
            hasBullet={true}
          />
          <MenuItem
            to="/szkolenia/rodzaje-szkolen"
            title="Rodzaje szkoleń"
            hasBullet={true}
          />
          <MenuItem to="/szkolenia/testy" title="Testy" hasBullet={true} />
        </MenuInnerWithSub>
      )}

      <MenuItem title="Zalecenia BHP" to="/zalecenia-bhp" />
      {!isUserOutsourcedWorker(auth) && (
        <MenuInnerWithSub
          title="Panel PPOŻ"
          to="/ppoz"
          menuPlacement="bottom-start"
          menuTrigger="click"
        >
          {/* PAGES */}

          <MenuItem to="/ppoz" title="Przegląd" hasBullet={true} />
          <MenuItem to="/ppoz/budynki" title="Budynki" hasBullet={true} />
          <MenuItem
            to="/ppoz/systemy-w-strefach-pozarowych"
            title="Systemy bezpieczeństwa w strefach"
            hasBullet={true}
          />
          <MenuItem
            to="/ppoz/strefy-pozarowe"
            title="Strefy pożarowe"
            hasBullet={true}
          />
          <MenuItem
            to="/ppoz/prace-niebezpieczne-pozarowo"
            title="Prace niebezpieczne pożarowo"
            hasBullet={true}
          />
          <MenuItem
            to="/ppoz/techniczne-systemy-bezpieczenstwa"
            title="Typy systemów bezpieczeństwa"
            hasBullet={true}
          />
          <MenuItem to="/ppoz/serwisy" title="Serwisy" hasBullet={true} />
          <MenuItem
            to="/ppoz/typy-serwisow"
            title="Rodzaje serwisów"
            hasBullet={true}
          />
          <MenuItem
            to="/ppoz/dokumenty"
            title="Wymagane dokumenty"
            hasBullet={true}
          />
          <MenuItem
            to="/ppoz/menadzer-plikow"
            title="Menadzer plików"
            hasBullet={true}
          />
        </MenuInnerWithSub>
      )}
    </>
  );
}

import createGenericQueryProvider, { QueryConfig } from '../../../generic/table/QueryResponseProvider';
import { AccidentBaseModel } from "./_models";
import { getAccidents } from "./_requests";

const accidentConfig: QueryConfig<AccidentBaseModel> = {
  modelName: 'ACCIDENT_LIST',
  fetchFunction: getAccidents,
  eventType: 'AccidentListChange',
};

export const {
  QueryResponseProvider: AccidentQueryProvider,
  useQueryResponse: useAccidentQueryResponse,
  useQueryResponseData: useAccidentQueryResponseData,
  useQueryResponsePagination: useAccidentQueryResponsePagination,
  useQueryResponseLoading: useAccidentQueryResponseLoading,
} = createGenericQueryProvider<AccidentBaseModel>(accidentConfig);

import {
  FC,
  useState,
  useEffect,
  createContext,
  useContext,
  useRef,
  Dispatch,
  SetStateAction,
} from 'react'
import {LayoutSplashScreen} from '../../../../../_metronic/layout/core'
import {AuthModel, UserModel} from './_models'
import * as authHelper from './AuthHelpers'
import {getUserByToken} from './_requests'
import {WithChildren} from '../../../../../_metronic/helpers'

type AuthContextProps = {
  auth: AuthModel | undefined
  saveAuth: (auth: AuthModel | undefined) => void
  currentUser: UserModel | undefined
  setCurrentUser: Dispatch<SetStateAction<UserModel | undefined>>
  logout: () => void
}

const initAuthContextPropsState = {
  auth: authHelper.getAuth(),
  saveAuth: () => {},
  currentUser: undefined,
  setCurrentUser: () => {},
  logout: () => {},
}

const AuthContext = createContext<AuthContextProps>(initAuthContextPropsState)

const useAuth = () => {
  return useContext(AuthContext)
}

const AuthProvider: FC<WithChildren> = ({children}) => {
  const [auth, setAuth] = useState<AuthModel | undefined>(authHelper.getAuth())
  const [currentUser, setCurrentUser] = useState<UserModel | undefined>()
  const saveAuth = (auth: AuthModel | undefined) => {
    setAuth(auth)
    if (auth) {
      authHelper.setAuth(auth)
    } else {
      authHelper.removeAuth()
    }
  }

  const logout = () => {
    saveAuth(undefined)
    setCurrentUser(undefined)
  }

  return (
    <AuthContext.Provider value={{auth, saveAuth, currentUser, setCurrentUser, logout}}>
      {children}
    </AuthContext.Provider>
  )
}

const AuthInit: FC<WithChildren> = ({children}) => {
  const {auth, logout, setCurrentUser} = useAuth()
  const didRequest = useRef(false)
  const [showSplashScreen, setShowSplashScreen] = useState(true)

  useEffect(() => {
    // authenticate user by JWT token
    const requestUser = async () => {
      if (!didRequest.current) {
        try {
          const auth = authHelper.getAuth()
          if (auth && auth.access) {
            const user = await getUserByToken(auth?.access)
            if (user) {
              setCurrentUser(user)
              didRequest.current = true
            }
          } 
        } catch (error: any) {
          if (error.response?.status === 401) {
            // access token has expired
            // refresh token
            await authHelper.refreshAuthToken()
            const newAuth = authHelper.getAuth()
            if(newAuth && newAuth?.access) {
              // check if refreshed token is valid
              const user = await getUserByToken(newAuth.access)
              if (user) {
                setCurrentUser(user)
                didRequest.current = true
              } else {
                logout()
              }
            } else {
              logout()
            }
          } else {
            console.error(error)
            if (!didRequest.current) {
              logout()
            }
          }
        } finally {
          setShowSplashScreen(false)
        }
      }
    }

    requestUser()
  }, [auth, setCurrentUser, logout])

  return showSplashScreen ? <LayoutSplashScreen /> : <>{children}</>
}


export {AuthProvider, AuthInit, useAuth}

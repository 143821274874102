import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { useLocation, useParams } from "react-router-dom";
import {
  KTCardBody,
  RequestError,
  toAbsoluteUrl,
} from "../../../../../_metronic/helpers";
import { OUTPUT_DATE_FORMAT } from "../../../../generic/_variables";
import { FormStepper } from "../../../../generic/components/form/FormStepper";
import { RequestLoader } from "../../../../generic/components/loader/RequestLoader";
import { OSHTrainingQuestionModel } from "../../../../modules/api/osh-training/_models";
import {
  getOSHTrainigTestResult,
  getOSHTrainingTest,
} from "../../../../modules/api/osh-training/_requests";
import { getDate } from "../../../accident/detail/protocols/AccidentRegistration/AccidentRegistrationCardDetail";
import { Step1 } from "./Step1";
import { StepQuestion } from "./StepQuestion";

const OSHTrainingForm = () => {
  const [quizStatus, setTestStatus] = useState<boolean | undefined>(undefined);
  const [testResultPercentage, setTestResultPercentage] = useState<number>(0);
  const { osh_training_id } = useParams<{ osh_training_id: string }>();
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const accessKey = params.get("access_key") as string;
  const [showTest, setShowTest] = useState<boolean | undefined>(undefined);
  const [errorMessage, setErrorMessage] = useState<string | undefined>(
    undefined
  );
  const [globalError, setGlobalError] = useState<string | undefined>(undefined);
  const [trainingValidityEndDate, setTrainingValidityEndDate] = useState<
    Date | undefined
  >(undefined);
  const { data, isLoading, isError, error } = useQuery(
    "oshTrainingTest",
    () =>
      getOSHTrainingTest(Number(osh_training_id), accessKey).catch((error) => {
        setErrorMessage(error.response?.data.detail || "Wystąpił błąd");
        setShowTest(false);
      }),
    { refetchOnMount: false, refetchOnWindowFocus: true }
  );

  useEffect(() => {
    if (data) {
      setShowTest(true);
    }
  }, [data]);

  const formik = useFormik({
    initialValues: {
      osh_training_member: {
        id: undefined,
        first_name: undefined,
        last_name: undefined,
      },
      birth_date: new Date(),
      terms_accepted: false,
      answers: {}, // Initialize answers object
    },
    onSubmit: (values) => {
      // Prepare answers in the desired format
      const formattedAnswers = Object.entries(values.answers).flatMap(
        ([questionId, answerIds]) => {
          if (Array.isArray(answerIds)) {
            return answerIds.map((answerId) => ({
              question_id: Number(questionId),
              answer_id: Number(answerId),
            }));
          }
          return [
            {
              question_id: Number(questionId),
              answer_id: Number(answerIds),
            },
          ];
        }
      );

      getOSHTrainigTestResult(Number(osh_training_id), {
        results: formattedAnswers,
        member_id: Number(values.osh_training_member.id),
      })
        .then((response) => {
          // console.log(response);
          setGlobalError(undefined);
          setTrainingValidityEndDate(response.training_validity_end_date);
          setEnforcedPageIndex(1);
          setTestResultPercentage(response?.test_result_percentage);
          if (response.result === true) setTestStatus(true);
          else {
            formik.setValues({ ...values, answers: {} }); // Reset answers on failure
            setTestStatus(false);
          }
        })
        .catch((err: RequestError) => {
          if (err.response.data?.member_id)
            setGlobalError(
              `Uczestnik szkolenia: ${err.response.data?.member_id}`
            );
          else if (err.response.data?.answers)
            setGlobalError(`Odpowiedzi: ${err.response.data?.answers}`);
          else setGlobalError(err.response.data.detail || "Wystąpił błąd");
        });
    },
  });

  const [enforcedPageIndex, setEnforcedPageIndex] = useState<
    number | undefined
  >(0);

  const questions: any = data?.questions;

  return (
    <>
      {isLoading && <RequestLoader />}

      <div className="card py-10 py-md-0 m-md-10">
        <KTCardBody className="px-11 h-100">
          <h2 className="text-center py-5">{data?.instruction?.name}</h2>

          {showTest === false && (
            <div className="text-center text-danger">{errorMessage}</div>
          )}
          {globalError && (
            <div className="alert alert-dismissible bg-light-danger d-flex flex-column flex-sm-row p-5 pb-0 mb-10">
              <span className="svg-icon svg-icon-2hx svg-icon-danger me-4 mb-5 mb-sm-0 max-h-25px">
                <img
                  src={toAbsoluteUrl("/media/custom/icons/error-icon.svg")}
                  className="h-15 align-self-center"
                  alt=""
                />
              </span>
              <div className="d-flex flex-column text-danger pe-0 pe-sm-10">
                <h5 className="mb-1">Błąd</h5>
                <ul>
                  <li>{globalError}</li>
                </ul>
              </div>
            </div>
          )}
          {showTest === true && (
            <form
              onSubmit={formik.handleSubmit}
              hidden={quizStatus !== undefined}
            >
              <FormStepper
                stepsInfo={[{} as any, ...(questions as any)]}
                onSubmit={formik.handleSubmit}
                isEdit={false}
                formik={formik}
                displaySteps={false}
                gotoPageIndex={enforcedPageIndex}
              >
                <div className="row">
                  <Step1 formik={formik} />
                  {questions?.length &&
                    questions.map(
                      (question: OSHTrainingQuestionModel, index: number) => (
                        <StepQuestion
                          formik={formik}
                          question={question}
                          key={question.id}
                          index={index}
                        />
                      )
                    )}
                </div>
              </FormStepper>
            </form>
          )}
          {quizStatus === true && (
            <>
              <div className="row">
                <div className="pb-10"></div>
                <div className="border-top mb-6"></div>
                <h3 className="text-center text-success mb-10">
                  Szkolenie zaliczone!
                </h3>
                <h4 className="text-center text-success mb-10">
                  Twój wynik: {testResultPercentage}%
                </h4>
                <p className="text-center fw-semibold fs-3">
                  Twoje szkolenie z zakresu BHP jest ważne do dnia:{" "}
                  <span className="fw-bold text-success">
                    {getDate(trainingValidityEndDate, OUTPUT_DATE_FORMAT)}
                  </span>
                </p>
              </div>
            </>
          )}
          {quizStatus === false && (
            <>
              <div className="row">
                <div className="pb-10"></div>
                <div className="border-top mb-6"></div>
                <h3 className="text-center text-danger mb-10">
                  Szkolenie niezaliczone
                </h3>
                <p className="fw-semibold fs-3 text-center">
                  Niestety nie zaliczyłeś szkolenia.
                  <p>
                    Aby zaliczyć szkolenie, musisz odpowiedzieć poprawnie na
                    wszystkie pytania.
                  </p>
                </p>
                <div className="w-100 mx-auto d-flex justify-content-center">
                  <button
                    className="btn btn-primary mt-5 fs-5 w-100 w-md-auto px-md-10"
                    onClick={async () => {
                      setEnforcedPageIndex(2);
                      await setTestStatus(undefined);
                    }}
                  >
                    Spróbuj ponownie
                  </button>
                </div>
              </div>
            </>
          )}
        </KTCardBody>
      </div>
    </>
  );
};

export { OSHTrainingForm };


import axios, { AxiosResponse } from "axios";
import { stringifyRequestQuery } from "../../../../_metronic/helpers";
import { API_URL } from "../_variables";
import { fetchWithSessionStorage } from "../helpers";
import {
  RecommendationCreateEditModel,
  RecommendationDetailModel,
  RecommendationModel,
  RecommendationRealizationModel,
} from "./_models";

export const RECOMMENDATIONS_URL = `${API_URL}/recommendations`;
export const RECOMMENDATION_GROUPS_URL = `${API_URL}/recommendation-groups/`;
export const THREAT_TYPES_URL = `${RECOMMENDATIONS_URL}/threat-types`;
export const PRIORITIES_URL = `${RECOMMENDATIONS_URL}/priorities`;
export const REALIZATION_STATUSES_URL = `${RECOMMENDATIONS_URL}/realization-statuses`;
export const RECOMMENDATION_TYPES_URL = `${API_URL}/recommendation-types/`;
export function getRecommendations(queryParams?: any) {
  return axios
    .get(`${RECOMMENDATIONS_URL}/?${queryParams}`)
    .then((response) => response.data);
}

export function createRecommendation(
  recommendation: RecommendationCreateEditModel
) {
  return axios.post(`${RECOMMENDATIONS_URL}/`, recommendation);
}

/**
 * recommendations GET request with disabled pagination
 */
export function getAllRecommendations(queryParams?: any) {
  queryParams.pagination_enabled = false;
  queryParams = stringifyRequestQuery(queryParams);

  return axios
    .get(`${RECOMMENDATIONS_URL}/?${queryParams}`)
    .then((response: AxiosResponse<RecommendationModel[]>) => response);
}

export function getRecommendationById(recommendationId: number) {
  return axios
    .get(`${RECOMMENDATIONS_URL}/${recommendationId}`)
    .then(
      (response: AxiosResponse<RecommendationDetailModel>) => response.data
    );
}

export function deleteRecommendation(recommendationId: number) {
  return axios
    .delete(`${RECOMMENDATIONS_URL}/${recommendationId}`)
    .then((response: AxiosResponse<RecommendationModel>) => response.data);
}

export function updateRecommendation(
  recommendationId: number,
  recommendation: any
) {
  return axios.patch(
    `${RECOMMENDATIONS_URL}/${recommendationId}/`,
    recommendation
  );
}

export function createRecommendationRealization(
  recommendationId: number,
  payload: RecommendationRealizationModel
) {
  return axios.patch(
    `${RECOMMENDATIONS_URL}/${recommendationId}/realization`,
    payload
  );
}

export function getRecommendationGroups() {
  return axios.get(`${RECOMMENDATION_GROUPS_URL}`);
}

export function getThreatTypes() {
  return fetchWithSessionStorage(
    `${THREAT_TYPES_URL}`,
    "recommendation-threat-types"
  );
}

export function getRecommendationPriorities() {
  return fetchWithSessionStorage(PRIORITIES_URL, "recommendation-priorities");
}

export function getRecommendationTypes(pagination_enabled: boolean = false) {
  return axios.get(`${RECOMMENDATION_TYPES_URL}?pagination_enabled=false`);
}

export function getRealizationStatuses() {
  return fetchWithSessionStorage(
    REALIZATION_STATUSES_URL,
    "recommendation-realization-statuses"
  );
}

export function getRecommendationAverageTimes(
  queryParams = ""
) {
  return axios
    .get(`${RECOMMENDATIONS_URL}/statistics/average-times?${queryParams}`)
    .then((res) => res.data);
}


export function getRecommendationStatisticsChartData(queryParams = "filter_field=threat_type") { 
  return axios.get(`${RECOMMENDATIONS_URL}/statistics/charts?${queryParams}`).then(res => res.data)
}

export function getRecommendationUserStatisticsChartData(queryParams="") {
  return axios.get(`${RECOMMENDATIONS_URL}/statistics/my?${queryParams}`).then(res => res.data)
}
import { FC } from "react";

interface Props { 
    className?: string;
    href: string;
    notifications: any[] | null | undefined;
    title: string;
}
const NotificationListItem: FC<Props> = ({className="", title, href, notifications}) => (
  <>
    <li className="nav-item">
      <a
        className={`nav-link opacity-75 opacity-state-100 pb-4 text-white text-secondary mx-0 me-5 ${
          className
        }`}
        data-bs-toggle="tab"
        href={href}
      >
        {title}
        {notifications?.length &&
        notifications?.length > 0 ? (
          <span
            className="position-relative bottom-50 fw-bolder text-white fs-9 bg-danger "
            style={{
              borderRadius: "50%",
              padding: "2px 5px",
            }}
          >
            {notifications?.length}
          </span>
        ) : (
          <></>
        )}
      </a>
    </li>
  </>
);


export { NotificationListItem };

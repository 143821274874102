import { AuthModel } from "./_models";
import { refreshToken } from "./_requests";

const AUTH_LOCAL_STORAGE_KEY = "bhapp-authorization";

export const USER_GROUPS = {
  ADMIN: "admin",
  SECURITY_WORKER: "security_worker",
  OSH_WORKER: "osh_worker",
  OUTSOURCED_WORKER: "outsourced_worker",
};
const getAuth = (): AuthModel | undefined => {
  if (!localStorage) {
    return;
  }

  const encodedLsValue: string | null = localStorage.getItem(
    AUTH_LOCAL_STORAGE_KEY
  );
  if (!encodedLsValue) {
    return;
  }

  try {
    const decodedLsValue: string = atob(encodedLsValue);
    const auth: AuthModel = JSON.parse(decodedLsValue) as AuthModel;
    if (auth && auth.access) {
      return auth;
    } else if (auth?.temporary_token) return auth;
  } catch (error) {
    console.error("AUTH LOCAL STORAGE PARSE ERROR", error);
  }
};

const setAuth = (auth: AuthModel) => {
  if (!localStorage) {
    return;
  }
  try {
    const lsValue = JSON.stringify(auth);
    const encodedLsValue = btoa(lsValue); // base64 encode for security reasons
    localStorage.setItem(AUTH_LOCAL_STORAGE_KEY, encodedLsValue);
  } catch (error) {
    console.error("AUTH LOCAL STORAGE SAVE ERROR", error);
  }
};

const removeAuth = () => {
  if (!localStorage) {
    return;
  }

  try {
    localStorage.removeItem(AUTH_LOCAL_STORAGE_KEY);
  } catch (error) {
    console.error("AUTH LOCAL STORAGE REMOVE ERROR", error);
  }
};

export function setupAxios(axios: any) {
  axios.defaults.headers.Accept = "application/json";
  axios.interceptors.request.use(
    (config: { headers: { Authorization: string } }) => {
      const auth = getAuth();
      if (auth && auth.access) {
        config.headers.Authorization = `Bearer ${auth.access}`;
      }

      return config;
    },
    (err: any) => Promise.reject(err)
  );
}

export const refreshAuthToken = async () => {
  let auth = getAuth();

  if (auth && auth.refresh) {
    try {
      const response = await refreshToken(auth.refresh);
      auth.access = response.data.access;
      setAuth(auth); // update auth in local storage
    } catch (err) {
      console.error("Error refreshing token", err);
      removeAuth();
    }
  }
};

export { AUTH_LOCAL_STORAGE_KEY, getAuth, removeAuth, setAuth };


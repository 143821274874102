// RenderListSelectComponent.tsx
import { FormikProps } from "formik";
import { FC } from "react";
import { SelectOption } from "../components/select/ListSearchComponent";
import { ListSelectComponent } from "../components/select/ListSelectComponent";

interface MyFormValues {
  [key: string]: any;
}

type Props = {
  selectedItem?: SelectOption | any;
  fieldKey: string;
  fetchFunction: (params?: any) => any;
  changeHandler: (item: any) => void;
  placeholderText: string;
  labelText: string;
  formik: FormikProps<any>;
  formatItem: (item: any) => SelectOption;
  formikErrorField?: FormikProps<any>;
  required?: boolean;
  optionsGrouped?: boolean;
  disabled?: boolean;
  options?: SelectOption[]; // Add options prop here
  enableFetchFunction?: boolean
};

export const getErrorMessage = (error: any) => {
  if (typeof error === "string") {
    return error;
  }
  if (Array.isArray(error)) {
    return error[0];
  }
  if (typeof error === "object") {
    return Object.values(error)[0] as string;
  }
  return undefined;
};

const RenderListSelectComponent: FC<Props> = ({
  selectedItem,
  fieldKey,
  fetchFunction,
  changeHandler,
  placeholderText,
  labelText,
  formik,
  formatItem,
  formikErrorField = undefined,
  required = true,
  optionsGrouped = false,
  disabled = false,
  options = [], // Default to an empty array
  enableFetchFunction=true,
}) => {
  const formikError = formikErrorField ? formikErrorField : formik.errors[fieldKey];
  return (
    <>
      <ListSelectComponent
        selectedItem={selectedItem ? selectedItem : null}
        onItemChange={changeHandler}
        fetchItems={fetchFunction}
        formatOption={(item: any) => {
          const formattedItem = formatItem(item);
          return {
            value: formattedItem.value,
            label: formattedItem.label,
            additionalFields: formattedItem.additionalFields,
          };
        }}
        placeholder={placeholderText}
        noOptionsMessage="Brak wyników"
        label={labelText}
        key={formik.values[fieldKey]}
        required={required}
        optionsGrouped={optionsGrouped}
        disabled={disabled}
        options={options} // Pass options to the ListSelectComponent
        enableFetchFunction={enableFetchFunction}
      />
      {formikError && formik.touched[fieldKey] ? (
        <div className="text-danger">{getErrorMessage(formikError)}</div>
      ) : null}
    </>
  );
};

export default RenderListSelectComponent;

import axios from "axios";
import { API_URL } from "../_variables";
import {
  TechnicalSecuritySystemCreateEditModel,
} from "./_models";

const TECHNICAL_SECURITY_SYSTEM_URL = `${API_URL}/technical-security-systems`;
const SERVICES_URL = `${API_URL}/services`;

export function getTechnicalSecuritySystems(query: any) {
  return axios
    .get(`${TECHNICAL_SECURITY_SYSTEM_URL}/?${query}`)
    .then((res) => res.data);
}


export function getTechnicalSecuritySystemsById(id: number) {
  return axios
    .get(`${TECHNICAL_SECURITY_SYSTEM_URL}/${id}`)
    .then((res) => res.data);
}

export function createTechnicalSecuritySystem(
  payload: TechnicalSecuritySystemCreateEditModel
) {
  return axios
    .post(`${TECHNICAL_SECURITY_SYSTEM_URL}/`, payload)
    .then((res) => res);
}

export function getTechnicalSecuritySystemsDisabledPagination() { 
  return axios
  .get(`${TECHNICAL_SECURITY_SYSTEM_URL}/?pagination_enabled=false`)
  .then((res) => res);
}

export function updateTechnicalSecuritySystem(
  id: number,
  payload: TechnicalSecuritySystemCreateEditModel
) {
  return axios
    .put(`${TECHNICAL_SECURITY_SYSTEM_URL}/${id}/`, payload)
    .then((res) => res);
}

export function deleteTechnicalSecuritySystem(id: number) {
  return axios
    .delete(`${TECHNICAL_SECURITY_SYSTEM_URL}/${id}/`)
    .then((res) => res);
}

export function getServicesForTechnicalSecuritySystem(id: number) {
  return axios
    .get(`${TECHNICAL_SECURITY_SYSTEM_URL}/${id}/service-logs`)
    .then((res) => res.data);
}

// export function deleteService(id: number) {
//   return axios
//     .delete(`${SERVICES_URL}/${id}`)
//     .then((res) => res);
// }

// export function updateService(id: number, payload: ServiceCreateModel) {
//   return axios.put(`${SERVICES_URL}/${id}/`, payload).then((res) => res);
// }

// export function createService(payload: ServiceCreateModel) {
//   return axios.post(`${SERVICES_URL}/`, payload).then((res) => res);
// }


export function generateServicesReport(payload: any) { 
  return axios.post(`${API_URL}/reports/services`, payload).then((res) => res);
}

export function getFireProtectionNotifications() { 
  return axios.get(`${API_URL}/notifications/fire-protection`).then((res) => res.data);
}

export function getTechnicalSecuritySystemLastService(systemId: number) {
  return axios.get(`${API_URL}/technical-security-systems/${systemId}/service-logs?last_service=true`).then((res) => res.data);
}
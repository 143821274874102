import { useState, useContext, createContext, ReactNode } from 'react';
import { UserBaseModel } from '../../pages/auth';

interface SelectedUserContextType {
  selectedUser: UserBaseModel | null;
  setSelectedUser: (user: UserBaseModel | null) => void;
}

const SelectedUserContext = createContext<SelectedUserContextType | undefined>(undefined);

export function useSelectedUser() {
  const context = useContext(SelectedUserContext);
  if (!context) {
    throw new Error('useSelectedUser must be used within a SelectedUserProvider');
  }
  return context;
}

interface SelectedUserProviderProps {
  children: ReactNode;
}

export function SelectedUserProvider({ children }: SelectedUserProviderProps) {
  const [selectedUser, setSelectedUser] = useState<UserBaseModel | null>(null);

  const contextValue: SelectedUserContextType = {
    selectedUser,
    setSelectedUser,
  };

  return (
    <SelectedUserContext.Provider value={contextValue}>
      {children}
    </SelectedUserContext.Provider>
  );
}

import axios from "axios";
import { EnumChoiceModel } from "./accident/_models";
import { FactoryAreaDetailModel } from "./factory-area/_models";

export function getFactoryAreaInfo(
  factoryArea: FactoryAreaDetailModel,
  display: "description" | "factory" = "description"
) {
  if (display === "description")
    return `${factoryArea?.name} ${
      factoryArea?.description ? ` - ${factoryArea.description}` : ""
    }`;

  return `${factoryArea?.name} - ${factoryArea?.factory?.name}`;
}

export function getBuildingHighTypeInfo(buildingHighType: EnumChoiceModel) {
  return `${buildingHighType?.description} (${buildingHighType?.code})`;
}

/**
 * Fetches data from the server or from session storage if already cached.
 * @param {string} url - The URL to fetch data from.
 * @param {string} storageKey - The key used for storing data in session storage.
 * @returns {Promise} - A promise that resolves to the fetched or cached data.
 */
export function fetchWithSessionStorage(url: string, storageKey: string) {
  // Check if the data exists in session storage
  const cachedData = sessionStorage.getItem(storageKey);
  if (cachedData) {
    // If data is found in session storage, parse it and return as a promise
    return Promise.resolve(JSON.parse(cachedData));
  } else {
    // If data is not found in session storage, fetch it from the server
    return axios
      .get(url)
      .then((response : any) => {
        // Store the fetched data in session storage for future use
        sessionStorage.setItem(storageKey, JSON.stringify(response.data));
        return response.data;
      })
      .catch((error : any) => {
        console.error("Error fetching data:", error);
      });
  }
}

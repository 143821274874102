/* eslint-disable jsx-a11y/anchor-is-valid */
import { lazy, useEffect } from "react";
import { Link, Outlet, Route, Routes } from "react-router-dom";
import { toAbsoluteUrl } from "../../../_metronic/helpers";

const ForgotPassword = lazy(() => import("./components/ForgotPassword"));
const EmailNotActivated = lazy(() => import("./components/EmailNotActivated"));
const VerifyEmail = lazy(() => import("./components/VerifyEmail"));
const ResetPassword = lazy(() => import("./components/ResetPassword"));
const Login = lazy(() => import("./components/Login"));
const TwoFactorAuthentication = lazy(() =>
  import("./components/TwoFactorAuthentication")
);
const Verification = lazy(() => import("./components/Verification"));
const Registration = lazy(() => import("./components/Registration"));

const AuthLayout = () => {
  useEffect(() => {
    document.body.classList.add("bg-body");
    return () => {
      document.body.classList.remove("bg-body");
    };
  }, []);

  return (
    <div
      className="d-flex flex-column flex-column-fluid bgi-position-y-bottom position-x-center bgi-no-repeat bgi-size-contain bgi-attachment-fixed"
      style={{}}
    >
      {/* begin::Content */}
      <div className="d-flex flex-center flex-column flex-column-fluid p-10 pb-lg-20">
        {/* begin::Logo */}
        <a href="/" className="mb-12">
          <img
            alt="Logo"
            src={toAbsoluteUrl("/media/custom/logos/ktb.svg")}
            className="h-55px"
          />
        </a>
        {/* end::Logo */}
        {/* begin::Wrapper */}
        <div className="col col-12 w-lg-500px col-xl-5 col-lg-6 col-md-8 col-sm-9 col-xs-12  mx-auto bg-body rounded shadow-sm p-10 p-lg-15 mx-auto">
          <Outlet />
        </div>
        {/* end::Wrapper */}
      </div>
      {/* end::Content */}
      {/* begin::Footer */}
      <div className="d-flex flex-center flex-column-auto p-10">
        <div className="d-flex align-items-center fw-bold fs-6">
          <Link to="/kontakt" className="text-muted text-hover-primary px-2">
            Kontakt
          </Link>
          <Link
            to="/polityka-prywatnosci"
            className="text-muted text-hover-primary px-2"
          >
            Polityka Prywatności
          </Link>
          <Link to="/regulamin" className="text-muted text-hover-primary px-2">
            Regulamin
          </Link>
        </div>
      </div>
      {/* end::Footer */}
    </div>
  );
};

export const AuthPage = () => (
  <Routes>
    <Route element={<AuthLayout />}>
      <Route path="login" element={<Login />} />
      <Route path="registration" element={<Registration />} />
      <Route path="forgot-password" element={<ForgotPassword />} />
      <Route path="2fa" element={<TwoFactorAuthentication />} />
      <Route path="reset-password" element={<ResetPassword />} />
      <Route index element={<Login />} />
    </Route>
  </Routes>
);

export default AuthLayout;





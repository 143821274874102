/* eslint-disable jsx-a11y/anchor-is-valid */

import { FC, useState } from "react";
import { useLocation } from "react-router-dom";
import { KTIcon } from "../../../_metronic/helpers";
import { RequestLoader } from "../../generic/components/loader/RequestLoader";
import { useToastMessage } from "../../generic/components/toast/ToastMessageProvider";
import { TOAST_MESSAGES } from "../../generic/components/toast/ToastMessages";
import { setUserGroup } from "../../modules/api/auth/core/_requests";
import {
  useQueryResponse,
} from "../../modules/api/user/QueryResponseProvider";
import { UserBaseModel } from "../../pages/auth";
import { useSelectedUser } from "./SelectedUserProvider";
const roles = [
  {
    
    id: "outsourced_worker",
    title: "Pracownik Firmy Zewnętrznej",
    subTitle: "Konto dla pracowników firm zewnętrznych",
    description: "Konto dla pracowników firm zewnętrznych",
    default: false,
    custom: false,
    features: [
      {
        title: "Powiadomienia mailowe",
        supported: true,
      },
      {
        title: 'Moduł "Wypadki"',
        supported: false,
      },
      {
        title: 'Moduł "Zalecenia BHP"',
        supported: true,
      },
      {
        title: "Przegląd otrzymanych zaleceń",
        supported: true,
      },
      {
        title: "Wypełnianie informacji do zalecenia",
        supported: true,
      },
    ],
  },
  {
    id: "security_worker",
    title: "Inspektor PPOŻ",
    subTitle: "Konto dla pracowników do spraw bezpieczeństwa pożarowego",
    description: "Konto dla pracowników do spraw bezpieczeństwa pożarowego",
    default: false,
    custom: false,
    features: [
      {
        title: "Powiadomienia mailowe",
        supported: true,
      },
      {
        title: 'Moduł "Wypadki"',
        supported: true,
      },
      {
        title: "Tworzenie, edycja, usuwanie wypadków",
        supported: false,
      },
      {
        title: "Przegląd podstawowych informacji o wypadkach",
        supported: true,
      },
      {
        title: 'Moduł "Zalecenia BHP"',
        supported: true,
      },
      {
        title: "Tworzenie, edycja własnych zaleceń",
        supported: true,
      },
      {
        title: "Przegląd otrzymanych zaleceń",
        supported: true,
      },
      {
        title: "Zatwierdzanie własnych zaleceń",
        supported: true,
      },
    ],
  },
  {
    id: "osh_worker",
    title: "Pracownik BHP",
    subTitle: "Konto dla pracowników działu BHP",
    description: "Konto dla pracowników działu BHP",
    default: false,
    custom: false,
    features: [
      {
        title: "Powiadomienia mailowe",
        supported: true,
      },
      {
        title: 'Moduł "Wypadki"',
        supported: true,
      },
      {
        title: "Tworzenie, edycja, usuwanie wypadków",
        supported: true,
      },
      {
        title: "Przegląd wszystkich wypadków",
        supported: true,
      },
      {
        title: "Sporządzanie protokołów powypadkowych",
        supported: true,
      },
      {
        title: 'Moduł "Zalecenia BHP"',
        supported: true,
      },
      {
        title: "Tworzenie, edycja własnych zaleceń",
        supported: true,
      },
      {
        title: "Przegląd wszystkich zaleceń",
        supported: true,
      },
      {
        title: "Zatwierdzanie wszystkich zaleceń",
        supported: true,
      },
     
    ],
  },

  {
    id: "admin",
    title: "Administrator",
    subTitle:
      "Konto o najwyższych uprawnieniach, tylko dla pojedynczych użytkowników",
    description:
      "Konto o najwyższych uprawnieniach, tylko dla pojedynczych użytkowników",
    default: true,
    custom: false,
    label: "Najwyższy",
    features: [
      {
        title: "Zarządzanie uprawnieniami użytkowników",
        supported: true,
      },
      {
        title: 'Zmiana danych firmy, obszarów zakładu',
        supported: true,
      },
      {
        title: 'Dodawnie nowych pracowników',
        supported: true,
      },
      {
        title: 'Eksportowanie danych',
        supported: true,
      },
      {
        title: 'Moduł "Wypadki"',
        supported: true,
      },
      {
        title: "Tworzenie, edycja, usuwanie wypadków",
        supported: true,
      },
      {
        title: "Przegląd wszystkich wypadków",
        supported: true,
      },
      {
        title: "Sporządzanie protokołów powypadkowych",
        supported: true,
      },{
        title: 'Moduł "Zalecenia BHP"',
        supported: true,
      },
      {
        title: "Tworzenie, edycja, usuwanie, przegląd wszystkich zaleceń",
        supported: true,
      },
      {
        title: "Zatwierdzanie wszystkich zaleceń",
        supported: true,
      },
      
    ],
  },

];

interface Props {
  user: UserBaseModel | null;
}
const GrantRole: FC<Props> = () => {
  // No need to pass user prop anymore
  const [selectedRole, setSelectedRole] = useState<string>("osh_worker");
  const [error, setError] = useState<string | null>(null);
  const handleRoleChange = (roleTitle: string) => {
    setSelectedRole(roleTitle);
  };
  const { refetch } = useQueryResponse();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const {setToastMessage} = useToastMessage();  
  const { selectedUser } = useSelectedUser(); // Use the selected user from the custom hook
  const location = useLocation();
  const hideModal = () => {
    const cancelButton: HTMLElement | null = document.querySelector(
      "#kt_modal_grant_role_cancel_button"
    );
    if (cancelButton) {
      cancelButton.click();
    }
  };

  async function grantUserGroup() {
    const rolesId = roles.map((role) => role.id);
    if (!rolesId.includes(selectedRole)) {
      setError("Wybrano nieprawidłowe uprawnienie");
      return;
    }
    setError(null);
    setIsLoading(true);
    let response;
    try {
      const payload = {
        id: selectedUser?.id || -1, // Use selectedUser from the custom hook
        groups: [selectedRole],
      };

      response = await setUserGroup(payload);
      if (response?.status === 200) {
        hideModal();
      }
    } catch (ex:any) {
      if (ex.response) setError(`Błąd: ${ex.response.data.detail}`);
    }

    setIsLoading(false);
    setToastMessage(TOAST_MESSAGES.user.grant_role_success(selectedUser?.email || "", selectedRole));
    
    if (location.pathname.includes("uzytkownicy")) {
      const event = new CustomEvent("userListChange");
      window.dispatchEvent(event);
    }
  }
  return (
    <>
      <div
        className="modal h-85"
        id="kt_modal_grant_role"
        aria-hidden="true"
        data-backdrop="true"
        tabIndex={-1}
        role="dialog"
        aria-labelledby="myModalLabel"
      >
        <div className="modal-dialog modal-xl">
          <div className="modal-content rounded">
            <div className="modal-header justify-content-end border-0 pb-0">
              <div
                className="btn btn-sm btn-icon btn-active-color-primary"
                data-bs-dismiss="modal"
              >
                <KTIcon iconName="cross" className="fs-1" />
              </div>
            </div>

            <div className="modal-body pt-0 pb-15 px-5 px-xl-20">
              <div className="mb-7 text-center">
                <h1 className="mb-3">Nadaj uprawnienia</h1>

                <div className="text-muted fw-semibold fs-5">
                  Nadaj uprawnienia dla Użytkownika{" "}
                  <span className="text-primary fw-bold">
                    {selectedUser?.first_name && selectedUser?.last_name
                      ? `${selectedUser?.first_name} ${selectedUser?.last_name}`
                      : ""}
                  </span>
                </div>
              </div>
              <div className="d-flex flex-column">
                <div className="row mt-10">
                  <div className="col-lg-6 mb-10 mb-lg-0">
                    {/* <p className="fw-semibold fs-6">
                  <strong>Uwaga: </strong>Mozesz dodac więcej niz jedno uprawnienie.
                </p> */}
                    {error && <div className="alert alert-danger">{error}</div>}

                    <div className="nav flex-column">
                      {roles.map((role, index) => (
                        <div
                          onClick={() => handleRoleChange(role.id)}
                          className={`nav-link btn btn-outline btn-outline-dashed btn-color-dark d-flex flex-stack text-start p-6 ${
                            index !== roles.length - 1 && "mb-6"
                          } ${role.id === selectedRole && "active"} ${
                            !role.custom && "btn-active btn-active-primary"
                          }`}
                          key={index}
                        >
                          <div className="d-flex align-items-center me-2">
                            <div className="form-check form-check-custom form-check-solid form-check-success me-6">
                              <input
                                className="form-check-input"
                                type="radio"
                                name="roleSelection"
                                value={role.title}
                                checked={selectedRole === role.id}
                                onChange={() => handleRoleChange(role.id)}
                              />
                            </div>

                            <div className="flex-grow-1">
                              <span className="d-flex align-items-center fs-2 fw-semibold flex-wrap">
                                {role.title}
                                {role.label && (
                                  <span className="badge badge-light-danger ms-2 fs-7">
                                    {role.label}
                                  </span>
                                )}
                              </span>
                              <div className="fw-semibold opacity-50">
                                {role.subTitle}
                              </div>
                            </div>
                          </div>

                          <div className="ms-5">
                            {role.custom && (
                              <button className="btn btn-sm btn-primary">
                                Contact Us
                              </button>
                            )}
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>

                  <div className="col-lg-6">
                    <div className="tab-content rounded h-100 bg-light p-10">
                      {roles.map((role) => {
                        // Check if role is the currently focused one
                        if (selectedRole === role.id && !role.custom) {
                          return (
                            <div
                              key={`custom-${role.title}`}
                              className="tab-panel show active"
                            >
                              <div className="pb-5">
                                <h2 className="fw-semibold text-dark">
                                  Co zawiera uprawnienie "{role.title}"?
                                </h2>
                                <div className="text-gray-400 fw-semibold">
                                  {role.description}
                                </div>
                              </div>

                              <div className="pt-1">
                                {role.features!.map((feature, i) => (
                                  <div
                                    className={`d-flex align-items-center ${
                                      i !== role.features!.length - 1 && "mb-7"
                                    }`}
                                    key={`${i}-${feature.title}`}
                                  >
                                    {feature.supported ? (
                                      <>
                                        <span className="fw-semibold fs-6 text-gray-700 flex-grow-1">
                                          {feature.title}
                                        </span>
                                        <KTIcon
                                          iconName="check-circle"
                                          className="fs-1 text-success"
                                        />
                                      </>
                                    ) : (
                                      <>
                                        <span className="fw-semibold fs-6 text-gray-400 flex-grow-1">
                                          {feature.title}
                                        </span>
                                        <KTIcon
                                          iconName="cross-circle"
                                          className="fs-1"
                                        />
                                      </>
                                    )}
                                  </div>
                                ))}
                              </div>
                            </div>
                          );
                        } else {
                          return null;
                        }
                      })}
                    </div>
                  </div>
                </div>
              </div>
              <div className="d-flex flex-center flex-row-fluid pt-12">
                <button
                  id="kt_modal_grant_role_cancel_button"
                  type="reset"
                  className="btn btn-light me-3"
                  data-bs-dismiss="modal"
                >
                  Cofnij
                </button>

                <button
                  type="button"
                  className={`btn btn-primary`}
                  onClick={grantUserGroup}
                  disabled={isLoading}
                >
                  Nadaj uprawnienia
                </button>
              </div>
            </div>
          </div>
        </div>
        {isLoading && <RequestLoader />}
      </div>
    </>
  );
};

export { GrantRole };

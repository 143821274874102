/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC } from 'react'

type Props = {
    background?: string
    color?: string
    title: string
    accidentCount?: number | any
}

const AccidentCountCard: FC<Props> = ({
  background,
  color,
  title,
  accidentCount = 0,
}) => {
  return (
    <div className={`card ${background} mt-10 w-100`}>
      <div className='card-body d-flex flex-center flex-column'>
        <a className='w-100 fs-2 text-gray-800 fw-bolder' style={{
          marginBottom: "auto",
          marginTop: "0",
          textAlign: "left",
          letterSpacing: "0.5px"
        }}>
          {title}
        </a>
        <span className='w-100 text-gray fw-bolder' style={ {
          marginBottom: 0,
          marginTop: "auto",
          textAlign: "right",
          fontSize: "4rem"
        }}
        >
          {accidentCount}
        </span> 
      </div>
    </div>
  )
}

export { AccidentCountCard }

import createGenericQueryProvider, { QueryConfig } from '../../../generic/table/QueryResponseProvider';
import { ResourceBaseModel } from "./_models";
import { getResourcesByPath } from "./_requests";

const fileManagerConfig: QueryConfig<ResourceBaseModel> = {
  modelName: 'FILE_MANAGER_LIST',
  fetchFunction: (query) => getResourcesByPath("", query),
  eventType: 'fileManagerListChange',
};




export const {
  QueryResponseProvider: FileManagerQueryProvider,
  useQueryResponse: useFileManagerQueryResponse,
  useQueryResponseData: useFileManagerQueryResponseData,
  useQueryResponsePagination: useFileManagerQueryResponsePagination,
  useQueryResponseLoading: useFileManagerQueryResponseLoading,
} = createGenericQueryProvider<ResourceBaseModel>(fileManagerConfig);


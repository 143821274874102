import axios, { AxiosResponse } from "axios";
import { stringifyRequestQuery } from "../../../../_metronic/helpers";
import { API_URL } from "../_variables";
import { UserNameModel } from "../auth/core/_models";


export const GET_USERS = `${API_URL}/users`;



export function verifyUser(uid: string, token: string) {
  const payload = { uidb64: uid, token: token };

  return axios.post(`${API_URL}/users/verify-email`, payload).then((res) => res);
}

export function resetPassword(payload: any ) {

  return axios.post(`${API_URL}/reset-password`, payload).then((res) => res);
}


export function getUsers(queryParams?: any) {
  return axios.get(`${GET_USERS}?${queryParams}`).then((res) => res.data);
}

export function deleteUser(id: Number) {
  return axios.delete(`${GET_USERS}/${id}`).then((res) => res.data);
}

export function getUsersDisabledPagination() { 
  return axios.get(`${GET_USERS}?pagination_enabled=false&exclude_user_requesting=true&exclude_unverified_users=true&order_by=first_name`);
}

export function getUsersBySearchTerm(queryParams?: any) {
  queryParams = stringifyRequestQuery(queryParams);

  return axios
    .get(`${API_URL}/users?pagination_enabled=false&list_search=true&${queryParams}`)
    .then((response: AxiosResponse<UserNameModel[]>) => response);
}
